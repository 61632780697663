import { TbVideoPlus } from "react-icons/tb";
import Join_Video_Call_Modal from "./join-call-modal"
import { useRef } from "react";
export default function Join_Video_Call_Btn({className="floating"}) {
  
  const open_join_video_call_modal = useRef(null)
  

  return (
    <>
    <Join_Video_Call_Modal ref={open_join_video_call_modal}/>

    <button type="button" className={`join-call-btn ${className}`} onClick={() => open_join_video_call_modal.current?.open_modal()}>
    <TbVideoPlus className="video-call-icon"/>
    <span>Join Call</span>
    </button>
    </>
  )
}
