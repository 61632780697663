import React, { useCallback, useMemo } from "react";
import { sentenceCase } from "../../../utils";
import { get_bulk_settings, get_informationFromSessionID } from "../../../Component/Helper";
import { Link } from "react-router-dom";

function TermsConditionInput({ formParams, clsName = "", errors, register }) {
  const { field_name, short_code, control_value, required } = formParams || {};
  const company_code = get_informationFromSessionID("companyCode");
  let {
    app_registration_show_hyper_link_tnc,
    app_registration_show_hyper_link_privacy_policy,
    app_registration_tnc_replace_text,
    app_registration_privacy_policy_replace_text,
  } = get_bulk_settings([
    "app_registration_show_hyper_link_tnc",
    "app_registration_show_hyper_link_privacy_policy",
    "app_registration_tnc_replace_text",
    "app_registration_privacy_policy_replace_text",
  ]);
  
  app_registration_show_hyper_link_tnc = app_registration_show_hyper_link_tnc === "show"
  app_registration_show_hyper_link_privacy_policy = app_registration_show_hyper_link_privacy_policy === "show"

  let label =
  Array.isArray(control_value) && control_value.length > 0
    ? control_value[0]
    : control_value;
    label = label.replace(app_registration_tnc_replace_text, app_registration_tnc_replace_text.split(" ").join("_")).replace(app_registration_privacy_policy_replace_text, app_registration_privacy_policy_replace_text.split(" ").join("_"))
    
label = label ? label?.split(" ") : "";


  const processText = useCallback((textArray) => {
    return textArray.map(val => {
      const text = val.replace(/_/g, " ")
      
      if(app_registration_show_hyper_link_tnc && text.includes(app_registration_tnc_replace_text)){
        return <a href={`/static-page/terms-condition?terms=1&company_code=${company_code}`} className="hyper-link" target="_blank">{text} </a>
      }else if(app_registration_show_hyper_link_privacy_policy && text.includes(app_registration_privacy_policy_replace_text)){
        return <a href={`/static-page/privacy-policy?privacy=1&company_code=${company_code}`} className="hyper-link" target="_blank">{text} </a>
      }else {
        return <span>{text} </span>
      }
    })
  
  }, [label]);

  return (
    <span className={clsName}>
      <label className="label" htmlFor={short_code}>
        <span>
            {processText(label)}
        </span>
        {/* {cin_required === "1" && <span className="required-field">*</span>} */}
        <div className="required-field">
          {errors[short_code]?.message || ""}
        </div>
      </label>

      <input
        // tabIndex={i + 1}
        className="RegInp fieldValue"
        type={"checkbox"}
        name={short_code}
        {...register(short_code, {
          required: {
            value: required === "1",
            message: `${field_name} is required`,
          },
        })}
        id={short_code}
      />
    </span>
  );
}

export default TermsConditionInput;
