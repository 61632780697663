import { getCustomValue } from "../../Custom/CompanyList";
import { get_informationFromSessionID, get_userData_from_activeUser } from "../Helper";
import { IoWarning } from "react-icons/io5";
import "./AccessBannerMessage.css"

export default function AccessBannerMessage() {
  const user_access_massage = getCustomValue("user_access_massage")
  const userID = get_informationFromSessionID("userID");
  const userData = get_userData_from_activeUser()
  if(!user_access_massage || !user_access_massage?.before_login) return null;
  if(userID && userData?.is_cat_allocated === "1") return null;
  const messsage = !!userID ?user_access_massage?.after_login : user_access_massage?.before_login
  
  return (
    <div className="user-access-banner-message">
    <ul>
      {
        messsage.map((msg, index) => <li key={`access-banner-${index}`}>
            <IoWarning className="icon"/>
            <span>{msg}</span>
            </li>)
      }
    </ul>
    </div>
  );
}
