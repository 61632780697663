import React, { useEffect, useMemo, useState } from "react";
import { show_homescreen_popup } from "./HomePagePopUpHelper";
import HomePagePopUpModal from "./HomePagePopUpModal/HomePagePopUpModal";

function HomePagePopUp({ homescreen_popup, cat_id }) {
  const [openHomeScreenPopup, setOpenHomeScreenPopup] = useState(false);
  const handleHomeScreenPopupOpen = () => setOpenHomeScreenPopup(true);
  const handleHomeScreenPopupClose = () => setOpenHomeScreenPopup(false);
  
  const hasHomeScreenPopup = homescreen_popup?.length > 0;
  

  useEffect(() => {
    if (
      hasHomeScreenPopup &&
      show_homescreen_popup(homescreen_popup[0]?.popup_code) &&
      !cat_id
    ) {
      handleHomeScreenPopupOpen();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homescreen_popup, cat_id])

  const no_image_video = useMemo(() => homescreen_popup?.every(popup => !popup.popup_image && !popup.popup_video), [homescreen_popup])

  if(!homescreen_popup || !hasHomeScreenPopup || no_image_video) return null
  

  return (
    openHomeScreenPopup ? <HomePagePopUpModal
      homescreen_popup={homescreen_popup}
      handleHomeScreenPopupClose={handleHomeScreenPopupClose}
      openHomeScreenPopup={openHomeScreenPopup}
    /> : null
  );
}

export default HomePagePopUp;
