import {
  get_bulk_settings,
  get_informationFromSessionID,
  get_property_settings,
  get_userData_from_activeUser,
  set_informationToSessionID,
  ToastError,
  ToastSuccess,
} from "./Component/Helper";
// import singleLayout from "../../../../../assets/images/single-layout.jpg";
// import gridLayout from "../../../../../assets/images/grid-layout.jpg";
import singleLayout from "./assets/images/single-layout.jpg";
import gridLayout from "./assets/images/grid-layout.jpg";
import { getCustomValue } from "./Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "./Custom/CompanyCustomization";

/******************************* REGEX ***********************************/
export const MOBILE_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

//for DJ
// export const MOBILE_REGEX = /^\+?[1-9]\d{0,2}[-\s\.]?\(?\d{1,4}\)?[-\s\.]?\d{1,4}[-\s\.]?\d{1,9}[-\s\.]?\d{0,9}$/;
export const EMAIL_REGEX = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
export const DATE_REGEX =
  /(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})/;

/************************ UTILS FUNCTIONS ***********************/
export function is_plural_base_on_count(count) {
  return Number(count) > 1;
}

export function is_num_even(num) {
  return num % 2 === 0;
}

export function sentenceCase(str) {
  const strArr = str.split(" ");
  return strArr
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function maskString(maskType, maskString, str, startIndex) {
  if (!str) return "";
  const strArr = str.split("");
  if (maskType === "email") {
    const emailLastIndex = strArr.indexOf("@");
    return strArr.fill(maskString, startIndex, emailLastIndex).join("");
  } else if (maskType === "mobile") {
    const mobileLastIndex = -4;
    return strArr.fill(maskString, startIndex, mobileLastIndex).join("");
  } else {
    return strArr.fill(maskString).join("");
  }
}

export function DATA_TYPE(data) {
  if (Array.isArray(data)) {
    return "array";
  } else if (data === "" || data === undefined || data === null) {
    return null;
  } else if (
    typeof data === "object" &&
    (Object.keys(data).length === 0 || Object.keys(data).length > 0)
  ) {
    return "object";
  } else {
    return typeof data;
  }
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function check_is_myc_web() {
  const host = window.location.origin;
  //'https://appv2.jewelflow.link || https://appv2.jewelflow.pro' || 'https://staging.jewelflow.link/'
  return (
    host === "http://localhost:4001" ||
    // host === "https://appv2.jewelflow.pro" ||
    // host === "http://appv2.jewelflow.pro" ||
    (!host.includes("staging") && host.includes("jewelflow.link") && !host.includes("website") && !host.includes('production') ) || 
    false
  );
}



export function displayed_product_in_matrix_myc_for_secure(layoutView) {
  const {
    mycat_secure_lock_image_count_based_on,
    mycat_secure_lock_image_count,
  } = get_bulk_settings([
    "mycat_secure_lock_image_count_based_on",
    "mycat_secure_lock_image_count",
  ]);

  const MIN_SCREEN_SIZE = 350;
  const MAX_SCREEN_SIZE = 1920;
  let product_count = 1;
  const displayed_product_arr = [
    { screen: MIN_SCREEN_SIZE, product: 1, mode: "less-than" },
    { screen: [MIN_SCREEN_SIZE, 767], product: 2 },
    { screen: [768, 1439], product: 3 },
    { screen: [1440, 1749], product: 4 },
    { screen: [1750, MAX_SCREEN_SIZE], product: 5 },
    { screen: MAX_SCREEN_SIZE, product: 6, mode: "greater-than" },
  ];

  if (window.innerWidth <= MIN_SCREEN_SIZE) {
    const active_screen = displayed_product_arr.find(
      (prod) =>
        typeof prod.screen === "number" && prod.screen === MIN_SCREEN_SIZE
    );
    product_count = active_screen.product;
  } else if (window.innerWidth >= MAX_SCREEN_SIZE) {
    const active_screen = displayed_product_arr.find(
      (prod) =>
        typeof prod.screen === "number" && prod.screen === MAX_SCREEN_SIZE
    );
    product_count = active_screen.product;
  } else {
    displayed_product_arr.forEach((prod) => {
      if (
        Array.isArray(prod.screen) &&
        window.innerWidth >= prod.screen[0] &&
        window.innerWidth <= prod.screen[1]
      ) {
        product_count = prod.product;
      }
    });
  }

  //   displayed_product_arr.forEach((prod) => {
  //     if (prod?.mode && prod?.mode === "less-than") {
  //       if (window.innerWidth < prod.screen) {
  //         product_count = prod.product;
  //       }
  //     } else if (prod?.mode && prod?.mode === "greater-than") {
  //       if (window.innerWidth > prod.screen) {
  //         product_count = prod.product;
  //       }
  //     } else {
  //       if (
  //         window.innerWidth >= prod.screen[0] &&
  //         window.innerWidth <= prod.screen[1]
  //       ) {
  //         product_count = prod.product;
  //       }
  //     }
  //   });

  if (window.innerWidth < 576 && layoutView === "single-layout") {
    product_count = 1;
  }

  if (mycat_secure_lock_image_count_based_on === "row") {
    return product_count * Number(mycat_secure_lock_image_count);
  } else {
    //mycat_secure_lock_image_count_based_on === "product"

    return Number(mycat_secure_lock_image_count);
  }
}

const companies_which_have_website = [
  { company_name: "SHANKESH", company_website: "shankeshjewellers.com" },
  { company_name: "KALASHAONLINE", company_website: "kalashaonline.com" },
  { company_name: "TGC", company_website: "tgcwebsite.jewelflow.link" },
  { company_name: "COPL", company_website: "copl.in" },
  // {company_name:"COPL", company_website: "coplwebsite.jewelflow.link"},
  {
    company_name: "BULLIONNERVE",
    company_website: "bullionnervewebsite.jewelflow.link",
  },
  // {company_name:"ZARJEWELS", company_website: "zarjewelswebsite.jewelflow.link"},
  { company_name: "IGJE", company_website: "igje.in" },
  { company_name: "IGJE", company_website: "igjewebsite.jewelflow.link" },
  { company_name: "SOLITAADIAMONDS", company_website: "solitaadiamonds.com" },
  { company_name: "KALASHJEWELS", company_website: "kgopl.com" },
  // {company_name:"KALASHJEWELS", company_website: "kgoplwebsite.jewelflow.link"},
  { company_name: "JEWELSMITH", company_website: "jewelsmithindia.com" },
  {
    company_name: "JEWELSMITH",
    company_website: "jewelsmithwebsite.jewelflow.link",
  },
  {
    company_name: "SIYARAMJEWELLS",
    company_website: "siyaramjewellswebsite.jewelflow.link",
  },
  { company_name: "GROVERCHAIN", company_website: "groverchain.com" },
  {
    company_name: "SWARNSHILP",
    company_website: "swarnshilpwebsite.jewelflow.link",
  },
  {
    company_name: "CHAINHOUSE24",
    company_website: "chainhouse24website.jewelflow.link",
  },
  {
    company_name: "LIVEDEMO",
    company_website: "livedemowebsite.jewelflow.link",
  },
  {
    company_name: "KANAKRATNA",
    company_website: "kanakratnawebsite.jewelflow.link",
  },
  // { company_name: "RASIKA", company_website: "production.jewelflow.link" },
  {
    company_name: "WHITECARATDIAMONDS",
    company_website: "wctwebsite.jewelflow.link",
  },
  { company_name: "ZARJEWELS", company_website: "app.zarjewels.com" },
  { company_name: "MTS", company_website: "mtswebsite.jewelflow.link" },
  {company_name:"WHITECARATDIAMONDS", company_website: "whitecaratdiamonds.in"},
  {company_name: "BULLIONNERVE",
    company_website: "catalog.bullionnerve.com"},
    {company_name:"DREAMSBANGLES", company_website: "dreamsbangles.com"}
];
export function get_company_code_from_website_domain() {
  const website = window.location.hostname;
  const company = companies_which_have_website.find((company) =>
    website.includes(company.company_website)
  );
  if (company) {
    const decryptCompanyCode = btoa(company.company_name);
    set_informationToSessionID({ companyCode: decryptCompanyCode });
    return decryptCompanyCode;
  } else {
    return null;
  }
}

export const Layout = [
  {
    id: 1,
    type: "single-layout",
    image: singleLayout,
    layout_style: "1xn-grid",
  },
  {
    id: 2,
    type: "grid-layout",
    image: gridLayout,
    layout_style: "2xn-grid",
  },
];

export function get_input_control_value(control_value) {
  if (Array.isArray(control_value) && control_value.length > 0) {
    return control_value[0];
  } else if (typeof control_value === "string") {
    return control_value;
  } else {
    return "";
  }
}

export function formatDate(inputDate) {
  //input should be : "2024-04-15 15:49:53"
  // Parse the input date
  let date = new Date(inputDate);

  // Define month names
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract date components
  let day = date.getDate();
  let month = months[date.getMonth()];
  let year = date.getFullYear();

  // Extract time components
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format minutes to always have two digits
  minutes = minutes < 10 ? "0" + minutes : minutes;

  // Construct the formatted date string
  let formattedDate = `${day}-${month}-${year} ${hours}:${minutes}${ampm}`;

  return formattedDate;
}

export function get_search_params() {
  const searchParams = Object.fromEntries(
    new URLSearchParams(document.location.search)
  );
  return searchParams || {};
}

export function ScrollToTopCustom(behavior, delayTime, TopPOS = 0) {
  function scrollTop() {
    if (behavior) {
      window.scrollTo({ top: Number(TopPOS), behavior });
    } else {
      window.scrollTo(Number(TopPOS), 0);
    }
    const HeaderLogo =
      document.getElementById("header-company-logo-2") ||
      document.getElementById("header-company-logo-1");
    if (HeaderLogo) {
      HeaderLogo.scrollIntoView({ behavior: "instant" });
    }

    document.documentElement.scrollTop = Number(TopPOS);
    document.body.scrollTo = Number(TopPOS);
  }
  if (!delayTime) {
    scrollTop();
  } else {
    setTimeout(() => {
      scrollTop();
    }, delayTime);
  }
}

export const copyToClipboard = async (value, successMsg) => {
  try {
    await navigator.clipboard.writeText(value);
    ToastSuccess(successMsg);
  } catch (err) {
    ToastError("Failed to copy");
    // console.error('Failed to copy: ', err);
  }
};

export function set_equal_slick_slider_card_height(className, delay = 100) {
  setTimeout(() => {
    const cards = Array.from(document.querySelectorAll(className));
    if (cards.length === 0) return;
    const maxHeight = Math.max(...cards.map((card) => card.offsetHeight));
    
    if (isNaN(maxHeight)) return;
    cards.forEach((card) => {
      card.style.height = `${maxHeight}px`;
    });
  }, delay);
}

export function reverseTimerContDown(time = 60) {
  let countDownTime = time;
  let displayTime = "";
  const interval = setInterval(() => {
    if (countDownTime <= 0) {
      clearInterval(interval);
    }
    countDownTime--;
    if (countDownTime < 10) {
      displayTime = `00:0${countDownTime}`;
    } else {
      displayTime = `00:${countDownTime}`;
    }
  }, 1000);

  return displayTime;
}

export function append_all_product_menu_in_categories(categories) {
  if (!Array.isArray(categories)) return categories;
  let show_all_product_button_homescreen_menu = get_property_settings(
    "show_all_product_button_homescreen_menu"
  );
  show_all_product_button_homescreen_menu =
    show_all_product_button_homescreen_menu === "show";

  if (show_all_product_button_homescreen_menu) {
    const product_count = categories.reduce((total, list) => {
      total += parseInt(list.product_count) || 0;
      return total;
    }, 0);

    return [
      {
        cat_name: "All Products",
        cat_parent_id: "0",
        id: "0",
        product_count,
        show_subcat: "Yes",
        subcategory: [],
        subcategory_view_style: "tableview",
      },
      ...categories,
    ];
  } else {
    return categories;
  }
}

export function customReactSelectHTMLFilter(option, inputValue) {
  return (
    option?.data?.text?.toLowerCase().indexOf(inputValue?.toLowerCase()) > -1
  );
}

export function zoom_image_tocover_container(category_name) {
  const is_myc = check_is_myc_web();
  if (window.innerWidth > 576 || !is_myc) return false;
  const zoom_based_on = ["long", "haar", "set", "patta"];
  return zoom_based_on.some((based) =>
    category_name.toLowerCase().includes(based)
  );
}

export function get_plain_url_search_params(formData) {
  const params = {};

  for (let key in formData) {
    const value = formData[key];
    if (!!value && !Array.isArray(value) && typeof value === "object") {
      params[key] = value?.value || value || "";
    } else if (Array.isArray(value)) {
      const editdValue = value.map((val) => {
        
        if (!!val && !Array.isArray(val) && typeof val === "object") {
          return val?.value || val || "";
        } else {
          return val || "";
        }
      });

      params[key] = editdValue.join(",");
    } else {
      params[key] = value || "";
    }
  }
  return params;
}

export function get_rgb_array_value(str) {
  const numbers = str.replace(/[a-zA-Z()]/g, "")
  return numbers.split(",").map(num => parseInt(num))
} 

export function rgbToHsl(r, g, b) {
  // Normalize RGB values to the range [0, 1]
  r /= 255;
  g /= 255;
  b /= 255;

  // Find the min and max values among r, g, and b
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const delta = max - min;

  // Initialize hue, saturation, and lightness
  let h = 0;
  let s = 0;
  let l = (max + min) / 2;

  // Calculate hue
  if (delta !== 0) {
      if (max === r) {
          h = ((g - b) / delta) % 6;
      } else if (max === g) {
          h = (b - r) / delta + 2;
      } else {
          h = (r - g) / delta + 4;
      }
      // Convert hue to degrees
      h *= 60;
      if (h < 0) h += 360;
  }

  // Calculate saturation
  if (delta !== 0) {
      s = delta / (1 - Math.abs(2 * l - 1));
  } else {
      s = 0;
  }

  // Convert HSL values to percentages
  s *= 100;
  l *= 100;

  return { h: Math.round(h), s: Math.round(s), l: Math.round(l) };
}



//Set Pattern to BG 
export function set_custom_pattern_bg() {
  const custom_theme = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.custom_theme
  );
  if (!custom_theme) return {};

  
  const style = {
    backgroundImage:
      custom_theme.type === "pattern"
        ? `url(${custom_theme.pattern})`
        : "none",
  };

  return style;
}

export const selected_matster = (master) => {
  //for selected master
  const searchParams = get_search_params()
  const selected_master = searchParams?.selected_master || master || get_informationFromSessionID("selected_master") || ""
  const userData = get_userData_from_activeUser()
  let show_design =  userData?.show_design
      show_design = show_design === "Yes"

  let show_inventory =  userData?.show_inventory
  show_inventory = show_inventory === "Yes"

  const selected_master_params = show_design && show_inventory && selected_master ? {selected_master} : {}

  return selected_master_params
} 

export const check_custom_input_value_in_form = (formData) => {
  const obj = {};
  const form_keys = Object.keys(formData);
  for(const key in formData){
      if(form_keys.includes(`${key}_custom_input`) && !!formData[`${key}_custom_input`]){
        obj[key] = formData[`${key}_custom_input`]
      }else{
        obj[key] = formData[key]
      }
  }

  return obj
} 


export function findMostFrequentNumber(arr) {
  const frequencyMap = {};
  let maxCount = 0;
  let mostFrequentNum = null;

  for (const num of arr) {
      frequencyMap[num] = (frequencyMap[num] || 0) + 1;
      if (frequencyMap[num] > maxCount) {
          maxCount = frequencyMap[num];
          mostFrequentNum = num;
      }
  }

  return mostFrequentNum;
}
