//This is for default master status based on userdata (i.e selected_design_status_master: "1,2", selected_inventory_status_master: "1,2,3,4,5,6,7,9")
//output: "1,2"

import { useSelector } from "react-redux"
import { get_designStatus, get_informationFromSessionID, get_userData_from_activeUser } from "../Component/Helper"
import { getCatMaster } from "../redux/Reducer/reducer"

function useDefaultSelectedMasterStatus() {
    const userID = get_informationFromSessionID("userID");
    const master = useSelector(getCatMaster)
    const design_status = get_designStatus(master);
    if(userID){
        const userData = get_userData_from_activeUser()
        const default_selected_status_master = master === "inventory_master" ? userData?.selected_inventory_status_master : userData?.selected_design_status_master
        return default_selected_status_master
    }else{
        return design_status
    }
}

export default useDefaultSelectedMasterStatus
