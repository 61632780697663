export const live_rate_DB_data = [
    {
    position: 40,
      symbol_linker_key: 'GLD',
      display_name: 'GOLD 995 WITH GST(TDS)',
      socket_sc: 'GOLD-C',
      short_code: 'GOLD995',
      description: 'GOLD 995',
      round_flag: 1,
      decimal_count: 0,
      difference: '1930',
      buy_difference: '-1830',
      low_difference: '0',
      high_difference: '0',
      purity_percent: '100',
      show_low: 1,
      show_high: 1
    },
    {
      position: 80,
      symbol_linker_key: 'GLD',
      display_name: 'GOLD 22KT',
      socket_sc: 'GOLD-C',
      short_code: '22KT',
      description: 'GOLD 22KT',
      round_flag: 1,
      decimal_count: 0,
      difference: '2412',
      buy_difference: '-2312',
      low_difference: '0',
      high_difference: '0',
      purity_percent: '91.7',
      show_low: 1,
      show_high: 1
    },

    {
      position: 50,
      symbol_linker_key: 'SILVER',
      display_name: 'SILVER COSTING',
      socket_sc: 'SILVER-C',
      short_code: 'SLVC',
      description: 'SILVER MCX',
      round_flag: 1,
      decimal_count: 0,
      difference: '0',
      buy_difference: '0',
      low_difference: '0',
      high_difference: '0',
      purity_percent: '100',
      show_low: 1,
      show_high: 1
    },
    {
      position: 10,
      symbol_linker_key: 'GLD',
      display_name: 'GOLD MCX',
      socket_sc: 'GOLD-C',
      short_code: 'MCX995',
      description: 'GOLD MCX RATE',
      round_flag: 0,
      decimal_count: 0,
      difference: '0',
      buy_difference: '0',
      low_difference: '0',
      high_difference: '0',
      purity_percent: '100',
      show_low: 1,
      show_high: 1
    },
    {
      position: 30,
      symbol_linker_key: 'INRSpot',
      display_name: 'USD/INR',
      socket_sc: 'SPOT-INR',
      short_code: 'USD',
      description: 'US DOLLAR TO RUPEE',
      round_flag: 0,
      decimal_count: 3,
      difference: '0',
      buy_difference: '0',
      low_difference: '0',
      high_difference: '0',
      purity_percent: '100',
      show_low: 1,
      show_high: 1
    },
    {
      position: 20,
      symbol_linker_key: 'XAUUSD',
      display_name: 'GOLD SPOT',
      socket_sc: 'SPOT-GOLD',
      short_code: 'SPOT',
      description: 'USD RATE PER OUNCE',
      round_flag: 0,
      decimal_count: 2,
      difference: '0',
      buy_difference: '0',
      low_difference: '0',
      high_difference: '0',
      purity_percent: '100',
      show_low: 1,
      show_high: 1
    }
    ]



  export function live_rate_margin_calc(live_rate_metal_kt, liverate) {

    let new_ask_rate  = 0;
    let new_high_rate = 0;
    let new_bid_rate = 0;
    let new_low_rate = 0;
    if (live_rate_metal_kt.purity_percent > 0) 
      {
          new_ask_rate = parseFloat((parseFloat(liverate.gold1_ask) + parseFloat(live_rate_metal_kt.difference)) * (live_rate_metal_kt.purity_percent / 100));
         new_high_rate = parseFloat((parseFloat(liverate.gold1_high) + parseFloat(live_rate_metal_kt.high_difference)) * (live_rate_metal_kt.purity_percent / 100));

          new_bid_rate = parseFloat((parseFloat(liverate.gold1_bid) - parseFloat(live_rate_metal_kt.buy_difference)) * (live_rate_metal_kt.purity_percent / 100));
          new_low_rate = parseFloat((parseFloat(liverate.gold1_low) - parseFloat(live_rate_metal_kt.low_difference)) * (live_rate_metal_kt.purity_percent / 100));
      } else {
          new_ask_rate = parseFloat(live_rate_metal_kt.difference);
          new_high_rate = parseFloat(live_rate_metal_kt.high_difference);

          new_bid_rate = parseFloat(live_rate_metal_kt.buy_difference);
          new_low_rate = parseFloat(live_rate_metal_kt.low_difference);
      }
      
      // ['display_name' => $display_name, 'rate' => $rate, 'short_code' => $short_code, 'desc' => $desc, 'location' => $ulocation, 'time' => $rate_time];

      if (live_rate_metal_kt.round_flag === "1" || live_rate_metal_kt.round_flag === 1) {
          new_ask_rate = Math.round(new_ask_rate);
          new_high_rate = Math.round(new_high_rate);

          new_bid_rate = Math.round(new_bid_rate);
          new_low_rate = Math.round(new_low_rate);
      }

      if (live_rate_metal_kt.decimal_count > 0) {
          new_ask_rate = new_ask_rate.toFixed(live_rate_metal_kt.decimal_count);
          new_high_rate = new_high_rate.toFixed(live_rate_metal_kt.decimal_count);

          new_bid_rate = new_bid_rate.toFixed(live_rate_metal_kt.decimal_count);
          new_low_rate = new_low_rate.toFixed(live_rate_metal_kt.decimal_count);
      }

      const newData = {
          "display_name": live_rate_metal_kt.display_name,
          "ask_rate": new_ask_rate,
          "bid_rate": new_bid_rate,
          "high_rate": new_high_rate,
          "low_rate": new_low_rate,
          "short_code": live_rate_metal_kt.short_code,
          "desc": live_rate_metal_kt.description,
          "time_stamp": Date.now(),
          "show_low": live_rate_metal_kt.show_low,
          "show_high": live_rate_metal_kt.show_high
      };

      
      // JSON.stringify(newData)
      return newData
  }