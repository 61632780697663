import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Slider from "react-slick";
import { get_property_settings } from "../../Helper";
import ZoomImageComp from "../../ZoomImageComp/ZoomImageComp";

function ImageSlider({ imagesArr, imagePath, showThumbnailPreview }) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  const active_order_details_slide = sessionStorage.getItem("active_order_details_slide") ? Number(sessionStorage.getItem("active_order_details_slide")) : null
  
  const cdn = get_property_settings("cdn")
  
  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  useLayoutEffect(() => {
    if(active_order_details_slide === null) return
    sliderRef1?.slickGoTo(active_order_details_slide - 1)
    sliderRef2?.slickGoTo(active_order_details_slide - 1)
    
    
  }, [active_order_details_slide])

  const slidesToShow = window.innerWidth > 768 ? 5 : 3 
  
  return (
    <div className="slider-container">
      
      {/* <div className={`main-image-preview ${imagesArr.length === 1 ? "full-height" : ""}`}> */}
      <div className={`main-image-preview ${showThumbnailPreview ? "" : "full-height"}`}>
        <Slider asNavFor={nav2} ref={(slider) => (sliderRef1 = slider)} infinite={false}>
          {imagePath ? imagesArr?.map((img) => {
            const image = `${cdn}${imagePath}${img}`;
            
            return <div className="main-image-container" key={img}>
              <ZoomImageComp imgURL={image}/>
            </div>;
          }) : imagesArr?.map((img, index) => {
            
            
            return <div className="main-image-container" key={`main-${index}`}>
              <ZoomImageComp imgURL={img}/>
            </div>;
          })}
        </Slider>
      </div>

      {showThumbnailPreview ? <div className="thumb-image-preview">
        <Slider
          asNavFor={nav1}
          ref={(slider) => (sliderRef2 = slider)}
          slidesToShow={slidesToShow}
          swipeToSlide={true}
          focusOnSelect={true}
          infinite={false}
        >
          {imagePath ? imagesArr.map((img) => {
            const image = `${cdn}${imagePath}${img}`;
            return <div className="thumb-img-container" key={img}>
              <img src={image} alt="thumb" />
            </div>;
          }) : imagesArr.map((img, index) => {
            
            return <div className="thumb-img-container" key={`thum-${index}`}>
              <img src={img?.src || img} alt="thumb" />
            </div>;
          })}
        </Slider>
      </div> : null}
    </div>
  );
}

export default ImageSlider;
