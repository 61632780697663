import React, { useMemo } from 'react'
import ReactSlider from '../../ReactSlider/ReactSlider'
import SingleTestmonialCard from './SingleTestmonialCard'
import { get_Custom_Testimonial_Data } from './custom/set-custom-testimonial-data'

function Testmonial({testmonialData, custom_testimonial = false}) {
    let Custom_Testimonial_Data = useMemo(() => custom_testimonial ? get_Custom_Testimonial_Data() : null, [custom_testimonial]);
   
  return (
    <section className="section ec-test-section section-space-ptb-100 section-space-m testimonial-section" id="reviews">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <div className="section-title mb-0">
                        <h2 className="ec-bg-title">Testimonial</h2>
                        <h2 className="ec-title">Client Review</h2>
                        <p className="sub-title mb-3">What Our Client Says About Us</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="ec-test-outer">
                    <ul id="ec-testimonial-slider">
                        <ReactSlider additionalSetting={{autoplay: true, autoplaySpeed: 5000, cssEase: "linear", pauseOnHover: false, adaptiveHeight: true}}>
                            
                              
                            {!!Custom_Testimonial_Data ? (
                               Custom_Testimonial_Data.data.map(testmonial => <Custom_Testimonial_Data.COMPONENT key={testmonial.id} {...testmonial}/>))
                             : (testmonialData.map(testmonial => <SingleTestmonialCard key={testmonial.position} {...testmonial}/>))} 
                            
                        </ReactSlider>
                    </ul>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Testmonial
