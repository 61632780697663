import { get_informationFromSessionID } from "../Component/Helper";
import { Company, CUSTOMIZIED_COMPANY_PROPERTY } from "./CompanyCustomization";
import BullionNervePattern from "../assets/images/bullionnerve/bullion-nerve-bg-pattern.jpg";

export const CustomizedComapaniesList = {
  KALASHAONLINE: new Company({
    new_arrivals: "KALASHA BEST SELLER",
    custom_menu: [
      {
        name: "menu",
        link: "/categories",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Custom Order",
        link: "/custom-order",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Live Rates",
        link: "/live-rates",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Swarna Kalasham",
        link: "/static-page/services/swarna-kalasham",
        external_link: false,
        static_group: true,
      },
      {
        name: "Book Video Call",
        link: `/static-page/services/video-call?company_code=S0FMQVNIQU9OTElORQ&external_string=4476446347`,
        external_link: false,
        static_group: true,
      },
      {
        name: "EXCLUSIVE OFFER FOR NRI's",
        link: `/static-page/services/exclusive-offer-for-nri`,
        external_link: false,
        clsName: "no-uppercase",
        static_group: true,
      },
    ],
    horizontal_menu: {
      status: true,
      custom_dropdown_style: {
        mega_menu: { width: "min(1200px, 100vw)" },
        columns: { gridTemplateColumns: "repeat(4, 1fr)" },
      },
      LIST_MAX_COUNT: 5,
      CUSTOM_MAX_COUNT: { Diamond: 4, Gold: 7, Jadau: 3 },
    },
    center_horizontal_menu: true,
    hide_elements: ["Qty_Remark"],
    show_total_in_cart_footer: true,
    hide_menu_when_user_not_loggedin: true,
    show_floating_social_icons: true,
    center_compnay_logo: true,
    fsv_hover_zoom_image: true,
    show_homescreen_animated_category: true,
    show_horizontal_align_location_in_footer_section: true, //show location details in row instead of column
    show_matrix_branding_banner: true,
    show_matrix_pagination_at_bottom: true,
    store_location_bg_banner_image_position: "top left",
    hide_background_store_location_card: true,
    show_only_product_image_in_home_productbase_section: true,
    show_hover_shadow_effect_on_home_product_card: true,
    show_extracted_subcategory_in_desktop_dropdown_menu: {
      status: true,
      extracted_categories: ["Mens"],
    },
    show_contact_us_in_matrix_branding: {
      status: true,
      category: ["Coins"],
      body: { title: "Contact Us For Customization", mobile_no: "9393992499" },
    },
    show_thumbnail_in_menu_dropdown: true,
    shift_upward_home_banner_gradient: true,
    custom_footer_links: [
      {
        name: "History",
        link: "static-page/our-legacy/history",
        external_link: false,
        custom_link: true,
      },
      {
        name: "The Management",
        link: "static-page/our-legacy/management",
        external_link: false,
        custom_link: true,
      },
      {
        name: "Awards & Accolades",
        link: "static-page/our-legacy/awards",
        external_link: false,
        custom_link: true,
      },
      {
        name: "Campaigns",
        link: "static-page/our-legacy/campaign",
        external_link: false,
        custom_link: true,
      },
      {
        name: "News & Events",
        link: "static-page/our-legacy/news-events",
        external_link: false,
        custom_link: true,
      },
      {
        name: "Newsletter",
        link: "static-page/our-legacy/news-letters",
        external_link: false,
        custom_link: true,
      },
      {
        name: "Press Releases",
        link: "static-page/our-legacy/press-release",
        external_link: false,
        custom_link: true,
      },
      {
        name: "Customization",
        link: "static-page/our-legacy/customization",
        external_link: false,
        custom_link: true,
      },
      {
        name: "Special Jewellery Lounges",
        link: "static-page/services/special-jewellery",
        external_link: false,
        custom_link: true,
      },
      {
        name: "Jewellery Redesign & Upgrade",
        link: "static-page/services/jewellery-redesign",
        external_link: false,
        custom_link: true,
      },
      {
        name: "Bridal Appointment",
        link: "static-page/services/bridal-appointment",
        external_link: false,
        custom_link: true,
      },
      {
        name: "Video Call Shopping",
        link: "static-page/services/video-call",
        external_link: false,
        custom_link: true,
      },
      {
        name: "Jewellery Care",
        link: "static-page/services/jewellery-care",
        external_link: false,
        custom_link: true,
      },
    ],
  }),

  COPL: new Company({
    horizontal_menu: {
      status: true,
      custom_dropdown_style: {
        mega_menu: { width: "min(1000px, 90vw)" },
        columns: { gridTemplateColumns: "repeat(5, 1fr)" },
      },
      LIST_MAX_COUNT: 5,
    },
    custom_menu: [
      {
        name: "Custom Order",
        link: "/custom-order",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Menu",
        link: "/categories",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Live Rates",
        link: "/live-rates",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "About Us",
        link: "/about-us",
        external_link: false,
        static_group: true,
      },
      {
        name: "Contact Us",
        link: "/contact-us",
        external_link: false,
        static_group: true,
      },
    ],
    show_about_us_comp_on_home_page: true,
  }),

  KALASHJEWELS: new Company({
    custom_menu: [
      {
        name: "Custom Order",
        link: "/custom-order",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Menu",
        link: "/categories",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Live Rates",
        link: "/live-rates",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "About Us",
        link: "/about-us",
        external_link: false,
        static_group: true,
      },
    ],
    show_about_us_comp_on_home_page: true,
    show_footer_custom_location_link: true,
  }),

  TGC: new Company({
    custom_menu: [
      {
        name: "Custom Order",
        link: "/custom-order",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Menu",
        link: "/categories",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Live Rates",
        link: "/live-rates",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Contact Us",
        link: "/contact-us",
        external_link: false,
        static_group: true,
      },
    ],
  }),

  JEWELSMITH: new Company({
    show_about_us_comp_on_home_page: true,
    show_floating_social_icons: true,
    show_small_video_for_branding: true,
    hide_product_count: true,
    custom_theme: {
      type: "color", //pattern OR color
      pattern: null,
      theme: "dark", //light OR dark
    },
    custom_footer_links: [
      {
        name: "Features",
        link: "static-page/our-legacy/features",
        external_link: false,
        custom_link: true,
      },
    ],

    custom_menu: [
      {
        name: "Custom Order",
        link: "/custom-order",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Menu",
        link: "/categories",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Live Rates",
        link: "/live-rates",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Features",
        link: "static-page/our-legacy/features",
        external_link: false,
        static_group: true,
      },

      {
        name: "About Us",
        link: "/about-us",
        external_link: false,
        static_group: true,
      },

      {
        name: "Contact Us",
        link: "/contact-us",
        external_link: false,
        static_group: true,
        custom_link: true,
      },
    ],

    // custom_footer_links: [
    //   {
    //     name: "Campaign",
    //     link: "/campaign",
    //     external_link: false,
    //     custom_link: true,
    //   },
    // ],
  }),

  BULLIONNERVE: new Company({
    show_horizontal_align_location_in_footer_section: true,
    custom_theme: {
      type: "pattern", //pattern OR color
      pattern: BullionNervePattern,
      theme: "dark", //light OR dark
    },
    cart_to_fsv_redirection: true,
    show_floating_social_icons: true,
    user_access_massage: {
      before_login: [
        "Kindly Call on 9711048803  to get user access of all the designs.",
      ],
      after_login: [
        "Kindly call on 9711048803 to get user access for availability of Stock items and it’s quantity",
        "Kindly Complete your profile for admin to grant user access",
      ],
    },
  }),

  ZARJEWELS: new Company({
    hide_elements_from_cart_footer: ["net_wt"],
    show_horizontal_align_location_in_footer_section: true,
    show_random_product_option_in_named_wishlist: true,
    show_floating_social_icons: true,
    show_matrix_details_in_grid: true,
    show_pcs_sets_in_cart_footer: true,
    cart_to_fsv_redirection: true,
    show_matrix_pagination_at_bottom: true,
    calculate_oms_weight_based_on_design_no: true, //oms => custom order
    // custom_reposition_cart_params: [
    //   "Size",
    //   "Qty",
    //   "GWt",
    //   "Total GWt",
    //   "Remark",
    // ],
    custom_menu: [
      {
        name: "Menu",
        link: "/categories",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Custom Order",
        link: "/custom-order",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
    ],
    show_fsv_custom_varient_details: true,
    fsv_custom_varient_details: [
      { label: "Design No", under: null, key: null },
      { label: "Wt", under: "short_code", key: "gross_wt" },
      // { label: "Net Wt", under: "short_code", key: "net_wt" },
    ],
  }),

  CHAINHOUSE24: new Company({
    center_compnay_logo: true,
    show_floating_social_icons: true,
    show_matrix_pagination_at_bottom: true,
    show_fsv_custom_varient_details: true,
    cart_to_fsv_redirection: true,
    fsv_custom_varient_details: [
      { label: "Item Name", under: "root", key: "product_name" },
      { label: "Design No", under: null, key: null },
    ],
    custom_menu: [
      {
        name: "About Us",
        link: "/about-us",
        external_link: false,
        static_group: true,
        custom_link: true,
      },
      {
        name: "Menu",
        link: "/categories",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Custom Order",
        link: "/custom-order",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Live Rates",
        link: "/live-rates",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Contact Us",
        link: "/contact-us",
        external_link: false,
        static_group: true,
        custom_link: true,
      },
    ],
    custom_footer_links: [
      {
        name: "About Us",
        link: "/about-us",
        external_link: false,
        custom_link: true,
      },
      {
        name: "Contact Us",
        link: "/contact-us",
        external_link: false,
        custom_link: true,
      },
    ],
  }),

  IGJE: new Company({
    custom_footer_links: [
      {
        name: "Custom Order",
        link: "/custom-order",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Menu",
        link: "/categories",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Live Rates",
        link: "/live-rates",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "About Us",
        link: "/about-us",
        external_link: false,
        custom_link: true,
      },
    ],
    show_about_us_comp_on_home_page: true,
    show_shortlist_icon_as_named_shortlist: true,
  }),

  DREAMSBANGLES: new Company({
    show_about_us_comp_on_home_page: true,
    show_custom_order_status_in_row_style: true,
    show_floating_social_icons: true,
    center_compnay_logo: true,
    custom_menu: [
      {
        name: "Menu",
        link: "/categories",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Custom Order",
        link: "/custom-order",
        external_link: false,
        static_group: false,
        custom_link: true,
      },

      {
        name: "About Us",
        link: `/about-us`,
        external_link: false,
        static_group: true,
      },
    ],
  }),

  SHANKESH: new Company({
    show_about_us_comp_on_home_page: true,
    custom_testimonial: true,
    custom_footer_links: [
      {
        name: "About Us",
        link: "/about-us",
        external_link: false,
        custom_link: true,
      },
    ],
    custom_menu: [
      {
        name: "Menu",
        link: "/categories",
        external_link: false,
        static_group: false,
        custom_link: true,
      },

      {
        name: "About Us",
        link: `/about-us`,
        external_link: false,
        static_group: true,
      },
      {
        name: "Contact Us",
        link: `/contact-us`,
        external_link: false,
        static_group: true,
        custom_link: true,
      },
    ],
  }),

  SWARNSHILP: new Company({
    show_floating_social_icons: true,
  }),

  LIVEDEMO: new Company({
    show_floating_social_icons: true,
    show_video_calling: true,
    video_calling: {
      has_video_call_banner: false,
    },
    custom_menu: [{
      name: "Custom Order",
      link: "/custom-order",
      external_link: false,
      static_group: false,
      custom_link: true,
    },
    {
      name: "Menu",
      link: "/categories",
      external_link: false,
      static_group: false,
      custom_link: true,
    },
    {
      name: "Live Rates",
      link: "/live-rates",
      external_link: false,
      static_group: false,
      custom_link: true,
    },
    {
      name: "Video Calling",
      link: "/video-calling",
      external_link: false,
      static_group: false,
      custom_link: true,
    }
  ]
  }),

  PRJEWELLERS: new Company({
    center_compnay_logo: true,
    show_matrix_pagination_at_both: true,
    hide_elements_from_cart_footer: ["net_wt"],
  }),

  GROVERCHAIN: new Company({
    show_fsv_custom_varient_details: true,
    show_horizontal_align_location_in_footer_section: true,
    show_left_header_extra_company_logo: true,
    show_floating_social_icons: false,
    footer_height_to_adjest_download_app_image: "540px",
    center_compnay_logo: true,
    fsv_custom_varient_details: [
      { label: "Item Name", under: "root", key: "product_name" },
      { label: "Design No", under: null, key: null },
      { label: "Gross Wt", under: "short_code", key: "gross_wt" },
      { label: "Net Wt", under: "short_code", key: "net_wt" },
    ],
    custom_menu: [
      {
        name: "Custom Order",
        link: "/custom-order",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Menu",
        link: "/categories",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Live Rates",
        link: "/live-rates",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "News & Updates",
        link: "/news-updates",
        external_link: false,
        static_group: false,
      },
      {
        name: "About Us",
        link: "/static-page/about-us?company_code=R1JPVkVSQ0hBSU4=&id=1",
        external_link: false,
        static_group: true,
      },
      {
        name: "Contact Us",
        link: "/static-page/contact-us?company_code=R1JPVkVSQ0hBSU4=&id=2",
        external_link: false,
        static_group: true,
      },
      {
        name: "Bank Account Details",
        link: "/static-page/bank-account-details?company_code=R1JPVkVSQ0hBSU4=&id=3",
        external_link: false,
        type: "mobile-menu",
        static_group: true,
      },
    ],
  }),
};

export const getCustomValue = (value) => {
  const companyCode = get_informationFromSessionID("companyCode");
  if (!companyCode) return;
  const decryptCompanyName = atob(companyCode);

  const companyCustom = CustomizedComapaniesList[decryptCompanyName];

  if (typeof value === "string" && companyCustom) {
    return companyCustom[value];
  } else if (value === CUSTOMIZIED_COMPANY_PROPERTY.custom_menu) {
    return [
      {
        name: "Custom Order",
        link: "/custom-order",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Menu",
        link: "/categories",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
      {
        name: "Live Rates",
        link: "/live-rates",
        external_link: false,
        static_group: false,
        custom_link: true,
      },
    ];
  }

  return null;
};
