import React, { useRef, useState } from "react";
import "./CategoryAnimation.css"
import {motion} from "framer-motion"
import { createSearchURLParams, get_bulk_settings } from "../../Helper";
import NoImg from "../../../assets/images/no_image_available.png"
import { is_plural_base_on_count } from "../../../utils";
import { useNavigate } from "react-router-dom";

const CategoryAnimation = ({category, setShowCategoryModal}) => {
 
const {id, show_homescreen, category_img, cat_name, product_count, subcategory} = category;

const {cdn, default_image, show_hide_category_counts} = get_bulk_settings(["cdn", "default_image", "show_hide_category_counts"]) || {}
const defaultImg = default_image ? `${cdn}uploads/default_image/${default_image}` : NoImg;
const img = category_img ? `${cdn}uploads/branding_image/category/${category_img}` : defaultImg;

const hasSubcategory = Array.isArray(subcategory) && subcategory?.length > 0

const navigate = useNavigate()
const [startCategoryAnimation, _] = useState(true)
const animatedCategoryRef = useRef(null)

// const handleScroll = useCallback(() => {
//   if(!animatedCategoryRef.current) return
//   const viewportHeight = window.innerHeight;
//   const animationCategoryCord = animatedCategoryRef.current.getBoundingClientRect()

//   if(animationCategoryCord.y > viewportHeight){
//     setStartCategoryAnimation(true)
//   }else if(animationCategoryCord.bottom < 60){
//     setStartCategoryAnimation(false)
//   }
// }, [])

// useEffect(() => {

// window.addEventListener("scroll", handleScroll)

// return () => window.removeEventListener("scroll", handleScroll)
// }, [handleScroll])

const textInitialAnimation = window.innerWidth > 768 ? {x: 0, y: 50, opacity: 0} : {x: -50, opacity: 0}
const textInViewAnimation = window.innerWidth > 768 ? {x: 0, y: -50, opacity: 1} : {x: 0, opacity: 1}

if(show_homescreen === "0") return null

  return (
    <section ref={animatedCategoryRef}>
        {
          startCategoryAnimation ? (
            <div className="framer-motion-left-right-animation">
        <div className="left-comp">
            <motion.div className="text-container"
              initial = {textInitialAnimation}
              whileInView = {textInViewAnimation}
              viewport={{once: true}}
              transition={{ type: "tween", x: {duration: 0.5}, y: {duration: 0.5}}}
            >
              <span className="cat-title">{cat_name}</span>
              {show_hide_category_counts === "show" && <span className="cat-subtitle">{is_plural_base_on_count(product_count) ? 'Designs' : 'Design'} {product_count} {is_plural_base_on_count(product_count) ? 'Pcs' : 'Pc'}</span>}
              
              </motion.div>
        </div>
      
        <motion.div className="right-comp"
          initial={{x:  100, opacity: 0 }}
          whileInView={{x: 0, opacity: 1}}
          // viewport={{amount: "some", once: true}}
          viewport={{margin: "-100px", once: true}}
          transition={{type: "tween", x: {duration: 1}}}
          
          onClick={() => {
            if(hasSubcategory){
              navigate(createSearchURLParams('home', {cat_id: id}))
              setShowCategoryModal(true)
          }else{
              navigate(createSearchURLParams('matrix-page', {cat_id: id, mode:'filter', page: 0}))
              setShowCategoryModal(false)
          }
          }}
        >
            <img src={img} alt={"product-cat"} />
        </motion.div>

     
      
      </div>
          ) : (
            <div className="framer-motion-left-right-animation">
        <div className="left-comp">
            <div className="text-container"
              
            >
              <span className="cat-title">{cat_name}</span>
              {show_hide_category_counts === "show" && <span className="cat-subtitle">{is_plural_base_on_count(product_count) ? 'Designs' : 'Design'} {product_count} {is_plural_base_on_count(product_count) ? 'Pcs' : 'Pc'}</span>}
              
              </div>
        </div>
      
        <div className="right-comp"
          
          
          onClick={() => {
            if(hasSubcategory){
              navigate(createSearchURLParams('home', {cat_id: id}))
              setShowCategoryModal(true)
          }else{
              navigate(createSearchURLParams('matrix-page', {cat_id: id, mode:'filter', page: 0}))
              setShowCategoryModal(false)
          }
          }}
        >
            <img src={img} alt={"product-cat"} />
        </div>

     
      
      </div>
          )
        }
    </section>
      
      
  );
};

export default React.memo(CategoryAnimation);