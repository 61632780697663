import React, { useEffect } from 'react'
import { get_informationFromSessionID } from '../../Component/Helper';
import CustomLoginPage from './CustomLoginPage/KalashaOnline/CustomLoginPage';
import LoginNew from './LoginNew';
import { ScrollToTopCustom } from '../../utils';

function LoginRedirection() {
    const companyCode = get_informationFromSessionID("companyCode");

    const decryptCompanyName = atob(companyCode);

   
  switch (decryptCompanyName) {
    case "KALASHAONLINE": return <CustomLoginPage header="Login to View Our Collection"/>
    case "DREAMSBANGLES": return <CustomLoginPage className='dreams-bangles-login'/>
    default: return <LoginNew />
  }
}

export default LoginRedirection
