import React, { useEffect, useMemo, useState } from "react";
import { ApiList } from "../../Api/ApiList";
import {
  createSelectArrayForReactSelect,
  get_bulk_settings,
  get_informationFromSessionID,
  get_plain_value_from_inputs,
  ToastError,
} from "../../Component/Helper";
import { useLocation, useNavigate } from "react-router-dom";
import Textfield from "./components/Textfield";
import Phonefield from "./components/Phonefield";
import Passwordfield from "./components/Passwordfield";
import Selectfield from "./components/Selectfield";
import TextAreafield from "./components/TextAreafield";
import { fetch_data_from_api } from "../../Api/APICalls";
import { useForm, Controller } from "react-hook-form";
import { EMAIL_REGEX, MOBILE_REGEX, sentenceCase } from "../../utils";
import RegistrationVerification from "./VerificationNew";
import { useSelector } from "react-redux";
import {
  get_is_myc_web,
  get_myc_allow_new_registration,
  get_myc_catalogue_id,
} from "../../redux/Reducer/reducer";
import DatePicker from "./components/DatePicker";
import GSTField from "./components/GSTField";
import TermsConditionInput from "./components/TermsConditionInput";

const RegistrationNew = () => {
  const [formParamsData, setFormParamsData] = useState([]);
  const [formData, setFormData] = useState({});

  const [show, setShow] = useState(false);
  const [countriesOption, setCountriesOption] = useState([]);
  const [statesOption, setStatesOption] = useState([]);
  const [citiesOption, setCitiesOption] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [ResOTP, setResOTP] = useState("");
  const catalogue_id = useSelector(get_myc_catalogue_id) || "";
  let is_myc_web = useSelector(get_is_myc_web);
  let myc_allow_new_registration = useSelector(get_myc_allow_new_registration);
  let { country_name, mycat_v2_login_based_on, login_based_on } =
    get_bulk_settings([
      "country_name",
      "mycat_v2_login_based_on",
      "login_based_on",
    ]);

  const location = useLocation();
  const navigate = useNavigate();
  const state = JSON.parse(sessionStorage.getItem("state"));
  const { mobile_no, country_code, password } = location.state || state || {};
  let mobilenoWithCountryCode = "";

  if (mobile_no && country_code) {
    mobilenoWithCountryCode = `${country_code}${mobile_no}`;
  }

  if (state) {
    sessionStorage.removeItem("state");
  }

  const fetchFormParams = async () => {
    const formParams_response = await fetch_data_from_api(
      `${ApiList.UserRegistration}/${ApiList.get_registration_form_params}`,
      "get-registration-form-params",
      {
        reg_type: 0,
        login_from: is_myc_web ? "myc" : "web",
      }
    );
    if (Array.isArray(formParams_response) && formParams_response.length > 0) {
      setFormParamsData(formParams_response);
    }
  };

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mobile_no: mobilenoWithCountryCode || "",
      password: password || "",
      confirm_password: password || "",
      email_id: "",
      full_name: "",
      company_name: "",
      country_id: "",
      state_id: "",
      city_id: "",
    },
  });

  const selectedCountry = watch("country_id")?.value || "";
  const selectedState = watch("state_id")?.value || "";
  const reg_where = watch("reg_where")?.value || "";
  

  const country = watch("country_id");

  useEffect(() => {
    if (!reg_where || reg_where.toLowerCase() !== "other") return;
    const custom_reg_where = document.querySelector("#custom_reg_where");
    if (custom_reg_where) {
      custom_reg_where.focus();
    }
  }, [reg_where]);

  async function fetch_country() {
    const fetch_country_response = await fetch_data_from_api(
      `${ApiList.UserRegistration}/${ApiList.get_loc_data}`,
      "get-local-data",
      {
        id: "",
        table: "countries",
      }
    );

    const countries = createSelectArrayForReactSelect(
      fetch_country_response,
      "name",
      "id"
    );

    const defaultCountry = countries
      ? countries?.find(
          (country) =>
            country.label.toLowerCase() === country_name?.toLowerCase()
        )
      : "";

    setCountriesOption(countries);
    setValue("country_id", defaultCountry);
    fetch_state(defaultCountry?.value);
  }

  async function fetch_state(country_id) {
    const fetch_state_response = await fetch_data_from_api(
      `${ApiList.UserRegistration}/${ApiList.get_loc_data}`,
      "get-local-data",
      {
        id: country_id,
        table: "states",
      }
    );

    const states = createSelectArrayForReactSelect(
      fetch_state_response,
      "name",
      "id"
    );

    setStatesOption(states);
  }

  async function fetch_cities(state_id) {
    const fetch_cities_response = await fetch_data_from_api(
      `${ApiList.UserRegistration}/${ApiList.get_loc_data}`,
      "get-local-data",
      {
        id: state_id,
        table: "cities",
      }
    );

    const cities = createSelectArrayForReactSelect(
      fetch_cities_response,
      "name",
      "id"
    );

    setCitiesOption(cities);
  }

  async function fetch_currency() {
    const fetch_currency_response = await fetch_data_from_api(
      `${ApiList.Currency}`,
      "Currency"
    );

    const currency = createSelectArrayForReactSelect(
      fetch_currency_response,
      "currency",
      "id"
    );
    //const defaultCurrency = fetch_currency_response.find(currency => currency.default === "1")

    setCurrency(currency);
  }

  useEffect(() => {
    if (is_myc_web && !myc_allow_new_registration) {
      ToastError("This mobile no. is not register with us");
      return navigate("/login");
    }
    fetchFormParams();
    fetch_currency();
    fetch_country();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      fetch_state(selectedCountry);
    }
    if (selectedState) {
      fetch_cities(selectedState);
    }
  }, [selectedCountry, selectedState]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const onSubmit = async (data) => {
    data = data ? data : getValues();
    
    //remove short_code = checkbox  (Terms and conditions)
    if(data?.checkbox){
      delete data.checkbox
    }
    
    //if there is no password in register form then delete password and confirm_password from data
    const has_password_field = formParamsData.some(data => data.short_code === "password")
    if(!has_password_field) {
      delete data?.password;
      delete data?.confirm_password;
    }
    
    // console.log(data)
    const { full_name, email_id, mobile_no } = data;
    const country_code = get_informationFromSessionID("country_code");
    const generate_verification_keys_params = {
      country_code: country_code ? `+${country_code}` : "+91",
      email_id,
      full_name: sentenceCase(full_name),
      gen_referral_code: "",
      mobile_no: mobile_no.replace(country_code, ""),
      ref_code: "",
      session_id: "",
      catalogue_id,
      login_from: is_myc_web ? "myc" : "web",
    };

    //set blank password when is_myc_web mode is "ON" and password and confirm password is hide in registration form
    // if (is_myc_web && mycat_v2_login_based_on === "otp" && !data?.password) {
    //   generate_verification_keys_params.password = '""';
    //   generate_verification_keys_params.confirm_password = '""';
    // }else 
    
    if(!!data?.password){
      generate_verification_keys_params.password = data?.password;
      generate_verification_keys_params.confirm_password = data?.confirm_password;
    }
   
    const generate_verification_keys_response = await fetch_data_from_api(
      `${ApiList.UserRegistration}/${ApiList.generate_verification_keys}`,
      "generate-verification-keys",
      generate_verification_keys_params
    );

   

    if (generate_verification_keys_response?.ack === "0") {
      ToastError(
        generate_verification_keys_response?.error || "Something Went Wrong"
      );
    } else {
      const {
        country_id: { value: countryValue },
        state_id: { value: stateValue },
        city_id: { value: cityValue },
      } = data;
      const fdata = {
        ...data,
        ...generate_verification_keys_params,
        reg_where: data.custom_reg_where || data.reg_where || "",
        country_id: countryValue,
        state_id: stateValue,
        city_id: cityValue,
      };
      
      const edited_fdata = {};
      for (const key in fdata) {
        edited_fdata[key] = get_plain_value_from_inputs(fdata[key]);
      }
      setResOTP(generate_verification_keys_response?.mobile_key);
      setFormData(edited_fdata);
      setShow(true);
    }
  };

  let terms_condition_dummy = null

  //   terms_condition_dummy = {
  //   "field_name": "Terms & Conditions",
  //   "short_code": "checkbox",
  //   "required": "1",
  //   "control_type": "Checkbox",
  //   "control_value": [
  //   "I agree to the company's Terms & Conditions and Privacy Policy. I want to receive consultation offers and other updates through Call SMS and WhatsApp."
  //   ],
  //   "validation_options": ""
  //   }



  const terms_condition = useMemo(() => {
    // return null
    if(formParamsData?.length === 0) return null
    return formParamsData.find(data => data?.short_code === "checkbox" && data?.field_name ===  "Terms & Conditions") || terms_condition_dummy;
   
  }, [formParamsData])

  
  

  return (
    <>
      {show ? (
        <RegistrationVerification
          ResOTP={ResOTP}
          formData={formData}
          refetchOTP={onSubmit}
        />
      ) : (
        formParamsData &&
        formParamsData.length > 0 && (
          <section id="RegContaier">
            <div className="container-fluid">
              <div className="ec-register-wrapper register-wrapper">
                <div className="header">
                  <div className="section-title m-0 d-flex justify-content-center">
                    <h2 className="my-4 ec-title title">Register</h2>
                  </div>
                </div>
                <div className="ec-register-container ">
                  <div className="ec-register-form">
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                      {formParamsData &&
                        formParamsData.length > 0 &&
                        formParamsData.map((formParams, formParamsIndex) => {
                          return (
                            <React.Fragment key={formParamsIndex}>
                              {formParams.control_type === "Textfield" && (
                                <>
                                  {formParams.short_code !== "whats_app_no" ? (
                                    <>
                                      <Controller
                                        name={formParams.short_code}
                                        control={control}
                                        rules={{
                                          required: {
                                            value: formParams?.required === "1",
                                            message: `${formParams?.field_name} is required`,
                                          },
                                          pattern: {
                                            value:
                                              formParams.short_code ===
                                              "email_id"
                                                ? EMAIL_REGEX
                                                : true,
                                            message: "Invaild Email ID",
                                          },
                                        }}
                                        render={({
                                          field: { onChange, value },
                                        }) => {
                                          return (
                                            <Textfield
                                              formParams={formParams}
                                              clsName="ec-register-wrap ec-register-half ec-register-three mb-2"
                                              onChange={onChange}
                                              value={value}
                                              errors={errors}
                                            />
                                          );
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <Phonefield
                                        formParams={formParams}
                                        clsName="ec-register-wrap ec-register-half ec-register-three mb-2"
                                      />
                                    </>
                                  )}
                                </>
                              )}
                              {formParams.control_type === "Password" && (
                                  <>
                                    <Controller
                                      name={formParams.short_code}
                                      control={control}
                                      rules={{
                                        required: {
                                          value: formParams?.required === "1",
                                          message: `${formParams?.field_name} is required`,
                                        },
                                        validate: (fieldValue) => {
                                          if (
                                            formParams.short_code ===
                                            "confirm_password"
                                          ) {
                                            const passwordValue =
                                              getValues("password");
                                            return (
                                              passwordValue === fieldValue ||
                                              `${formParams?.field_name} does not match`
                                            );
                                          }
                                        },
                                      }}
                                      render={({
                                        field: { onChange, value },
                                      }) => {
                                        return (
                                          <Passwordfield
                                            formParams={formParams}
                                            clsName="ec-register-wrap ec-register-half ec-register-three mb-2"
                                            onChange={onChange}
                                            value={value}
                                            errors={errors}
                                          />
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              {formParams.control_type === "Telephone" && (
                                <>
                                  <Controller
                                    name={formParams.short_code}
                                    control={control}
                                    rules={{
                                      required: {
                                        value: formParams?.required === "1",
                                        message: `${formParams?.field_name} is required`,
                                      },

                                      validate: (fieldValue) => {
                                        const country_code =
                                          get_informationFromSessionID(
                                            "country_code"
                                          );
                                        const onlyMobileNo = fieldValue.replace(
                                          country_code,
                                          ""
                                        );
                                        return (
                                          MOBILE_REGEX.test(onlyMobileNo) ||
                                          `${formParams?.field_name} is invaild`
                                        );
                                      },
                                    }}
                                    render={({
                                      field: { onChange, value },
                                    }) => {
                                      return (
                                        <Phonefield
                                          formParams={formParams}
                                          clsName="ec-register-wrap ec-register-half ec-register-three mb-2"
                                          onChange={onChange}
                                          value={value}
                                          errors={errors}
                                        />
                                      );
                                    }}
                                  />
                                </>
                              )}
                              {formParams.control_type === "Country" && (
                                <>
                                  <Selectfield
                                    formParams={formParams}
                                    clsName="ec-register-wrap ec-register-half ec-register-three mb-2"
                                    Controller={Controller}
                                    control={control}
                                    errors={errors}
                                    options={countriesOption}
                                  />
                                </>
                              )}
                              {formParams.control_type === "State" && (
                                <>
                                  <Selectfield
                                    formParams={formParams}
                                    clsName="ec-register-wrap ec-register-half ec-register-three mb-2"
                                    Controller={Controller}
                                    control={control}
                                    errors={errors}
                                    options={statesOption}
                                  />
                                </>
                              )}
                              {formParams.control_type === "City" && (
                                <>
                                  <Selectfield
                                    formParams={formParams}
                                    clsName="ec-register-wrap ec-register-half ec-register-three mb-2"
                                    Controller={Controller}
                                    control={control}
                                    errors={errors}
                                    options={citiesOption}
                                  />
                                </>
                              )}
                              {formParams.control_type === "Textarea" && (
                                <>
                                  <TextAreafield
                                    formParams={formParams}
                                    clsName="ec-register-wrap ec-register-half ec-register-three mb-2"
                                  />
                                </>
                              )}
                              {formParams.control_type === "Currency" && (
                                <>
                                  <Selectfield
                                    formParams={formParams}
                                    clsName="ec-register-wrap ec-register-half ec-register-three mb-2"
                                    Controller={Controller}
                                    control={control}
                                    errors={errors}
                                    options={currency}
                                  />
                                </>
                              )}
                              {formParams.control_type === "Dropdown" && (
                                <>
                                  <Selectfield
                                    formParams={formParams}
                                    clsName="ec-register-wrap ec-register-half ec-register-three mb-2"
                                    Controller={Controller}
                                    control={control}
                                    errors={errors}
                                  />
                                </>
                              )}

                              {formParams.control_type === "Datepicker" && (
                                <DatePicker
                                  formParams={formParams}
                                  register={register}
                                  clsName="ec-register-wrap ec-register-half ec-register-three mb-2"
                                  errors={errors}
                                />
                              )}
                            </React.Fragment>
                          );
                        })}
                      {/* OTHER DROPDOWN FOR REG_WHERE */}

                      {reg_where.toLowerCase() === "other" && (
                        <span className="ec-register-wrap ec-register-half ec-register-three mb-2">
                          <textarea
                            rows={3}
                            placeholder="Other"
                            {...register(`custom_reg_where`, {
                              required: {
                                value: reg_where.toLowerCase() === "other",
                                message: "This field is required",
                              },
                            })}
                            id="custom_reg_where"
                          />
                          {errors[`custom_reg_where`] && (
                            <div className="required-field">
                              {errors[`custom_reg_where`]?.message}
                            </div>
                          )}
                        </span>
                      )}

                      {/* FOR GST */}

                      {country && country?.cin_label ? (
                        <GSTField
                          formParams={country}
                          clsName="ec-register-wrap ec-register-half ec-register-three mb-2"
                          errors={errors}
                          register={register}
                        />
                      ) : null}

                      {/* FOR GST */}

                      {!!terms_condition  ? (
                        <TermsConditionInput
                          formParams={terms_condition}
                          clsName="ec-register-wrap mb-2 checkbox terms"
                          errors={errors}
                          register={register}
                        />
                      ) : null}

                      <span className="ec-register-wrap ec-register-btn ">
                        <button
                          className="btn btn-primary  mt-2 primary_button btn-primary"
                          type="submit"
                          tabIndex={formParamsData && formParamsData.length}
                        >
                          Submit
                        </button>
                      </span>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      )}
    </>
  );
};

export default RegistrationNew;
