import React from 'react'
import styles from "./TitleHeader.module.css";

export default function TitleHeader({title=null}) {
  if(!title) return null
  return (
    <header className={styles.titleHeader}>
        {title}
    </header>
  )
}
