import React, { useState } from 'react'
import OrderPlaceModal from '../../Component/OrderPlaceModal/OrderPlaceModal'
import CustomOrderModal from '../CustomOrder/CustomOrderModal';

export default function NewCustomOrderPage() {

    const [showPlacedModal, setShowPlacedModal] = useState({
        show: false,
        response: {
          title: "Thanks for placing your order using KAM",
          subtitle: "We hope you had a great overall experience using this app",
        },
      });

      
     
  return (
    <>
    {showPlacedModal.show && (
        <OrderPlaceModal
          showPlacedModal={showPlacedModal}
          setShowPlacedModal={setShowPlacedModal}
          header="Order Placed Successfully"
          autoClose={true}
        />
      )}
    <div>
    <CustomOrderModal setShowPlacedModal={setShowPlacedModal}/>
    </div>
    </>
  )
}
