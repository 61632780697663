import React, { useEffect } from "react";
import "./SHANKESH_About_Us.css";
import { ScrollToTopCustom } from "../../../utils";
import ShankeshIconLogo from "../../../assets/images/shankesh/shankesh-logo-icon.png";
import Shakesh_Awards from "./components/Shakesh_Awards";
import Clientele_Image_Grid from "./components/Shakesh_Clientele_Image_Grid";
import Shakesh_Client_Testimonial from "./components/Shakesh_Client_Testimonial";

export default function SHANKESH_About_Us() {
  useEffect(() => {
    ScrollToTopCustom();
  }, []);

   

  return (
    <section className="shankesh-about-us-page">
      <h2>About Us</h2>

      <div>
        <article>
          {/* <h3 className="sub-title">
            <img
              src={ShankeshIconLogo}
              alt="shankesh-logo-icon"
              className="sub-title-img"
            />
            Introduction
          </h3> */}
          <section className="content-section">
            <div className="image-container">
            <picture>
            <source media="(min-width:920px)" srcSet="https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/banner/About-us-thumb-Desktop+1.jpg" />
            <img src="https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/banner/About-us-thumb-Mobile.jpg" alt="about-us-thumb"/>
          </picture>
              
            </div>
            <div className="info-container about-us-content">
              <h3>Shankesh Jewellers Pvt Ltd: Where Heritage Meets Excellence</h3>
              <p>
              Founded in 1987, Shankesh Jewellers Pvt Ltd is a renowned Mumbai-based jewellery wholesaler with a rich legacy of over three decades. Our family's passion for gold and jewellery has been the driving force behind our success.

              </p>

              <p>
                <b>Our Mission</b><br/>
                At Shankesh Jewellers, we strive to achieve new heights every day. We believe in building lasting relationships with our clients and artisans, founded on the principles of integrity, trust, assurance, and transparency.
              </p>

              <p>
                <b>Our Expertise</b><br/>
                Our master craftsmen are dedicated to creating exquisite jewellery pieces that showcase superior craftsmanship and attention to detail. From traditional to contemporary designs, our collections cater to diverse tastes and preferences.
              </p>

              <p>
                <b>Our Legacy</b><br/>
                As a trusted supplier to the corporate world, Shankesh Jewellers has earned a reputation for delivering fine jewellery products that exceed expectations. Our commitment to quality, innovation, and customer satisfaction has made us a household name in the jewellery industry.
              </p>

              <p>
                <b>Our Vision</b><br/>
                We aim to continue pushing boundaries and embracing challenges to bring forth unique and breathtaking collections to the jewellery world. With a keen eye on market trends and a deep understanding of our clients' needs, we strive to remain at the forefront of the industry.
              </p>

              <p>
              <b>This enhanced version includes:</b><br/>
              <ul>
                <li>A catchy title that highlights the company's heritage and excellence</li>
                <li>A more detailed and descriptive mission statement</li>
                <li>Emphasis on the company's expertise and craftsmanship</li>
                <li>A separate section highlighting the company's legacy and reputation</li>
                <li> A vision statement that outlines the company's future goals and aspirations</li>
              </ul>
              </p>
            </div>
          </section>
        </article>

        <article>
          <h3 className="sub-title">
            <img
              src={ShankeshIconLogo}
              alt="shankesh-logo-icon"
              className="sub-title-img"
            />
            Our Directors
          </h3>
          <section className="content-section">
            <div className="image-container">
              <img
                src="https://cdn.jfone.jewelflow.pro/shankesh/uploads/company_logo/6848-7745-shankesh-logo.png"
                alt="shankesh-logo-icon"
              />
            </div>
            <div className="info-container">
              <p>
                Mahavir Kantilal Jain, the promoter of the company, joined the
                business in 2007 and brings with him 18 years of experience in
                the jewelry industry, backed by an MBA. Inspired by a family
                legacy in the gold business, he has always been captivated by
                the endless creativity and design possibilities within the
                jewelry field. His vision for the company is to achieve new
                heights daily through innovative designs, creativity, and strong
                customer relationships. Mahavir emphasizes values of integrity,
                quality assurance, and fostering long-term relationships with
                both clients and artisans. Under his leadership, the company has
                become a priority vendor for major corporate jewelry companies,
                while maintaining a commitment to ethical sourcing and cultural
                preservation. His leadership continues to shape the company's
                goals and culture, driving its ongoing success.
              </p>
            </div>
          </section>
        </article>

        <article>
          <h3 className="sub-title">
            <img
              src={ShankeshIconLogo}
              alt="shankesh-logo-icon"
              className="sub-title-img"
            />
            Awards and Accolades
          </h3>
          <section className="content-section no-switch award-section">
            <Shakesh_Awards />
          </section>
        </article>


        <article>
          <h3 className="sub-title">
            <img
              src={ShankeshIconLogo}
              alt="shankesh-logo-icon"
              className="sub-title-img"
            />
            Our Clientele
          </h3>
          <section className="content-section no-switch clientele-section">
            <p className="info-container">Shankesh Jewellers Pvt Ltd continues to set the benchmark for exceptional jewelry in Mumbai's Zaveri Bazaar. Our dedication to delivering exquisite craftsmanship, unique designs, and impeccable service has earned us the trust and admiration of our valued customers. As we look ahead, we remain committed to pushing boundaries, embracing innovation, and creating timeless pieces that reflect the true essence of beauty and artistry.</p>

            <div>
                <Clientele_Image_Grid />
            </div>
          </section>
        </article>


        <article>
          <h3 className="sub-title">
            <img
              src={ShankeshIconLogo}
              alt="shankesh-logo-icon"
              className="sub-title-img"
            />
            Client Testimonial
          </h3>
          <section className="content-section no-switch testimonial-section">
            <Shakesh_Client_Testimonial />
          </section>
        </article>

      </div>
    </section>
  );
}
