
import { useEffect } from "react";
import "./DREAMSBANGLES_About_Us.css";

function DREAMSBANGLES_About_Us() {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <div className="aboutUs dreams-bangles-about-us">
      <header>
        <h2>About Us</h2>
        
      </header>

      <section>
        <div className="image-section">
            <img src="https://cdn.jfone.jewelflow.pro/dreamsbangles/uploads/company_logo/1131-login_logo@3x_(2).png" alt="" />
        </div>
        <div className="details-section">
            {/* <h3>Lorem ipsum dolor sit ?</h3> */}
            <p>Our identity lies in the essence that dreams represent, which is more than just a word or name. Dreams embody the aura that our products emanate. </p>

           <p>
           Dreams Bangles is known for its ethical standards, commitment to excellence, consistent product quality, and transparent business practices. With years of expertise as a manufacturer, we constantly innovate our products and styles to match our expertise with evolving technology. 
           </p>

           <p>
           Our aim is to work in synergy with our clients and embrace their demands for better designs every day.
           </p>

           <p>With the introduction of this website, we want to bring an enormous product collection to your fingertips for wholesale purchase. Browse through our products exclusively on our website</p>
        </div>
      </section>
    </div>
  )
}

export default DREAMSBANGLES_About_Us
