import { get_informationFromSessionID } from "../../../../Helper"
import KalashaMobileApp from "../../../../../assets/images/kalashaonline/kalasha-mobile-app.png"
import ZarJewelsMobileApp from "../../../../../assets/images/zarjewels/zar-jewels-mobile-app-mockup.png"
import SwarnshilpMobileApp from "../../../../../assets/images/swarnshilp/SWARNSHILP-app-mockup.png"
import JewelSmithMobileApp from "../../../../../assets/images/jewelsmith/jewelsmith-mobile-app-mockup.png"
import GCCMobileApp from "../../../../../assets/images/gcc/GCC_mobile_mockup.png"
import BullionNerveMobileApp from "../../../../../assets/images/bullionnerve/bullionnerve-mobile-app.png"
import MTSMobileApp from "../../../../../assets/images/mts/mobile-app-mockup.png"
import ShankeshMobileMock from "../../../../../assets/images/shankesh/shankesh-mobile-mockup.png"

export const getDownloadAppImage = () => {
    const company_code = get_informationFromSessionID("companyCode");
    const decryptCompanyName = atob(company_code);
    switch(decryptCompanyName){
        case "KALASHAONLINE" : return KalashaMobileApp;
        case "ZARJEWELS" : return ZarJewelsMobileApp;
        case "SWARNSHILP" : return SwarnshilpMobileApp;
        case "JEWELSMITH" : return JewelSmithMobileApp;
        case "GROVERCHAIN" : return GCCMobileApp;
        case "MTS" : return MTSMobileApp;
        case "SHANKESH" : return ShankeshMobileMock;
        case "DREAMSBANGLES" : return "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/dreamsbangles/uploads/website/mobile_app_mock/Dream-Bangles-Mobile-App-Mock.png";
        // case "BULLIONNERVE" : return BullionNerveMobileApp;
        default : return null
    }
}