import React from 'react'
import { shankesh_testimonial_filterd_data } from '../../../../Component/HomeComponents/Testmonial/custom/SHANKESH/shankesh_testimonial_data'
import ShankeshTestmonialCard from '../../../../Component/HomeComponents/Testmonial/custom/SHANKESH/ShankeshTestmonialCard'

export default function Shakesh_Client_Testimonial() {
  
  return (
    <ul className="testimonial-page-Container ec-test-section">
            {
                shankesh_testimonial_filterd_data.map((testimonial, index) => <ShankeshTestmonialCard key={testimonial.id} 
                style={index === shankesh_testimonial_filterd_data.length - 1 ? {gridColumn: '1 / -1'} : {}}
                {...testimonial} 
                />
              )
            }

    </ul>
  )
}
