import { ApiList } from "./ApiList";
import { CSS_StyleSettings } from "../Component/Helper";

export const FILTER_DATA_ACTION_KEYS = {
    user_data: "user_data",
    settings_data: "settings_data"
}


const filter_data = {
    [FILTER_DATA_ACTION_KEYS.user_data]: [
        "ack",
        "full_name",
        "show_design",
        "show_inventory",
        "user_id",
        "mobile_no",
        "client_type",
        "client_id",
        "show_wastage",
        "show_material",
        "show_total_amt",
        "show_other_charges_details",
        "in_cart_id_dm",
        "in_cart_id_im",
        "in_wishlist_id_dm",
        "in_wishlist_id_im",
        "country_code",
        "company_name",
        "city",
        "selected_design_status_master",
        "selected_inventory_status_master",
        "password",
        "show_kam_based_on_user",
        "user_type",
        "show_past_order",
        "show_live_rate",
        "is_cat_allocated"
    ],

    [FILTER_DATA_ACTION_KEYS.settings]: [
        ...CSS_StyleSettings,
        
    ]
}

function get_list_of_filter_Keys(api_endpoint){
    if(api_endpoint === ApiList.login){
        return filter_data[FILTER_DATA_ACTION_KEYS.user_data]
    }

    else{
        return null
    }
    
}

export function data_filter_function(response_data, api_endpoint) {
    const list_of_filter_keys = get_list_of_filter_Keys(api_endpoint);
    
    if(list_of_filter_keys){
        return list_of_filter_keys.reduce((obj, currentKey) => {
            obj[currentKey] = response_data[currentKey];
            return obj
        }, {})
    }else{
        return response_data
    }
    
}

