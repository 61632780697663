import { decryptData, encryptData, fetch_data_from_api, PROXY_BASE_URL } from "../../Api/APICalls";
import { ApiList } from "../../Api/ApiList";
import aws_image_upload from "../../aws/aws_image_upload";
import {
  get_bulk_settings,
  get_informationFromSessionID,
  get_plain_value_from_inputs,
  get_property_settings,
  get_userData_from_activeUser,
} from "../../Component/Helper";
import { MOBILE_REGEX } from "../../utils";
import axios from "axios";


export function vaildate_mobileNo(data) {
  const { billing_mobile_no, shipping_mobile_no } = data;
  let isError = { status: false, errors: [] };

  if (billing_mobile_no?.phoneNumber || shipping_mobile_no?.phoneNumber) {
    if (
      !MOBILE_REGEX.test(
        billing_mobile_no?.phoneNumber.replace(
          billing_mobile_no?.country_code,
          ""
        )
      )
    ) {
      isError.errors.push([
        "billing_mobile_no",
        { type: "manual", message: "Invalid mobile no." },
      ]);
      isError.status = true;
    }

    if (
      shipping_mobile_no?.phoneNumber &&
      !MOBILE_REGEX.test(
        shipping_mobile_no?.phoneNumber.replace(
          shipping_mobile_no?.country_code,
          ""
        )
      )
    ) {
      isError.errors.push([
        "shipping_mobile_no",
        { type: "manual", message: "Invalid mobile no." },
      ]);
      isError.status = true;
    }
  }

  return isError;
}

function get_string_data_from_form(data) {
  if (typeof data === "string") return data;
  if (data?.value && data?.label) return data?.value;
  //For Mobile No.
  if (data?.phoneNumber && data?.country_code) return `+${data?.phoneNumber}`;
  return data;
}

export function setFinalData(data, hide_shipping_details) {
  let shipping_data = {};
  let billing_and_shipping_data = data;
  if (hide_shipping_details) {
    for (const key in data) {
      const shippingKey = key.replace("billing_", "shipping_");
      shipping_data[shippingKey] = data[key];
    }

    billing_and_shipping_data = {
      ...billing_and_shipping_data,
      ...shipping_data,
    };
  }

  for (const key in billing_and_shipping_data) {
    billing_and_shipping_data[key] = get_string_data_from_form(
      billing_and_shipping_data[key]
    );
  }

  return billing_and_shipping_data;
}

export const billing_doc_bucket_s3_path = () => {
  let jewelkam_bucket_name = get_property_settings("jewelkam_bucket_name");
  const companyCode = get_informationFromSessionID("companyCode");
  let decryptCompanyName = atob(companyCode);
  decryptCompanyName = decryptCompanyName.toLowerCase()

  return `${jewelkam_bucket_name}/${decryptCompanyName}/uploads/billing_doc`
}

export const s3_credentiontial = () => {
  const s3Pass = get_property_settings("s3Pass")
  const s3Key = get_property_settings("s3Key")
  return {
    accesskeyID: s3Pass,
    secretAccessKey: s3Key,
  };

  
}

//******************************** RAZORPAY INTEGRATION ************************************************************************************* */

export async function createOrder(amount, currency) {
  //  const IS_DEVELOPMENT_MODE = process.env.NODE_ENV === 'development'
    // const url = IS_DEVELOPMENT_MODE ? 'http://localhost:9090' : PROXY_BASE_URL
    const url = PROXY_BASE_URL
  const order = await axios.post(`${url}/api-payment/order`, {
    params: encryptData({amount, currency}),
  });
 
  return decryptData(order?.data);
}

export const setFormDataValue = (formData) => {
    
    const has_shipping_details = Object.keys(formData).includes("shipping_name")
    let data = {};
    for(const key in formData){
      const value = formData[key];
      if(key.includes('mobile_no')){
        data[key] = `+${value?.phoneNumber}`
      }else if(key.includes("image")){
        const files = Object.values(value)
        data[key] = files.map(file => `web_${file?.name.replace(/\s/g, "-")}`).join('@@')
        
        // data[key] = value.files[0].file.name
      }
      else{
        data[key] = get_plain_value_from_inputs(value)
      }

    }

    if(!has_shipping_details){
      const data2 = {}
      for(const key in data){
        const edited_key = key.replace('billing_', 'shipping_')
        if(edited_key.includes("shipping_")){

          data2[edited_key] = data[key];
        }
      }
      data = {...data, ...data2}
    }

    return data
    
    //{ phoneNumber: '911111111111', country_code: '91' }
    // get_plain_value_from_inputs
    
}

export function get_payment_gateway_details(order, formdata, adhaar_card_image, pan_card_image) {
    const KEY_ID = process.env.REACT_APP_PAYMENT_TEST_KEY_ID || "rzp_test_lOKbOw2XffVf8E"
  //  const KEY_SECRET = process.env.REACT_APP_PAYMENT_TEST_KEY_SECRET || 'OQkM5HmCGBjvnn3dM4QLIUde'
    
  const { amount, currency, id } = order;
  const { billing_name, billing_email, billing_mobile_no, billing_address } =
    formdata;

  const { cdn, company_logo, highlight_color } = get_bulk_settings([
    "cdn",
    "company_logo",
    "highlight_color",
  ]);
  const logoImg = `${cdn}uploads/company_logo/${company_logo}`;

  const client_type = get_userData_from_activeUser().client_type || "1";

  const companyCode = get_informationFromSessionID("companyCode");
  const decryptCompanyName = atob(companyCode);

  const options = {
    key: KEY_ID, // Enter the Key ID generated from the Dashboard
    amount: amount || 0, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: currency || "INR",
    name: decryptCompanyName,
    description: "Test Transaction",
    image: logoImg,
    order_id: id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    // "callback_url": "http://localhost:9090/api-payment/verification",
    handler: async function (response) {
      

    //   const body_data = response.razorpay_order_id + "|" + response.razorpay_payment_id;

      
    //   const expected_signature = CryptoJS
    //     .createHmac("sha256", KEY_SECRET)
    //     .update(body_data)
    //     .digest("hex");

    //   const isVaild = expected_signature === response.razorpay_signature;

    //   if (isVaild) {
    //     console.log({ isVaild, razorpay_payment_id:  response.razorpay_payment_id});
    //     // const encrypted = encryptData({razorpay_payment_id, razorpay_order_id, timeStamp: new Date().toLocaleString()})
    //     return navigate(`http://localhost:3000/success`,
    //     {
    //         state: {
    //             payment_id: response.razorpay_payment_id, 
    //             order_id: response.razorpay_order_id,
    //             timeStamp: new Date().toLocaleString()
    //         }
    //     }
    
    //     )
        
    //   } 

    const timeStamp = new Date().toLocaleString()

    /** UPLOAD BILLING DOCS TO S3 BUCKET */
    const adhaar_card =  !!adhaar_card_image ? adhaar_card_image[0] : null
    const pan_card = !!pan_card_image ? pan_card_image[0] : null
    const images = [adhaar_card, pan_card].filter(img => !!img)
    
    const credential = s3_credentiontial()
    const bucket_path = billing_doc_bucket_s3_path()
    
    const uploadPromises = images.map((image) =>
      aws_image_upload(image,bucket_path, credential)
    );
    await Promise.all(uploadPromises);
    
    //Store data to backend
    const place_order_params = {
      device: "web",
      web_order: "1",
      client_type,
      ...formdata,
      razorpay_status: "success",
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_order_id: response.razorpay_order_id,
      razorpay_signature: response.razorpay_signature,
      razorpay_timeStamp: timeStamp,
      razorpay_description: "",

    }
   
    // eslint-disable-next-line no-unused-vars
    const place_order_response = await fetch_data_from_api(
      ApiList?.Finalize,
      "finalize",
      place_order_params
    );
   
   
    //Redirection after payement success
    const url = process.env.NODE_ENV === "development" ? 'http://localhost:4000' : document.location.origin;
    const encrypted = encryptData({
      payment_id: response.razorpay_payment_id,
      order_id: response.razorpay_order_id,
      timeStamp
    })
    window.location.replace(`${url}/success?rs=${encodeURIComponent(encrypted)}`)
    // window.location.replace(`${url}/success?payment_id=${response.razorpay_payment_id}&order_id=${response.razorpay_order_id}&timeStamp=${new Date().toLocaleString()}`)
    
    // return navigate(`http://localhost:3000/success`,
    //     {
    //         state: {
    //             payment_id: response.razorpay_payment_id, 
    //             order_id: response.razorpay_order_id,
    //             timeStamp: new Date().toLocaleString()
    //         },
    //         replace: true
    //     },
    
    //     )
      
    //   else {
    //     console.log({ status: "failed" });
    //     return res.redirect("http://localhost:3000/failed");
    //   }
    },
    prefill: {
      name: billing_name,
      email: billing_email,
      contact: billing_mobile_no,
    },
    notes: {
      address: billing_address,
    },
    theme: {
      color: highlight_color,
    },
  };

  const rzp1 = new window.Razorpay(options);
  rzp1.on("payment.failed", async function (response) {
    const error = await response?.error
    // console.log(error.code);
    // console.log(error.description);
    // console.log(error.source);
    // console.log(error.step);
    // console.log(error.reason);
    // console.log(error.metadata.order_id);
    // console.log(error.metadata.payment_id);

    const timeStamp = new Date().toLocaleString()
    
    //Store data to backend
    const place_order_params = {
      device: "web",
      web_order: "1",
      client_type,
      ...formdata,
      razorpay_status: "failed",
      razorpay_payment_id: error.metadata.payment_id,
      razorpay_order_id: error.metadata.order_id,
      razorpay_signature: "",
      razorpay_timeStamp: timeStamp,
      razorpay_description: error.description,

    }
    //console.log(place_order_params)
    // eslint-disable-next-line no-unused-vars
    const place_order_response = await fetch_data_from_api(
      ApiList?.payment_failed,
      "payment_failed",
      place_order_params
    );
    

    const url = process.env.NODE_ENV === "development" ? 'http://localhost:4000' : document.location.origin;
    const encrypted = encryptData({
      payment_id: error.metadata.payment_id,
      order_id: error.metadata.order_id,
      timeStamp: timeStamp,
      description: error.description
    })
    window.location.replace(`${url}/failed?rs=${encodeURIComponent(encrypted)}`)
    //window.location.replace(`${url}/failed?payment_id=${error.metadata.payment_id}&order_id=${error.metadata.order_id}&timeStamp=${new Date().toLocaleString()}&description=${error.description}`)

    // return navigate(`http://localhost:3000/failed`, {
    //     state: {
    //         payment_id: error.metadata.payment_id, 
    //         order_id: error.metadata.order_id,
    //         timeStamp: new Date().toLocaleString(),
    //         description: error.description
    //     }
    // })
  });

  rzp1.open();
}
