import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  get_myc_is_secured,
  set_trigger_cart_btn,
  set_trigger_shortlist_btn,
  setConstants,
  get_inCartWishList,
  set_inCartWishList,
  get_is_myc_web,
  get_martix_view_layout,
  get_selected_currency,
  getCatMaster,
} from "../../redux/Reducer/reducer";
import { NumericFormat } from "react-number-format";
import { ApiList } from "../../Api/ApiList";
import {
  ToastError,
  ToastSuccess,
  createSearchURLParams,
  get_bulk_settings,
  get_design_inventory_and_realated_cart_property,
  get_informationFromSessionID,
  get_product_status_details,
  get_userData_from_activeUser,
  set_productID_inCartWishList,
} from "../Helper";
// import CartIcon from "../../assets/images/ic_matrix_cart@3x.png";

import Tag from "../Tag";
import { fetch_data_from_api } from "../../Api/APICalls";
import noImg from "../../assets/images/no_image_available.png";
import secure_image from "../../assets/images/secure-image.jpg";
import { displayed_product_in_matrix_myc_for_secure, selected_matster } from "../../utils";
import { getCustomValue } from "../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../Custom/CompanyCustomization";
import { formatPrice } from "../HomeComponents/HomeHeader/components/UserProfileDropDownMenu/Components/ChangeCurrencyModal/change_currency_helper";
import ShortListIcon from "./Components/ShortListIcon";
import CartIcon from "./Components/CartIcon";
import "./ProductNew.css";
import ATCButton from "./Components/ATCButton";
import FullScreenImageSlider from "../FullScreenImageSlider/FullScreenImageSlider";

const ProductNew = ({
  index,
  sort,
  item,
  saveStateToLocalStorage,
  display_card_for_product_base_section,
  matrix_data_count,
  show_hover_shadow_effect_on_home_product_card,
}) => {
  const location = useLocation();

  //General Settings
  const {
    show_hide_design_master_new_arrival,
    new_arrival_badge_bg_color,
    new_arrival_badge_fg_color,
    matrix_stepper_and_buttons,
    allow_lessthan_moq,
    enable_shortlist,
    matrix_shortlist_button_position,
    matrix_cart_button_position,
    show_center_label_matrix,
    center_label_matrix_value,
    cdn,
    show_price_label_matrix,
    is_pinned_badge_fg_color: pinned_badge_fg_color,
    is_pinned_badge_bg_color: pinned_badge_bg_color,
    is_pinned_matrix_string: pinned_matrix_string,
    default_image,
    show_named_wishlist_app,
    open_fullscreen_or_zoomview,
    pre_login_open_fsv_or_login_from_matrix,
    set_image_bg_based_on_product_image,
    matrix_label_color,
    matrix_value_color
  } = useMemo(
    () =>
      get_bulk_settings([
        "show_hide_design_master_new_arrival",
        "new_arrival_badge_bg_color",
        "new_arrival_badge_fg_color",
        "matrix_stepper_and_buttons",
        "matrix_add_to_cart_button",
        "allow_lessthan_moq",
        "enable_shortlist",
        "matrix_shortlist_button_position",
        "matrix_cart_button_position",
        "show_center_label_matrix",
        "center_label_matrix_value",
        "cdn",
        "show_price_label_matrix",
        "is_pinned_badge_fg_color",
        "is_pinned_badge_bg_color",
        "is_pinned_matrix_string",
        "default_image",
        "btn_primary_color",
        "show_named_wishlist_app",
        "open_fullscreen_or_zoomview",
        "pre_login_open_fsv_or_login_from_matrix",
        "set_image_bg_based_on_product_image",
        "matrix_label_color",
        "matrix_value_color"
      ]) || {},
    []
  );

  const show_image_bg = set_image_bg_based_on_product_image === "Yes"
  const showStepper = display_card_for_product_base_section
    ? "No"
    : matrix_stepper_and_buttons;

  // const showAddToCart = display_card_for_product_base_section
  //   ? "No"
  //   : matrix_add_to_cart_button;

  const showAddToCart = display_card_for_product_base_section ? "No" : "Yes";

  const show_price_label =
    location.pathname === "/matrix-page" && show_price_label_matrix === "Yes";

  const show_only_product_image_in_home_productbase_section =
    display_card_for_product_base_section &&
    getCustomValue(
      CUSTOMIZIED_COMPANY_PROPERTY.show_only_product_image_in_home_productbase_section
    );

    const show_matrix_details_in_grid = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_matrix_details_in_grid)

  //Show Named Wishlist Popup
  const show_named_wishlist =
    enable_shortlist === "Yes" && show_named_wishlist_app === "Yes";

  //Pinned Product
  const show_pinned = item?.is_pinned === "1";

  //Session Values
  const user_id = get_informationFromSessionID("userID");

  //Product Object

  const defaultImg = default_image
    ? `${cdn}uploads/default_image/${default_image}`
    : noImg;

  const design_master_new_arrival = item?.design_master_new_arrival === "1";
  const centerLabel =
    show_center_label_matrix === "Yes" ? item[center_label_matrix_value] : "";

  //Show Add To Cart Button In Matrix
  const show_atc_matrix = item?.show_atc_matrix === "1";

  let image = `${
    item.design_files
      ? `${cdn}${item.image_path}${item.design_files}`
      : defaultImg
  }`;
  const label = item?.label;
  const label_visible = Array.isArray(item?.label_visible)
    ? item?.label_visible
    : [];
  const values = item?.values;
  const minQty = item?.min_order_quantity;
  const maxQty = item?.max_order_quantity;
  const design_inventory = item?.design_inventory;
  const inCartWishList = useSelector(get_inCartWishList);
  const selected_currency = useSelector(get_selected_currency);

  //MYC secure locked image
  const is_myc_is_secured = useSelector(get_myc_is_secured);
  const is_myc_web = useSelector(get_is_myc_web);
  const layoutView = useSelector(get_martix_view_layout);
  const get_display_product_count_for_secure = useMemo(() => {
    if (!is_myc_web || user_id) return null;

    return displayed_product_in_matrix_myc_for_secure(layoutView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_myc_is_secured, layoutView]);

  if (
    !user_id &&
    !is_myc_is_secured &&
    get_display_product_count_for_secure !== null
  ) {
    if (index + 1 > get_display_product_count_for_secure) {
      image = secure_image;
    }
  }

  const [count, setCount] = useState(
    Number(item?.in_cart_qty) || parseInt(minQty)
  );
  const [itemIsInCart, setItemIsInCart] = useState(false);
  const [addToCartloading, setAddToCartloading] = useState(false);
  const [buttonLabel, setButtonLabel] = useState(() => {
    if (!inCartWishList) return "ADD TO CART";
    const { typeOfID, property } =
      get_design_inventory_and_realated_cart_property(item?.design_inventory);

    if (
      inCartWishList[property]?.includes(item[typeOfID]) &&
      showStepper === "Yes"
    ) {
      return "UPDATE";
    } else if (
      inCartWishList[property]?.includes(item[typeOfID]) &&
      showStepper === "No"
    ) {
      return "REMOVE";
    } else {
      return "ADD TO CART";
    }
  });

  const ATC_BUTTON_CLASS =
    buttonLabel === "UPDATE" || buttonLabel === "REMOVE"
      ? "update-button"
      : "add-to-cart-button";

  const product_status_details = get_product_status_details(
    item?.status_details
  );

  const dispatch = useDispatch();

  const searchParams = Object.fromEntries(new URLSearchParams(location.search));

  const navigate = useNavigate();
  const cat_id = searchParams?.cat_id || 0;


  const pre_login_open_login_from_matrix = pre_login_open_fsv_or_login_from_matrix === "login" && !user_id

  
  const master = useSelector(getCatMaster);
  const selected_master_params = selected_matster(master)
  

  const FSV_redirection_link =
    searchParams.mode === "named_wishlist"
      ? createSearchURLParams(`product-page/${index}`, {
          sort,
          cat_id,
          mode: searchParams?.mode,
          wishlist_id: searchParams?.wishlist_id,
          ...selected_master_params
        })
      : location.pathname.includes("product-page")
      ? createSearchURLParams(`product-page/${index}`, {
          sort,
          cat_id,
          mode: "homescreen",
          product_id: item.design_master_id || item.inventory_master_id,
          ...selected_master_params
        })
      : location.pathname.includes("home")
      ? createSearchURLParams(`product-page/${index}`, {
          sort,
          cat_id,
          mode: "homescreen",
          product_id: item.design_master_id || item.inventory_master_id,
          ...selected_master_params
        })
      : // `/product-page/${index}?mode=homescreen&product_id=${item.design_master_id}&sort=${sort}&cat_id=${cat_id}`
      searchParams?.mode === "product_search_online"
      ? createSearchURLParams(`product-page/${index}`, {...searchParams, ...selected_master_params})
      : createSearchURLParams(`product-page/${index}`, { sort, cat_id, ...selected_master_params, ...searchParams });
  // `/product-page/${index}?sort=${sort}&cat_id=${cat_id}`

  const Increment = () => {
    let add = parseInt(count) + 1;
    if (parseInt(maxQty) === 0) {
      setCount(add);
    } else if (add > parseInt(maxQty)) {
      ToastError(`Max ${maxQty} Qty Reached.`);
    } else {
      setCount(add);
    }
  };

  const Decrement = () => {
    const is_allow_lessthen_moq = allow_lessthan_moq === "Yes";

    if (is_allow_lessthen_moq && parseInt(count) <= parseInt(minQty)) return;

    if (!itemIsInCart && parseInt(count) === 1) return;

    if (parseInt(count) > 0) {
      setCount(parseInt(count) - 1);
    }
  };

  const set_ATC_button_label = (cart_wish_list = null) => {
    cart_wish_list = !!cart_wish_list ? cart_wish_list : inCartWishList
    const { typeOfID, property } =
      get_design_inventory_and_realated_cart_property(item.design_inventory);
      let btn_label = ""
    //inCartWishList[property].includes(item[typeOfID])
    if (
      cart_wish_list[property].includes(item[typeOfID]) &&
      showStepper === "Yes" &&
      count === 0
    ) {
      btn_label = "ADD TO CART"
      // setButtonLabel("ADD TO CART");
    } else if (showStepper === "Yes") {
      btn_label = "UPDATE"
      // setButtonLabel("UPDATE");
    } else if (
      cart_wish_list[property].includes(item[typeOfID]) &&
      showStepper === "No"
    ) {
      btn_label = "REMOVE"
      // setButtonLabel("REMOVE");
    } else {
      // setButtonLabel("ADD TO CART");
       btn_label = "ADD TO CART"
    }
   
    setButtonLabel(btn_label)
  };

  
  

  async function handle_add_to_cart_button() {
    if(addToCartloading) return
    if (!user_id) return navigate("/login");
    const productID = Get_Product_ID_Property_Name();
    setAddToCartloading(true);

    const { typeOfID, property } = get_design_inventory_and_realated_cart_property(item?.design_inventory);
    const is_product_in_cart = showStepper === "No" && inCartWishList[property].includes(item[typeOfID])

    let action =
      buttonLabel === "UPDATE" || buttonLabel === "ADD TO CART"
        ? "add"
        : "remove";

    action = is_product_in_cart ? "remove" : action
    await handle_cart_and_wishlist("cart", item[productID], action);
    setItemIsInCart(true);
    // set_ATC_button_label();
  }

  const handle_cart_and_wishlist = async (typeOfCart, product_id, action) => {
    if (!user_id && !inCartWishList) return navigate("/login");
    // if((typeOfCart !== "cart" || typeOfCart !== "wishlist") || (action !== "add" || action !== "remove")){
    //   throw new Error("typeOfCart And Action must be required")
    // }


    const table = typeOfCart === "wishlist" ? "temp_wishlist" : "temp_cart";
    const defaultParams = {
      user_id,
      session_id: 0,
      table,
      design_inventory,
      product_id,
    };

    const params =
      action === "add"
        ? {
            ...defaultParams,
            quantity: count,
            mode: "with_subproduct",
            type_of_user: "normal_user",
          }
        : {
            ...defaultParams,
          };

    const API_Endpoint =
      action === "add"
        ? `${ApiList.cart}/${ApiList.addToCartMatrix}`
        : `${ApiList.cart}/${ApiList.Delete_From_Cart_Wishlist_Matrix}`;
    const Proxy_Endpoint =
      action === "add" ? "add-to-cart" : "remove-from-cart";
    const response = await fetch_data_from_api(
      API_Endpoint,
      Proxy_Endpoint,
      params
    );

    const is_Response_is_an_object = response?.constructor === Object;

    //if there is an error
    if (is_Response_is_an_object && response.ack === "0") {
      return ToastError(response?.msg);
    } else {
      if (is_Response_is_an_object) {
        if (window.innerWidth <= 575) {
          typeOfCart === "cart" && dispatch(set_trigger_cart_btn(true));
          typeOfCart === "wishlist" &&
            dispatch(set_trigger_shortlist_btn(true));
          setAddToCartloading(false);
        } else {
          ToastSuccess(response?.msg);
          setAddToCartloading(false);
        }
        dispatch(setConstants({ count: response?.cart_total }));

        const typeOfID =
          item.design_inventory === "design_master"
            ? "design_master_id"
            : item.design_inventory === "inventory_master"
            ? "inventory_master_id"
            : "";

        let updated_cart_wish_list = {};
        if (response?.action === 1 || response?.action === 2) {
          updated_cart_wish_list = set_productID_inCartWishList(
            typeOfCart,
            item.design_inventory,
            item[typeOfID],
            "add",
            inCartWishList
          );

          if (typeOfCart === "cart") {
            set_ATC_button_label(updated_cart_wish_list)
          }


        } else if (!response?.action || response?.action === 3) {
        
          updated_cart_wish_list = set_productID_inCartWishList(
            typeOfCart,
            item.design_inventory,
            item[typeOfID],
            "remove",
            inCartWishList
          );
        
          if (typeOfCart === "cart") {
            setItemIsInCart(false);
            setCount(1);
            set_ATC_button_label(updated_cart_wish_list)
          }
        }
        
        
        dispatch(set_inCartWishList(updated_cart_wish_list));
      }
    }
  };

  //Return ethier "design_master_id" || "inventory_master_id"
  const Get_Product_ID_Property_Name = () => {
    return item?.design_inventory === "design_master"
      ? "design_master_id"
      : item?.design_inventory === "inventory_master"
      ? "inventory_master_id"
      : "";
  };

  const checkQty = () => {
    if (count === "" || count === undefined) {
      setCount(parseInt(minQty));
    }
  };


  useEffect(() => {
    //set_ATC_button_label();
    const productID = Get_Product_ID_Property_Name();

    if (!inCartWishList || Object.keys(inCartWishList).length === 0) {
      setItemIsInCart(false);
    } else {
      const inCartWishList_property_name =
        productID === "inventory_master_id" ? "in_cart_id_im" : "in_cart_id_dm";
      if (
        inCartWishList[inCartWishList_property_name].includes(item[productID])
      ) {
        setItemIsInCart(true);
      } else {
        setItemIsInCart(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  return (
    <div
      className={`ec-product-sbtn mt-auto mb-auto d-flex aling-items-center w-100 flex-column single-matrix-product ${show_hover_shadow_effect_on_home_product_card}`}
      key={index}
    >
      <span className="ec-pro-actions px-3 w-100 d-flex justify-content-center">
        <div
          className={`cart-top-container d-flex w-100 align-items-center actions-ico`}
        >
          {item.design_inventory === "design_master" ? (
            <>
              {show_pinned ? (
                <div
                  className="card-top-label"
                  style={{
                    backgroundColor: pinned_badge_bg_color,
                    color: pinned_badge_fg_color,
                  }}
                >
                  {pinned_matrix_string}
                </div>
              ) : show_hide_design_master_new_arrival === "show" &&
                design_master_new_arrival ? (
                <div
                  className="card-top-label"
                  style={{
                    backgroundColor: new_arrival_badge_bg_color,
                    color: new_arrival_badge_fg_color,
                  }}
                >
                  {getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.new_arrivals) ||
                    "New Arrivals"}
                </div>
              ) : null}

              <div
                className={`cart-wishlist-icon-container d-flex gap-2 ${
                  show_pinned || show_hide_design_master_new_arrival === "show"
                    ? ""
                    : "w-100"
                } ${
                  matrix_cart_button_position === "topright" &&
                  matrix_shortlist_button_position === "topright"
                    ? "justify-content-end"
                    : matrix_cart_button_position === "topleft" &&
                      matrix_shortlist_button_position === "topleft"
                    ? "justify-content-start"
                    : (matrix_cart_button_position === "topleft" &&
                        matrix_shortlist_button_position === "topright") ||
                      (matrix_cart_button_position === "topright" &&
                        matrix_shortlist_button_position === "topleft")
                    ? "justify-content-between"
                    : ""
                }`}
              >
                {enable_shortlist && (
                  <ShortListIcon
                    inCartWishList={inCartWishList}
                    handle_cart_and_wishlist={handle_cart_and_wishlist}
                    item={item}
                    Get_Product_ID_Property_Name={Get_Product_ID_Property_Name}
                    show_named_wishlist={show_named_wishlist}
                    searchParams={searchParams}
                    matrix_data_count={matrix_data_count}
                  />
                )}
                <CartIcon inCartWishList={inCartWishList} item={item} />
              </div>
            </>
          ) : (
            ""
          )}
          {item.design_inventory === "inventory_master" ? (
            <div
              className={`cart-wishlist-icon-container d-flex gap-2 ${
                show_pinned || show_hide_design_master_new_arrival === "show"
                  ? ""
                  : "w-100"
              } ${
                matrix_cart_button_position === "topright" &&
                matrix_shortlist_button_position === "topright"
                  ? "justify-content-end"
                  : matrix_cart_button_position === "topleft" &&
                    matrix_shortlist_button_position === "topleft"
                  ? "justify-content-start"
                  : (matrix_cart_button_position === "topleft" &&
                      matrix_shortlist_button_position === "topright") ||
                    (matrix_cart_button_position === "topright" &&
                      matrix_shortlist_button_position === "topleft")
                  ? "justify-content-between"
                  : ""
              }`}
            >
              {enable_shortlist && (
                <ShortListIcon
                  inCartWishList={inCartWishList}
                  handle_cart_and_wishlist={handle_cart_and_wishlist}
                  item={item}
                  Get_Product_ID_Property_Name={Get_Product_ID_Property_Name}
                  show_named_wishlist={show_named_wishlist}
                  searchParams={searchParams}
                  matrix_data_count={matrix_data_count}
                />
              )}
              <CartIcon inCartWishList={inCartWishList} item={item} />
            </div>
          ) : (
            ""
          )}
        </div>
      </span>
      <div
        className="ec-product-image"
        style={{ backgroundImage: show_image_bg ? `url(${image})` : 'none' }}
      >
        {product_status_details && <Tag {...product_status_details} />}
        {/* <a href="#" className="ec-image"> */}
        <Link
          to={pre_login_open_login_from_matrix ? "/login" : FSV_redirection_link}
          state={location}
          onClick={(e) => {
            //Open Full Image Modal to zoom image
            if (open_fullscreen_or_zoomview === "matrix_to_zoom") {
              e.preventDefault();
            } else {
              //Redirect to FSV

              saveStateToLocalStorage(index);
            }
          }}
          autoFocus={false}
        >
          {open_fullscreen_or_zoomview === "matrix_to_zoom" ? (
            <FullScreenImageSlider
              imagesArr={[image.replace("large", "zoom")]}
              showThumbnailPreview={false}
            >
              <img className="pic-1" src={image} alt={centerLabel ? centerLabel : "Product"} />
            </FullScreenImageSlider>
          ) : (
            <img className="pic-1" src={image} alt={centerLabel ? centerLabel : "Product"} />
          )}
        </Link>
      </div>
      {/* {label?.length > 0 && */}
      {!show_only_product_image_in_home_productbase_section ? (
        <div className={`ec-product-body mt-auto ${show_matrix_details_in_grid ? "grid-layout" : ""}`}>
          {centerLabel ? (
            <b className={`product-name`} style={{color:  centerLabel === "product_name" ? matrix_value_color : matrix_label_color}}>
              <span>{centerLabel}</span>
            </b>
          ) : null}

          {label &&
            label.length > 0 &&
            label.map((label_item, i) => {
              if (typeof label_item !== "string") return null;
             
              return (
                <div key={i}>
                  {label_item?.toLowerCase().includes("price") ? (
                    show_price_label &&
                    label_visible[i] !== "0" && (
                     Array.isArray(item?.matrix_label_bold) && item?.matrix_label_bold[0] === "1" ? 
                      <b className="matrix-card-label">
                        <span>{label_item}</span> {label_item ? ':' : ''} 
                      </b> : <span className="matrix-card-label">
                        <span>{label_item}</span> {label_item ? ':' : ''} 
                      </span>
                    )
                  ) : label_visible[i] !== "0" ? (
                    Array.isArray(item?.matrix_label_bold) && item?.matrix_label_bold[0] === "1" ? 
                      <b className="matrix-card-label">
                        <span>{label_item}</span> {label_item ? ':' : ''} 
                      </b> : <span className="matrix-card-label">
                        <span>{label_item}</span> {label_item ? ':' : ''} 
                      </span>
                  ) : null}
                  <span
                    className={
                      `matrix-card-value ${label_item?.toLowerCase().includes("price") && !show_price_label
                        ? "custom-price-style"
                        : ""}`
                    }
                  >
                    {" "}
                    {
                      Array.isArray(item?.matrix_value_bold) && item?.matrix_value_bold[0] === "1" ? (
                        <b>
                    {label_item?.toLowerCase().includes("price")
                      ? formatPrice(values[i], true, selected_currency)
                      : values[i]}
                     </b> 
                      ) : (
                        <span>
                    {label_item?.toLowerCase().includes("price")
                      ? formatPrice(values[i], true, selected_currency)
                      : values[i]}
                     </span> 
                      )
                    }
                    
                  </span>
                </div>
              );
            })}
          <div className="stepper">
            {showStepper !== "No" && (
              <div className="qty-plus-minus">
                <div className="dec ec_qtybtn" onClick={Decrement}>
                  -
                </div>

                <NumericFormat
                  className="qty-input"
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    if (maxQty === 0) {
                      setCount(floatValue);
                    } else if (
                      !isNaN(floatValue) &&
                      floatValue >= minQty &&
                      floatValue <= maxQty
                    ) {
                      setCount(floatValue);
                    } else {
                      if (floatValue > maxQty) {
                        setCount(maxQty);
                        ToastError(`Max ${maxQty} Qty Reached.`);
                      }
                    }
                  }}
                  value={count}
                  onBlur={checkQty}
                  isAllowed={(inputObj) => {
                    const { value } = inputObj;
                    if (value === "") {
                      return true;
                    }
                    const numericValue = parseFloat(value);
                    if (numericValue === 0 && !itemIsInCart) {
                      return false;
                    }
                    return maxQty === 0
                      ? true
                      : numericValue > maxQty
                      ? false
                      : true;
                  }}
                  allowNegative={false}
                  prefix=""
                  placeholder="qty"
                />

                <div className="inc ec_qtybtn" onClick={Increment}>
                  +
                </div>
              </div>
            )}
          </div>

          {showAddToCart !== "No" && show_atc_matrix && (
            // <div className="ec-product-button-group">
            //   {/* <a className="ec-product-like-icon" href="#"><i className="fi-rr-heart"></i></a> */}
            //   {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            //   <a
            //     className={`ec-add-to-cart mt-2 ${ATC_BUTTON_CLASS}`}
            //     id="add-to-cart"
            //     onClick={handle_add_to_cart_button}
            //   >
            //     {addToCartloading ? (
            //       <BeatLoader color={"rgba(255,255,255,0.2)"} />
            //     ) : (
            //       buttonLabel
            //     )}
            //   </a>
            //   {/* <a className="ec-product-compare-icon" href="#"><i className="fi fi-rr-arrows-repeat"></i></a> */}
            // </div>
            <ATCButton
              handle_add_to_cart_button={handle_add_to_cart_button}
              addToCartloading={addToCartloading}
              ATC_BUTTON_CLASS={ATC_BUTTON_CLASS}
              buttonLabel={buttonLabel}
              page={index}
              sort={sort}
              set_ATC_button_label={set_ATC_button_label}
              inCartWishList={inCartWishList}
              item={item}
              showStepper={showStepper === "Yes"}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(ProductNew);
