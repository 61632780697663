/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Button from "@mui/material/Button";
import "./CustomizedMenus.css";

import { IoIosArrowDown } from "react-icons/io";
import CustomizedMenuList from "./CustomizedMenuList";
import { useLocation } from "react-router-dom";
import { is_url_has_form_input_keys } from "../../../FilterSideBarOptions/FilterHelper";
import { useDispatch } from "react-redux";
import { setInlineAppliedFilter } from "../../../../../redux/Reducer/reducer";
import useDefaultSelectedMasterStatus from "../../../../../Hooks/useDefaultSelectedMasterStatus";
// import "./CustomizedMenus.css";

export default function CustomizedMenus({ option, optionData, allOptionData }) {
  const { custom_label } = option;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const dispatch = useDispatch()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const default_selected_status_master = useDefaultSelectedMasterStatus()

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useLayoutEffect(() => {
    if(!is_url_has_form_input_keys(searchParams)){
      dispatch(setInlineAppliedFilter({type: "remove_all"}))
    }
  }, [searchParams?.cat_id])

  
  React.useEffect(() => {
    //set default filter capsule
    
    
    const field = option?.field;
 
    if(Array.isArray(optionData) && optionData.length > 1){
     const values = optionData.filter(option => option?.selected === "1").map(option => option?.id)
     if(field === "design_status" || field === "inventory_status"){
      const default_design_status = optionData
      .filter((option) => default_selected_status_master?.includes(option?.id))
      .map((option) => option?.id);
      dispatch(setInlineAppliedFilter({type: "apply", payload: {[field]: default_design_status}}))
     }
     else if(values?.length > 0){
      dispatch(setInlineAppliedFilter({type: "apply", payload: {[field]: values}}))
    }else{
      optionData.forEach(option => {
        if(option.selected === "1"){

          dispatch(setInlineAppliedFilter({type: "apply", payload: {[field]: option?.id}}))
        }

      })

      }
    }
    

    
  }, [searchParams?.cat_id])

  return (
    <div className="customized-menu-option">
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<IoIosArrowDown className="down-arrow"/>}
        className="menu"
      >
        {custom_label}
      </Button>

      <CustomizedMenuList
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        option={option}
        optionData={optionData}
        allOptionData={allOptionData}
      />
    </div>
  );
}
