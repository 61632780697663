import VideoCallingBanner from './video-calling-banner';
import "../video-calling-container.css";
import VideoCallingTabs from './video-calling-tabs/video-calling-tabs';
import Join_Video_Call_Btn from './video-calling-tabs/video-calling-buttons/join-call-btn';

export default function VideoCallingContainer() {
  return (
    <section className='video-calling-container'>
      <Join_Video_Call_Btn />
      <VideoCallingBanner />

      <section className='video-calling-section'>
        <VideoCallingTabs />    
      </section>
    </section>
  )
}
