import { useDispatch, useSelector } from "react-redux";
import {
  get_fsv_products_calc,
  get_fsv_quantity,
  get_fsv_sets_quantity,
  get_is_myc_web,
  getCatMaster,
  set_FSV_Handler,
} from "../../../../../../../redux/Reducer/reducer";
import {
  calculate_prod,
  FSV_ACTIONS,
  FSV_data_url,
  FSV_handle_recall_api_on_change,
  handle_fsv_button_value,
  recall_api_on_change,
} from "../../../../../Helper";
import { useLocation, useNavigate } from "react-router-dom";
import { get_search_params } from "../../../../../../../utils";
import { useEffect, useMemo } from "react";
import debounce from "debounce";

function ButtonFlow({
  short_code,
  selectedProduct,
  item,
  register,
  getValues,
  setValue,
  selectType,
}) {
  const product_calc = useSelector(get_fsv_products_calc);
  const fsv_count = useSelector(get_fsv_quantity);
  const fsv_sets_count = useSelector(get_fsv_sets_quantity);
  const is_myc_web = useSelector(get_is_myc_web);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const cartData = location.state?.cartData;

  const searchParams = get_search_params();
  const button_type = selectType === "button" ? "no-flow" : "";

  const mode = searchParams?.mode
    ? searchParams?.mode
    : is_myc_web
    ? "my_catalogue"
    : "filter";
  // if (!selected_prod_calc) return null;
  const master = useSelector(getCatMaster);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recall_api = useMemo(
    () =>
      recall_api_on_change(selectedProduct, short_code, master, searchParams),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [short_code]
  );

  const show_custom_input =
    selectedProduct[
      `show_custom_input_for_${short_code.replace("selected_", "")}`
    ];
  const custom_input_placeholder =
    selectedProduct[
      `custom_input_placeholder_for_${short_code.replace("selected_", "")}`
    ];

  function handle_custom_input(e) {
    if (recall_api && e.target.value) {
      setValue(short_code, "");
      const FSV_recall_params = FSV_handle_recall_api_on_change(
        getValues(),
        e.target.value,
        short_code
      );

      navigate(FSV_data_url({ ...FSV_recall_params, mode }));
    } else if (e.target.value) {
      calculate_prod(
        selectedProduct,
        product_calc,
        short_code,
        e.target.value,
        fsv_count,
        dispatch,
        fsv_sets_count,
        
      );
    }

    // else if (e.target.value) {
    //   setValue(short_code, "");
    
    //   calculate_prod(
    //     selectedProduct,
    //     product_calc,
    //     short_code,
    //     e.target.value,
    //     fsv_count,
    //     dispatch
    //   );
    // } else {
    //   calculate_prod(
    //     selectedProduct,
    //     product_calc,
    //     short_code,
    //     "0",
    //     fsv_count,
    //     dispatch
    //   );
    // }
  }

  const catalogue = (function () {
    switch (item.short_code) {
      case "selection_diamond_catalogue":
        return !!selectedProduct[item.short_code] &&
          !Array.isArray(selectedProduct[item.short_code]) &&
          typeof selectedProduct[item.short_code] === "object"
          ? Object.keys(selectedProduct[item.short_code])
          : [];
      default:
        return Array.isArray(selectedProduct[item.short_code]) &&
          selectedProduct[item.short_code].length > 0
          ? selectedProduct[item.short_code]
          : [];
    }
  })();

  useEffect(() => {
    if (!cartData) return;

    //set custom input value with cartdata
    if(cartData[short_code]){
      if(!catalogue.includes(cartData[short_code]) && show_custom_input){
        setValue(`custom_${short_code}`, cartData[short_code])

        const custom_input = document.getElementById(
          `${short_code}_custom_input_id`
        );
        if (custom_input) {
          custom_input.classList.add(
            "custom-input-btn-mode-enable"
          );
        }
      }
    }

    //Run Calcuation with size which affect on GrossWt
    if (short_code === "size" && cartData["size"]) {
    setTimeout(() => {
        calculate_prod(
          selectedProduct,
          product_calc,
          "size",
          cartData["size"],
          fsv_count,
          dispatch,
          fsv_sets_count,
          cartData
        );
      }, 500);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData]);
  
  return (
    <div
      className={`ec-pro-variation-content col-sm-7 buttonflow ${button_type}`}
    >
      <ul>
        {catalogue.map((value, i) => {
          let button_value = handle_fsv_button_value(short_code, value);
          button_value =
            button_value.constructor === Object
              ? button_value?.label === ""
                ? { ...button_value, label: " " }
                : button_value
              : button_value;
         

          return (
            <li key={i}>
              <input
                type="radio"
                id={short_code + (button_value?.value || button_value)}
                {...register(short_code)}
                value={button_value?.value || button_value}
                style={{ display: "none" }}
              />
              <label
                htmlFor={short_code + (button_value?.value || button_value)}
                className="buttonflow-btn"
                onClick={() => {
                  if (show_custom_input === "1") {
                    setValue(`custom_${short_code}`, "");
                    const custom_input = document.getElementById(
                      `${short_code}_custom_input_id`
                    );
                    if (custom_input) {
                      custom_input.classList.remove(
                        "custom-input-btn-mode-enable"
                      );
                    }
                  }

                  if (short_code === "selection_variant_catalogue") {
                    navigate(
                      FSV_data_url({
                        product_id: button_value?.value || button_value,
                        mode,
                      })
                    );
                  } else if (recall_api) {
                    if (
                      show_custom_input === "1" &&
                      getValues(`custom_${short_code}`)
                    ) {
                      setValue(`custom_${short_code}`, "");
                    }

                    const FSV_recall_params = FSV_handle_recall_api_on_change(
                      getValues(),
                      button_value?.value || button_value,
                      short_code
                    );

                    navigate(FSV_data_url({ ...FSV_recall_params, mode }));
                  } else if (
                    short_code === "cc_hook" ||
                    short_code === "tone"
                  ) {
                    dispatch(
                      set_FSV_Handler({
                        type: FSV_ACTIONS.SET_PRODUCT_CALC,
                        payload: {
                          product_name: selectedProduct?.category_name,
                          data: {
                            [short_code]: button_value?.value || button_value,
                          },
                        },
                      })
                    );
                  } else {
                    if (
                      show_custom_input === "1" &&
                      getValues(`custom_${short_code}`)
                    ) {
                      setValue(`custom_${short_code}`, "");
                    }
                    
                    calculate_prod(
                      selectedProduct,
                      product_calc,
                      short_code,
                      button_value?.value || button_value,
                      fsv_count,
                      dispatch,
                      fsv_sets_count
                    );
                  }
                }}
              >
                <span id="btn-span" className="buttonflow-btn-inner btn-span">
                  {button_value?.label || button_value}
                </span>
              </label>
            </li>
          );
        })}
        {show_custom_input === "1" && (
          <li>
            <input
              placeholder={custom_input_placeholder}
              type="number"
              className="custom-fsv-input"
              id={`${short_code}_custom_input_id`}
              {...register(`custom_${short_code}`)}
              onChange={debounce(handle_custom_input, 500)}
              onBlur={(e) => {
                if (e.target.value) {
                  e.target.classList.add("custom-input-btn-mode-enable");
                  setValue(short_code, "");
                }
              }}
              onClick={(e) => {
                e.target.classList.remove("custom-input-btn-mode-enable");
              }}
            />
          </li>
        )}
      </ul>
    </div>
  );
}

export default ButtonFlow;
