import React, { useLayoutEffect, useMemo } from 'react'
import { get_informationFromSessionID, get_property_settings } from '../../Component/Helper'
import { useQuery } from '@tanstack/react-query';
import { fetch_data_from_api_from_query } from '../../Api/APICalls';
import { ApiList } from '../../Api/ApiList';
import { useSelector } from 'react-redux';
import { get_is_myc_web, getCatMaster } from '../../redux/Reducer/reducer';
import { append_all_product_menu_in_categories, ScrollToTopCustom } from '../../utils';
import ButtonImageGroup from '../../Component/RecursiveCategory/ButtonImage/ButtonImageGroup';
import { useLocation } from 'react-router-dom';
import "./Categories.css";
import TitleHeader from '../../Component/TitleHeader/TitleHeader';
import { getCustomValue } from '../../Custom/CompanyList';
import { CUSTOMIZIED_COMPANY_PROPERTY } from '../../Custom/CompanyCustomization';

export default function CategoriesPage() {
    const web_parent_category_from_for_navigation_bar_text = get_property_settings("web_parent_category_from_for_navigation_bar_text")
    const web_parent_category_from_for_navigation_bar_text_url_string = web_parent_category_from_for_navigation_bar_text.toLowerCase().replace(/\s/g,"_")
    const master = useSelector(getCatMaster);
    const userID = get_informationFromSessionID("userID");
    const is_myc_web = useSelector(get_is_myc_web);

    const location = useLocation();
    const searchParams = Object.fromEntries(new URLSearchParams(location.search));
    const cat_id = searchParams.cat_id;
    const is_our_collection = searchParams?.cat_id === web_parent_category_from_for_navigation_bar_text_url_string
    

    const hide_product_count = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.hide_product_count)
  const show_hide_category_counts =
    get_property_settings("show_hide_category_counts") === "show" && !hide_product_count;
    

    const category_query = useQuery({
        queryKey: ["category", master, userID],
        queryFn: () =>
          fetch_data_from_api_from_query(ApiList.fetchCat, "FetchCat", {
            which_master: master,
          }),
        initialData: [],
        enabled: !is_myc_web 
      });

      const findID = (categoryData, cat_id, nestingKey) => {
          if(!Array.isArray(categoryData) || is_our_collection) return null;
          // eslint-disable-next-line array-callback-return
          return categoryData?.reduce((result, currentItem) => {
            if (result) return result;
            if (currentItem.id === cat_id) return currentItem;
            if (currentItem[nestingKey])
              return findID(currentItem[nestingKey], cat_id, nestingKey);
          }, null);
        }
          
      
        const category_list = useMemo(() => append_all_product_menu_in_categories(category_query?.data), [category_query?.data])
        const subCategories = useMemo(() => findID(category_list, cat_id, "subcategory"), [cat_id, category_list])
        

        // if(category_query.isLoading || !subCategories) return null

        const productName = is_our_collection ? web_parent_category_from_for_navigation_bar_text : subCategories?.cat_name
        const productCount = show_hide_category_counts && !is_our_collection && subCategories?.product_count !== null ? subCategories?.product_count : "";
        const title = `${productName} ${productCount ? ` (${productCount})`: ""}`

        useLayoutEffect(() => {
            ScrollToTopCustom()
        },[])

  return (
    <div className='categories-page-container'>
    <TitleHeader title={title}/>
      <section className='categories-page__section'>
            <ButtonImageGroup
                subCategories={is_our_collection ? category_list : subCategories?.subcategory}
                setShowCategoryModal={() => {}}
                layoutStyle={subCategories?.display_style_for_imagebutton}
            />
      </section>  
    </div>
  )
}
