export const shankesh_testimonial_data = [
    {
        id: 1,
        client_name: "AVR SWARNA MAHAL JEWELRY",
        directors: ["Mr. A.B. Sudarsanam",
            "Mr. A.B.S. Sanjay",
            "Mrs. Suganthi Sudarsanam",
            "Mrs. Soumya SanjjayS"],
        info: ['"Shankesh Jewellers are very customer friendly in all aspects, especially to jewelry orders. Even the smallest ones are executed in detail at the earliest. Excellent designs, elegant craftsmanship is what defines as the hallmarks of Shankesh Jewellers."'],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/AVR-SWARNA-MAHAL-JEWELRY.png"

    },


    {
        id: 2,
        client_name: "B.B. JEWELLERS PVT. LTD.",
        directors: ["Smt. Sarala Devi Bethala",
            "Shri. Amith Bethala"],
        info: ['"I have been purchasing from Shankesh Jewellers and would highly recommend this company. The jewellery is stunning and the service is unbeatable. If there is ever a problem it is rectified quickly without any hassle at all."'],
        company_logo: "",

    },

    {
        id: 3,
        client_name: "BHIMA DIAMONDS GOLD SILVER PLATINUM",
        directors: ["Shri. B.Krishnan Bhat"],
        info: ['"Shankesh Jewellers has been a household name for all gold shopping and it stands by that name. Many options, patterns and designs to shop and choose from. The staff are very patient and friendly. The lighting and displays are well thought out. They offer returns, exchange and repair services."'],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/BHIMA-DIAMONDS-GOLD-SILVER-PLATINUM.png",

    },

    {
        id: 4,
        client_name: "BHIMA DIAMONDS GOLD SILVER PLATINUM",
        directors: ["Mr Govindan"],
        info: ['"In short, fantastic collections, fabulous service and a family atmosphere created by the brothers which leaves no one to not to do business with Shankesh Jewellers."'],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/BHIMA-DIAMONDS-GOLD-SILVER-PLATINUM.png",

    },

    {
        id: 5,
        client_name: "BHIMA DIAMONDS GOLD SILVER PLATINUM",
        directors: ["Mr. B.Bindumadhav", "Mr. Abhishek Bindumadhav"],
        info: null,
        list_info: [
            "Product are very innovative",
            "Flexible in adapting our ideas into product designing and deliver the product accordingly",
            "Wide range to choose from among all category",
            "Prompt delivery even at short notice at times",
            "Highlight products for Bhima: Fancy rhodium products and Kolkata rhodium bangles, exclusive items, layer chains"
        ], 
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/BHIMA-DIAMONDS-GOLD-SILVER-PLATINUM.png",

    },

    {
        id: 6,
        client_name: "BHOLASONS JEWELLERS",
        directors: ["Dr. Rajendra Bhola"],
        info: ['"Very Trustworthy. Have been buying from Shankesh Jewellers for years and always been happy with the quality and prices of the jewellery."'],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/BHOLASONS-JEWELLERS.jpg",

    },

    {
        id: 7,
        client_name: "D.HARRIS NADAR DIAMONDS & JEWELLERY",
        directors: ["Mr. S.K. Sopheen Harris", "Mr. S.K. Sopheen Harris"],
        info: ['"The striking feature of Shankesh Jewellers is the constant quest to innovate and set the trend. The vivid use of semi precious stones / beeds in their antique jewellery series and designer jewellery are a big hit with our customers. The Impeccable finishing of products makes lives easier for us.', 'Thank you and keep up the good work."'],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/D.HARRIS-NADAR-DIAMONDS-&-JEWELLERY.jpg",

    },

    {
        id: 8,
        client_name: "GRT JEWELLERS",
        directors: ["Shri. G.Rajendran",
            "Shri. G. R. 'Ananth' Ananthapadmanabhan",
            "Shri. G. R. Radhakrishnan"],
        info: ['"We have been dealing with Shankesh Jewellers for the last 10 years and have always found their customer service to be excellent. They are known for maintaining good quality and we have seen this happening over the years. In addition to maintaining good quality, they also try their best to bring out the latest designs which are in fashion. We wish the team at Shankesh Jewellers all the very best in their future endeavors."'],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/GRT-JEWELLERS.png",

    },

    {
        id: 9,
        client_name: "Joyalukkas World's favourite jeweller",
        directors: ["Mr. Joy Alukkas"],
        info: [`"I can't say enough good things about dealing with Shankesh Jewellers. It's always a great experience and the merchandise is of very good quality. I find the prices to be good too. And, I love the personal touch that the team gives. They truly know and cares about each of their customers. Keep it up!!!"`],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/Joyalukkas-World's-favourite-jeweller.png",

    },


    {
        id: 10,
        client_name: "KALAMANDIR JEWELLERS LIMITED",
        directors: ["Mr. Milan"],
        info: [`"Shankesh Jewellers are amazing! their customer service is outstanding and the jewellery are very unique.`, `We hope you will be in the jewellery business for many more years to come!"`],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/KALAMANDIR-JEWELLERS-LIMITED.jpg",

    },


    {
        id: 11,
        client_name: "KANDALAA HOUSE OF JEWELLERY",
        directors: ["Mr. Subbaraj Kandala",
            "Mr. Srikanth Kandala" ],
        info: [`"I am very delighted with Shankesh Jewellers. Highly professional. I have dealt with Shankesh Jewellers several times and will do so again. The jewellery is perfect in every detail. I would highly recommend them to all."`],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/KANDALAA-HOUSE-OF-JEWELLERY.jpg",

    },


    {
        id: 13,
        client_name: "Kanhaiya Golden Arts Center",
        directors: ["Shri. Gunjan Agrawal" ],
        info: [`"Shankesh Jewellers are one of the most renowned manufacturers of gold n antique jewellery with Kundan work in Mumbai. We Kanhaiya Swarn Kala Kendra from Varanasi have been dealing with them from last 25 years. They provide us with latest variety of jewellery at very reasonable price. Their services to us is really appreciable"`],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/Kanhaiya-Golden-Arts-Center.png",

    },

    {
        id: 14,
        client_name: "KOTHARI JEWELLERS",
        directors: ["Shri. Narendra Kothari",
            "Shri. Pradeep Kothari",
            "Shri. Nitin Kothari",
            "Shri. Chandra Kothari",
            "Shri. Lalit Kothari"],
        info: [`"Good people to work with, we are honored to be associated with Shankesh Jewellers."`],
        company_logo: "",

    },


    {
        id: 15,
        client_name: "KASHI JEWELLERS",
        directors: ["Mr. Rajan Kapoor",
            "Mr. Shreyansh Kapoor"],
        info: [`"For the last 30 years we are associated with Shankesh Jewellers. We have been together with them since inception and are very happy to see the milestone they have reached. The products are excellent and the services are very good as well. I would not hesitate to recommend Shankesh Jewellers to anyone."`],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/KASHI-JEWELLERS.png",

    },


    {
        id: 16,
        client_name: "KRA JEWELLERS",
        directors: ["Shri. Amol Ashtekar",
            "Shri. Atul Ashtekar",
            "Shri. Vipul Ashtekar"],
        info: [`"KRA Jewellers has been associated with Shankesh Jewellers for the past seven years. Our experience dealing with them has been phenomenal. They cater to all type of gold jewellery demanded by us, and are very prompt and transparent in their dealings. Mr. Mahaveer and Mr. Manoj always welcome their customers with a big smile. Personally involved in the business they always take a first-hand review from their customers and try to remain ahead of competition in terms of variety and designs. We thank Shankesh Jewellers for their support to us, and wish them a great future ahead."`],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/KRA-JEWELLERS.png",

    },

    {
        id: 17,
        client_name: "Motisons Jewellers",
        directors: ["Mr. Naval Agrawal"],
        info: [`"Shankesh Jewellers is always courteous and conscientious. They provides great value and quality jewellery to my businesses so we can compete in the retail world. They are my primary supplier for my stock and my customers love what I sell."`],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/Motisons-Jewellers.jpg",

    },


    {
        id: 18,
        client_name: "Nandkishore Meghraj Jewellers (P) Ltd",
        directors: ["Mr. Sushil"],
        info: [`"Shankesh Jewellers headed by young entrepreneur who are committed to their work in designing and quality work. Know them or rather having business relationship with them since 1987. Their designs change with trend and update us. My best wishes for them for future and their policy of 'together we progress."`],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/Nandkishore-Meghraj-Jewellers-(P)-Ltd.jpg",

    },

    {
        id: 19,
        client_name: "PNG JEWELLERS",
        directors: ["Mr. Saurabh Gadgil"],
        info: [`"Best Wishes."`],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/PNG-JEWELLERS.png",

    },

    {
        id: 20,
        client_name: "PNG",
        directors: ["M/s. Purushottam Narayan Gadgil Saraf & Jewellers",
            "Mr. Samir Gadgil"],
        info: [`"Great supplier of quality jewellery. Shankesh Jewellers is a pleasure to work with great prices and super fast delivery, I would recommend this company! They are honest and trustworthy."`],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/PNG.png",

    },

    {
        id: 21,
        client_name: "P. N. GADGIL & SONS",
        directors: ["Mr. Ajit Gadgil"],
        info: [`"I recently received my order of jewellery from Shankesh Jewellers. They were wonderful and I sold them very soon. Very good at the price points, quality and variety. Exceptional company to work with. I am sure of a long-term relationship with them."`],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/P.-N.-GADGIL-&-SONS.png",

    },

    {
        id: 22,
        client_name: "PUNJABI NERVE ETERNAL JEWELLERY",
        directors: ["Mr. Ajay Anand",
            "Mr. Sumeet Anand"],
        info: [`"We have always felt Sankesh Jewellers as a part of Punjabi Saraf, One of the most honest suppliers in entire India, we always appreciate your speedy response on almost everything required. We have always experienced very positive vibes in there products also along with there personal approach. We wish them a great success and growth as you deserve."`],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/PUNJABI-NERVE-ETERNAL-JEWELLERY.jpg",

    },


    {
        id: 23,
        client_name: "R.C",
        directors: ["Mr. R.C. Bafnaji",
            "Mr. Sushilkumarji Bafna"],
        info: [`"Shankesh Jewellers is great, very helpful, right on top when it comes to Customer Service. I Love theit new designs, fast shipping, you wont be disappointed"`],
        company_logo: "",

    },


    {
        id: 24,
        client_name: "SHAM ETHNIC WEAR & JEWELLERY",
        directors: ["Mr. Gagan Khurana"],
        info: [`"Best wholesaler Shankesh Jewellers 8 years since we started working together. Their customer service is outstanding and the jewellery are very unique."`],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/SHAM-ETHNIC-WEAR-&-JEWELLERY.jpg",

    },


    {
        id: 26,
        client_name: "SVTM JEWELS",
        directors: ["Shri. Ravi N"],
        info: [`"Shankesh, one of the best friendly homely vendor and also have best designs and very very nice approachment firm."`],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/SVTM-JEWELS.jpg",

    },


    {
        id: 27,
        client_name: "VAIBHAV JEWELLERS",
        directors: ["Smt. G.B.M Ratna Kumari"],
        info: [`"Every piece of jewellery from Shankesh Jewellers, is craftily designed with highest quality and a wide range of jewellery to choose from, many of which are unusual and set in unique settings. The service is great, speedy, helpful and friendly. In a nutshell, good people to deal with and beautiful stock. Wish them growth and success in the years to come."`],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/VAIBHAV-JEWELLERS.png",

    },

    {
        id: 28,
        client_name: "VUMMIDI BANGARU JEWELLERS",
        directors: ["Shri. Ragunath Vummidi",
            "Shri. Jithendra Vummidi",
            "Shri. Vummidi Amarendran"],
        info: [`"Shankesh Jewellers are known to us for over 10 years. They have always maintained high standards, we had checked the net gold weight of their jewellery and it was found accurate. This shows the integrity that they have in place. Wishing all the best for the future."`],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/VUMMIDI-BANGARU-JEWELLERS.jpg",

    },

    {
        id: 29,
        client_name: "Reliance Jewels",
        directors: [""],
        info: [``],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/Reliance-Jewels.png",

    },


    {
        id: 30,
        client_name: "Indriya",
        directors: [""],
        info: [``],
        company_logo: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/client_testimonial/Indriya.png",

    },



   
]


export const shankesh_clientele_data = [5,8,9,10,19,21,27, 29, 30].map(id => {
    const img = shankesh_testimonial_data.find(data => id === data.id)?.company_logo
    return img
})


export const shankesh_testimonial_filterd_data = [9,8,5,27,21].map(id => {
    const img = shankesh_testimonial_data.find(data => id === data.id)
    return img
  })