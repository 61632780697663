// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "./ButtonFlowSwiper.css"

const ButtonFlowSwiper = ({ className = "", data, ButtonComp }) => {
  return (
    <ul
      className={`status-button-group overflow-scroll example button-flow-swipter ${className}`}
     >
      <Swiper spaceBetween={10} slidesPerView={`auto`}>
        {data?.map((status) => (
          <SwiperSlide key={status?.id}>
            <ButtonComp {...status} />
          </SwiperSlide>
        ))}
      </Swiper>
    </ul>
  );


};

export default ButtonFlowSwiper;
