import React from 'react'
import {  useNavigate } from 'react-router-dom'
import { createSearchURLParams, get_property_settings } from '../../../Component/Helper'
import ButtonFlowSwiper from '../../../Component/ButtonFlowSwiper/ButtonFlowSwiper';
import ButtonFlowRowGrid from '../../../Component/ButtonFlowRowGrid/ButtonFlowGrid';
import { getCustomValue } from '../../../Custom/CompanyList';
import { CUSTOMIZIED_COMPANY_PROPERTY } from '../../../Custom/CompanyCustomization';


function CustomOrderStatus({status_group, set_active_status, active_status}) {
  const navigate = useNavigate() 
  const searchParams = Object.fromEntries(
    new URLSearchParams(document.location.search)
  );
  const show_custom_order_status_in_row_style = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_custom_order_status_in_row_style)
 

  function Button ({order_status, is_selected, id, order_count}) {
    
    return (
        <li className={`${active_status === id || (!active_status && is_selected === id) ? 'group-by-buttons-selected' : 'group-by-buttons-unselected'} text-nowrap ui button-ui`}
        onClick={() => {
          const kam_module_title = get_property_settings("kam_module_title")
          set_active_status(id);
          navigate(createSearchURLParams(kam_module_title.replace(/\s/g, "-").toLowerCase(), {...searchParams, status: id, page: "0", sort: searchParams?.sort || "0"}))
        }}
        ><span>{order_status}</span>
        {order_count !== "0" ?  <span className='order-count group-by-buttons-selected'>{order_count}</span> : null} 
        </li>
        
        
    )
  }

  return (
    <section className='custom-order-status'>
      <div className='status text-nowrap'>STATUS :</div>
      {
        show_custom_order_status_in_row_style && window?.innerWidth > 576 ? <ButtonFlowRowGrid data={status_group} ButtonComp={Button}/> : <ButtonFlowSwiper data={status_group} ButtonComp={Button}/>
      }
      
     
    </section>
  )
}

export default CustomOrderStatus
