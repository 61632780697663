import { useEffect, useRef, useState } from 'react';
import { get_bulk_settings, get_informationFromSessionID } from '../../Component/Helper';
import { ApiList } from '../../Api/ApiList';
import { useLocation } from "react-router-dom"
import "./StaticPage.css";
import { fetch_data_from_api } from '../../Api/APICalls';
import Loader from "../../Component/Loader"
import NoProduct from '../../Component/NoProduct/NoProduct';
import { getCustomValue } from '../../Custom/CompanyList';
import { CUSTOMIZIED_COMPANY_PROPERTY } from '../../Custom/CompanyCustomization';
import CustomStaticPage from './CustomStaticPage/CustomStaticPage';
import { groverchain_privacy_page } from './CustomStaticPage/GROVERCHAIN/privacy_policy';

function StaticPage() {
    const location = useLocation()
    const searchParams = Object.fromEntries(
        new URLSearchParams(location.search)
    )
    const [iframeHeight, setIframeHeight] = useState("auto")

    const iframeRef = useRef(null)

    const companyCode = get_informationFromSessionID("companyCode");
    const [data, setData] = useState(null)
    const [dummyData, setDummyData] = useState(null)
    const custom_menu = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.custom_menu)
    const is_custom_page = custom_menu ? custom_menu.find(menu => location.pathname.includes(menu?.link)) : false
    
    const {privacy_policy, terms_and_conditions} = get_bulk_settings(["privacy_policy", "terms_and_conditions"])
    const company_code = get_informationFromSessionID("companyCode")

    let {terms, privacy} = searchParams || {}
    terms = terms === "1"
    privacy = privacy === "1"

   
    const [loading, setLoading] = useState(false)
    const fetchHTMLData = async () => {
        setLoading(true)
        let staticPageResponse = ""
        if(terms){
            staticPageResponse = await fetch(`${terms_and_conditions}?company_code=${company_code}`).then(res => res.text())  
           
            
        }else if(privacy){
            staticPageResponse = await fetch(`${privacy_policy}?company_code=${company_code}`).then(res => res.text())
            // staticPageResponse = !!staticPageResponse ? staticPageResponse : atob(company_code) === 'GROVERCHAIN' ? groverchain_privacy_page : staticPageResponse
        }else{

            staticPageResponse = await fetch_data_from_api(ApiList.staticpage, 'StaticPage', { company_code: companyCode, page: searchParams.id })
        }
       
        setData(staticPageResponse)
        let d1 = staticPageResponse.replace(/body.+{/g, '.body{').replace(/.body{/g, '.body{')
        d1 = d1.replace(/<body/g, `<body class="body"`)

        
        setDummyData(d1)
        setLoading(false)

    }
    
    

    useEffect(() => {
        
        fetchHTMLData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams.id, terms, privacy])


    function iframeDynamicHeight () {
        const iframeContentHeight = document.getElementById("iframeStatic")?.contentWindow?.document?.body?.offsetHeight
            const offsetHeight = 30
            if(!iframeContentHeight) return
           
            setIframeHeight(`${iframeContentHeight + offsetHeight}px`)
    }
    

    // useEffect(() => {
        
        
    //             iframeDynamicHeight ()

    
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [document.getElementById("iframeStatic")?.contentWindow?.document?.body?.offsetHeight, searchParams])

    
    // useEffect(() => {
    //     if(!dummyData) return

    //     if (iframeRef.current && dummyData) {
    //         const iframe = iframeRef.current;
    //         const doc = iframe.contentDocument || iframe.contentWindow.document;
    //         doc.open();
    //         doc.write(dummyData);
    //         doc.close();
      
    //         setTimeout(() => {
    //           iframe.style.height = (doc.body.scrollHeight + 100) + "px";
    //         }, 500); // Small delay to ensure content loads
    //       }
    // }, [dummyData])
    
    const NoData = typeof data === "string" && data?.includes("No Data Available")

    
    
    return (
        <section className='static-page'>
            {
                loading ? <Loader isLoading={loading} /> : !!NoData && !!is_custom_page ? <CustomStaticPage /> : NoData ? <NoProduct errMsg={"No Data Available"} hideHomeBtn={true}/> : (
                    <article dangerouslySetInnerHTML={{ __html: dummyData }}>
                        {/* <div className="dummy-static-page-container" dangerouslySetInnerHTML={{ __html: dummyData }}></div> */}
                        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                        {/* <iframe srcDoc={data} frameBorder="0" width='100%' style={{ overflow: 'hidden', height: iframeHeight }} ref={iframeRef} id="iframeStatic" onLoad={() => {
                         }}></iframe> */}
                    </article>
                )
            }

        </section>
    )
}

export default StaticPage
