import React, { useEffect } from 'react'
import { getCustomValue } from '../../Custom/CompanyList'
import { CUSTOMIZIED_COMPANY_PROPERTY } from '../../Custom/CompanyCustomization'
import NoProduct from '../../Component/NoProduct/NoProduct'
import VideoCallingContainer from './components/video-calling-container'
import { ScrollToTopCustom } from '../../utils'

export default function VideoCallingPage() {

  const show_video_calling = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_video_calling)

  useEffect(() => {
    ScrollToTopCustom()
  }, [])

  if(!show_video_calling) return <NoProduct errMsg="No Page Found" />

  return (
    <div>
      <VideoCallingContainer />
    </div>
  )
}
