import { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./CustomNewOrder.css";
import MultiCustomOrderForm from "./Components/MultiCustomOrderForm";
import CustomNewOrderFooter from "./Components/CustomNewOrderFooter";
import {
  get_bulk_settings,
  get_informationFromSessionID,
  get_property_settings,
  get_userData_from_activeUser,
  show_custom_order_menu,
  ToastError,
} from "../../Component/Helper";
import {
  fetch_data_from_api,
  fetch_data_from_api_from_query,
} from "../../Api/APICalls";
import { ApiList } from "../../Api/ApiList";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Loader from "../../Component/Loader";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import CustomOrderGeneral from "./Components/CustomOrderGeneral";
import CustomOrderModal from "../CustomOrder/CustomOrderModal";
import CustomNewOrderCard from "./Components/CustomNewOrderCardWrapper";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  get_custom_order,
  get_custom_order_design_by_image_arr,
  set_custom_order,
} from "../../redux/Reducer/reducer";
import CustomOrderContext from "../CustomOrder/Context/CustomOrderContext";
import aws_image_upload from "../../aws/aws_image_upload";
import {
  get_plain_values_from_custom_order,
  GetBlobFormImageURL,
  getKamImageUploadPath,
  getKamOrderParams,
} from "../CustomOrder/custom_order_utils";
import OrderPlaceModal from "../../Component/OrderPlaceModal/OrderPlaceModal";
import AddItemsButtons from "./Components/AddItemsButtons";

function CustomNewOrder() {
  const title = show_custom_order_menu().label;
  const userID = get_informationFromSessionID("userID");
  const userData = get_userData_from_activeUser();
  const [showStepModal, setShowStepModal] = useState(false);
  const [add_items_by, set_add_items_by] = useState("by image");
  const [showPlacedModal, setShowPlacedModal] = useState({
    show: false,
    response: {
      title: "Thanks for placing your order using KAM",
      subtitle: "We hope you had a great overall experience using this app",
    },
  });
  const custom_order_data = useSelector(get_custom_order);
  const methods = useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const { update_id } = Object.fromEntries(
    new URLSearchParams(location.search)
  );
  const FooterRef = useRef();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [placingOrder, setPlacingOrder] = useState(false);
  let design_by_images = useSelector(get_custom_order_design_by_image_arr);

  const oms_add_multiple_item_app = get_property_settings(
    "oms_add_multiple_item_app"
  );

  let allow_more_than_one_item_in_order_oms = get_property_settings(
    "allow_more_than_one_item_in_order_oms"
  );

  allow_more_than_one_item_in_order_oms =
    allow_more_than_one_item_in_order_oms === "Yes";

  //This function is for multiple custom order only. for single custom order onSubmit function in CustomOrderModal.jsx
  const place_order = async () => {
    const is_vaild = await methods.trigger();
    if (!is_vaild) return;
    if (custom_order_data.items.length === 0) {
      return ToastError("Please add at least one item");
    }

    const data = methods.getValues();

    const imageFile = FooterRef.current.imageFile;

    setPlacingOrder(true);

    //Set Parameters ==================================================>
    const get_plain_data = get_plain_values_from_custom_order(data);

    const formdata = { ...custom_order_data, ...get_plain_data };
    const params = getKamOrderParams(formdata, imageFile, design_by_images);
    
    // return

    //Upload images to S3 bucket ==================================================>
    //s3 confing variables
    const bucket_path = `${getKamImageUploadPath()}/uploads/kam_order_images`;
    const {
      jewelkam_s3Key,
      jewelkam_s3Pass,
      oms_add_multiple_item_app,
      append_kam_path_folder,
      kam_module_title,
    } =
      get_bulk_settings([
        "jewelkam_s3Key",
        "jewelkam_s3Pass",
        "oms_add_multiple_item_app",
        "append_kam_path_folder",
        "kam_module_title",
      ]) || {};
    const credentials = {
      accesskeyID: jewelkam_s3Pass,
      secretAccessKey: jewelkam_s3Key,
    };
    //s3 upload image

    //Images Array from Browse File
    let imageArr = imageFile.flat();
    imageArr = imageArr.filter((value) => !!value);

    let design_by_image_arr = [];
    if (Array.isArray(design_by_images) && design_by_images.length > 0) {
      design_by_image_arr = await GetBlobFormImageURL(
        credentials,
        design_by_images
      );
      design_by_image_arr = await Promise.all(design_by_image_arr);
    }

    const s3_images_arr = [...design_by_image_arr, ...imageArr];

    if (s3_images_arr.length > 0) {
      const uploadPromises = s3_images_arr.map((image) =>
        aws_image_upload(image.file, bucket_path, credentials)
      );
      await Promise.all(uploadPromises);

      
    }

    let is_multi_order = oms_add_multiple_item_app === "Yes";
    const API_END_POINT = is_multi_order
      ? ApiList.jewelkam_multi_order_place
      : `${append_kam_path_folder}/order`;

    try {
      const custom_order_response = await fetch_data_from_api(
        API_END_POINT,
        "custom-order-place",
        params
      );
      
      if (custom_order_response?.ack === "1") {
        setShowPlacedModal({ show: true, response: custom_order_response });
        dispatch(set_custom_order({ type: "reset" }));
        FooterRef.current.setImageFile([]);
        setPlacingOrder(false);
        let oms_navigate_direct_create_new_customer_order = get_property_settings("oms_navigate_direct_create_new_customer_order")
        oms_navigate_direct_create_new_customer_order = oms_navigate_direct_create_new_customer_order === "Yes";
        queryClient.invalidateQueries({ queryKey: ["kam_order_list"] });
        setTimeout(() => {
          setShowPlacedModal({ show: false, response: null });
          if(oms_navigate_direct_create_new_customer_order){
            navigate("/home")
          }else{  

            navigate(`/${kam_module_title.replace(/\s/g, "-").toLowerCase()}`);
          }
        }, 5000);
      } else if (custom_order_response?.ack === "0") {
        ToastError(custom_order_response?.error || "something went wrong");
      } else if (typeof custom_order_response === "string") {
        ToastError("Some error accured");
      }
    } catch (e) {
      ToastError("something went wrong");
    } finally {
      setPlacingOrder(false);
    }
  };

  const jewelkam_multi_fs_response = useQuery({
    queryKey: ["jewelkam_multi_fs"],
    queryFn: () =>
      fetch_data_from_api_from_query(
        ApiList.jewelkam_multi_welcome_screen,
        "jewelkam_multi_fs",
        {
          client_type: userData?.client_type || "",
        }
      ),
    enabled: oms_add_multiple_item_app === "Yes",
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!update_id) return;
    setShowStepModal(true);
  }, [update_id]);

  useEffect(() => {
    if (showStepModal) return;
    document.body.style.overflow = "auto";
  }, [showStepModal]);

  if (oms_add_multiple_item_app === "No" || !userID) {
    return <Navigate to="/" />;
  }

  return (
    <>
      {showPlacedModal.show && (
        <OrderPlaceModal
          showPlacedModal={showPlacedModal}
          setShowPlacedModal={setShowPlacedModal}
          header="Order Placed Successfully"
          autoClose={true}
        />
      )}
      <CustomOrderContext>
        {showStepModal && (
          <CustomOrderModal
            setShowStepModal={setShowStepModal}
            showStepModal={showStepModal}
            add_items_by={add_items_by}
          />
        )}
        {!jewelkam_multi_fs_response.isLoading &&
        jewelkam_multi_fs_response.data ? (
          <FormProvider {...methods}>
            <form
              className="new-custom-order-container ui form-ui"

              // onSubmit={methods.handleSubmit(onSubmit)}
            >
              <h2 className="new-custom-order-title title ui title-ui">
                {title ? <span>New {title}</span> : null}
              </h2>
              <div className="new-custom-order-wrapper">
                <MultiCustomOrderForm
                  default_fields={
                    jewelkam_multi_fs_response.data?.default_fields
                  }
                />

                {!allow_more_than_one_item_in_order_oms &&
                custom_order_data?.items?.length > 0 ? null : (
                  <AddItemsButtons
                    setShowStepModal={setShowStepModal}
                    set_add_items_by={set_add_items_by}
                  />
                )}

                <CustomNewOrderCard set_add_items_by={set_add_items_by} />

                <CustomOrderGeneral
                  general_fields={
                    jewelkam_multi_fs_response.data?.general_fields
                  }
                />
              </div>

              <CustomNewOrderFooter
                placingOrder={placingOrder}
                place_order={place_order}
                ref={FooterRef}
              />
            </form>
          </FormProvider>
        ) : (
          <Loader isLoading={true} />
        )}
      </CustomOrderContext>
    </>
  );
}

export default CustomNewOrder;
