import React from 'react'
import Schedule_Call_Btn from './video-calling-buttons/schedule-call-btn'
import WhatsApp_Us_Btn from './video-calling-buttons/whats-app-btn'
import Call_Us_Btn from './video-calling-buttons/call-us-btn'

export default function Video_Call_Welcome_Screen() {
  return (
    <div className='video-call-welcome-screen'>
        <p className='welcome-text'>
            Experience HD Quality Video Call with us. For more info
        </p>

        <div className='buttons-group'>
            <Schedule_Call_Btn />
            <WhatsApp_Us_Btn />
            <Call_Us_Btn />
        </div>
    </div>
  )
}
