import { useEffect } from "react"
import { findMostFrequentNumber } from "../../utils"

export default function SetSameHeight({traget_class, dependency = []}) {
    useEffect(() => {
        const cat_img = document.querySelectorAll(traget_class)
        if(cat_img) {
          let imgArray = Array.from(cat_img)
          let imgHeight = imgArray.map(img => img.offsetHeight)
          let height = findMostFrequentNumber(imgHeight)
          imgArray.forEach(img => {
            if(img.offsetHeight !== height){
              img.style.maxHeight = `${height}px`
              img.style.objectFit = 'contain'
            }
          })
        }
      }, dependency)

  return (
    null
  )
}
