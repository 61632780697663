import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./NamedWishlistPopUp.css";
import { useForm, useWatch } from "react-hook-form";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import WishlistInputModal from "../../Pages/NamedWishlist/Components/WishlistInputModal";
import { fetch_data_from_api } from "../../Api/APICalls";
import { ApiList } from "../../Api/ApiList";
import { useDispatch, useSelector } from "react-redux";
import { getCatMaster, set_inCartWishList, set_trigger_shortlist_btn } from "../../redux/Reducer/reducer";
import { get_property_settings, ToastError, ToastSuccess } from "../Helper";
import { useQueryClient } from "@tanstack/react-query";
import { get_search_params } from "../../utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "min(400px, 90%)",
  // bgcolor: "background.paper",
  bgcolor: "var(--named_wishlist_popup_bg_color, #fff)",
      border: '1px solid var(--named_wishlist_popup_hairline_color, #eee)',
      borderRadius: '0.3rem',
  //   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function NamedWishlistPopUp({
  openNmaedWishlistpopupModal,
  handleCloseWishlistpopupModal,
  data,
  product_id,
  
}) {
  const { register, setValue, getValues, control } = useForm();
  const [selectAll, setSelectAll] = useState(false);
  const master = useSelector(getCatMaster);
  const queryClient = useQueryClient();
  const {mode, wishlist_id} = get_search_params()
  const ids = useMemo(() => {
    if(Array.isArray(data)){
      return data.map((list) => list.id)
    }else{
      return []
    }
  }, [data]);
  
  const shortlist_label = get_property_settings("shortlist_label")
  const dispatch = useDispatch()

  const wishlist_watch = useWatch({control, name: "wishlist"})

  const [openWishlistInputModal, setOpenWishlistInputModal] = useState(false);
  const handleCloseWishlistInputModal = () => setOpenWishlistInputModal(false);
  const handleOpenWishlistInputModal = () => setOpenWishlistInputModal(true);

  function handleSelectAll() {
    if(!wishlist_watch) {
      setSelectAll(true)
      setValue("wishlist", ids)
    }
    else if(Array.isArray(wishlist_watch)){
        const isSelectAll = ids.every(id => wishlist_watch.includes(id))
        if(isSelectAll){
          setSelectAll(false)
          setValue("wishlist", "")
        }else{
          setSelectAll(true)
          setValue("wishlist", ids)
        }
    }
  }

  async function handleSubmit() {
    
    const { wishlist } = getValues();
    let selected_id = "";
    let unselected_id = "";
    if (Array.isArray(wishlist) && wishlist.length === 0) {
      unselected_id = data.map((list) => list.id).join(",");
    } else {
      if (Array.isArray(wishlist)) {
        unselected_id = data
          .filter((list) => !wishlist.includes(list.id))
          .map((list) => list.id)
          .join(",");
        selected_id = wishlist.join(",");
      } else if (typeof wishlist === "string" && Array.isArray(data)) {
        unselected_id = data
          .filter((list) => list.id !== wishlist)
          .map((list) => list.id)
          .join(",");
        selected_id = wishlist;
      }
    }

    try {
      const named_wishlist_response = await fetch_data_from_api(
        ApiList.named_wishlist_manage_product,
        ApiList.named_wishlist_manage_product,
        {
          selected_id,
          unselected_id,
          product_id,
          which_master: master,
          session_id: "",
        }
      );

      if (
        named_wishlist_response?.ack === "1" ||
        named_wishlist_response?.ack === 1
      ) {
        ToastSuccess(named_wishlist_response?.msg);
        await queryClient.invalidateQueries({ queryKey: ["named_wishlist"] });
        dispatch(set_inCartWishList({
          in_cart_id_dm: named_wishlist_response.in_cart_id_dm,
          in_cart_id_im: named_wishlist_response.in_cart_id_im,
          in_wishlist_id_dm: named_wishlist_response.in_wishlist_id_dm,
          in_wishlist_id_im: named_wishlist_response.in_wishlist_id_im,
        }))
        // await queryClient.invalidateQueries({ queryKey: ["login"] });
        if(mode === "named_wishlist"){
          await queryClient.invalidateQueries(["matrix-count"]);
          await queryClient.invalidateQueries(["matrix-data"]);
        }
        queryClient.invalidateQueries({ queryKey: ["named_wishlist_initial"] });
        if(window.innerWidth < 576){
          dispatch(set_trigger_shortlist_btn(true));
        }
        handleCloseWishlistpopupModal(null, window.scrollY)
      } else if (
        named_wishlist_response?.ack === "0" ||
        named_wishlist_response?.ack === 0
      ) {
        ToastError(named_wishlist_response?.error?.replace("product", "shortlist"));
      }
    } catch (e) {
      // console.log(e)
      ToastError("Something went wrong");
    }
    
  }

  useLayoutEffect(() => {
   
    
    if(mode !== "named_wishlist" || !wishlist_id) return
    const selected_ids = data?.filter(list => list.product_count !== "0").map(data => data?.id)
    
    
    if(selected_ids.length > 1){
      setValue("wishlist", selected_ids)
    }else{
      setValue("wishlist", selected_ids.join(","))
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, wishlist_id])
  
  useEffect(() => {
    
    if(Array.isArray(wishlist_watch)){
      const isSelectAll = ids.every(id => wishlist_watch.includes(id))
      if(isSelectAll){
        setSelectAll(true)
      }else{
        setSelectAll(false)
      }
    }else{
      if(wishlist_watch){
        setSelectAll(true)
      }else{
        setSelectAll(false)
      }
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids, wishlist_watch])
   
 

  return (
    <>
      {openWishlistInputModal && (
        <WishlistInputModal
          openWishlistInputModal={openWishlistInputModal}
          handleCloseWishlistInputModal={handleCloseWishlistInputModal}
        />
      )}
      <Modal
        open={openNmaedWishlistpopupModal}
        onClose={handleCloseWishlistpopupModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="named-wishlist-popup-modal"
        disableAutoFocus
      >
        <Box sx={style}>
          <div className="named-wishlist-popup">
            <header>
              <h3>{shortlist_label}</h3>
              <div className="right">
                {!Array.isArray(data) && data?.ack === "0" ? null : (
                  <button onClick={handleSelectAll}>
                    {selectAll ? "Remove All" : "Select All"}
                  </button>
                )}
                <button onClick={handleOpenWishlistInputModal}>+ New</button>
              </div>
            </header>

            <section className="input-section">
              {!Array.isArray(data) && data?.ack === "0" ? (
                <h3 className="no-wishlist">Please add {shortlist_label}</h3>
              ) : (
                <ul>
                  {data?.map((list) => {
                    const { id, wishlist_name } = list;
                    
                    return (
                      <li key={id}>
                        <input
                          type="checkbox"
                          value={id}
                          id={`${wishlist_name}_${id}`}
                          {...register("wishlist")}
                        />
                        <label htmlFor={`${wishlist_name}_${id}`}>{wishlist_name}</label>
                      </li>
                    );
                  })}
                </ul>
              )}

              <div className="btns">
                <div className="button" onClick={() => {
                  
                  handleCloseWishlistpopupModal(null, window.scrollY)
                  }}>
                  cancel
                </div>
                <div className="button" onClick={handleSubmit}>
                  done
                </div>
              </div>
            </section>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default NamedWishlistPopUp;
