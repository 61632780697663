import { useQuery } from '@tanstack/react-query';
import React, { useRef } from 'react'
import { fetch_data_from_api_from_query } from '../../../../../Api/APICalls';
import { ApiList } from '../../../../../Api/ApiList';
import CallUsModal from '../../../../../Component/HomeComponents/HomeHeader/components/MobileMenu/Components/CallUsModal/CallUsModal';

export default function Call_Us_Btn() {
  const open_call_us_modal_ref = useRef()
      const call_us_query = useQuery({
          queryKey: ["CallUs"],
          queryFn: () =>
            fetch_data_from_api_from_query(ApiList.CallUs, "CallUs"),
          
        });
    
  return (
    <>
    {call_us_query.isSuccess ? <CallUsModal ref={open_call_us_modal_ref} call_data={call_us_query?.data}/> : null}
      <button type='button' onClick={() => open_call_us_modal_ref.current?.open_modal()}>
                Call Us
      </button>
    </>
   
  )
}
