import "./CustomOrder.css";
import CustomOrderStatus from "./Components/CustomOrderStatus";
import CustomOrderSearch from "./Components/CustomOrderSearch";
import CustomOrderCard from "./Components/CustomOrderCard";
import { Navigate, useLocation } from "react-router-dom";
import {
  get_bulk_settings,
  get_informationFromSessionID,
  get_userData_from_activeUser,
  show_custom_order_menu,
} from "../../Component/Helper";
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../Api/APICalls";
import { ApiList } from "../../Api/ApiList";
import Loader from "../../Component/Loader";
import { useCallback, useEffect, useMemo, useState } from "react";
import NoProduct from "../../Component/NoProduct/NoProduct";
import PaginationGlob from "../../Component/PagiantionComp/PagiantionComp";
import NewOrderBtn from "./Components/NewOrderBtn";
import CustomOrderModal from "./CustomOrderModal";
import { useDispatch } from "react-redux";
import { set_custom_order } from "../../redux/Reducer/reducer";
import OrderPlaceModal from "../../Component/OrderPlaceModal/OrderPlaceModal";
import CustomOrderFilterOption from "./Components/CustomOrderFilterOption/CustomOrderFilterOption";


function CustomOrder() {
  const searchParams = Object.fromEntries(
    new URLSearchParams(document.location.search)
  ) || {};
  const dispatch = useDispatch();

  const show_kam_module_custom_order = show_custom_order_menu();

  const [search_key, set_search_key] = useState(
    () => searchParams?.order_no || ""
  );
  const [page, set_page] = useState(() => Number(searchParams?.page) || 0);
  const handle_page = useCallback((pageNo) => set_page(pageNo), []);
  const [status_group, set_status_group] = useState(null);
  const [total_order_count, set_total_order_count] = useState(0);
  const [showStepModal, setShowStepModal] = useState(false);
  

  
  const location = useLocation()

  const [active_status, set_active_status] = useState(
    searchParams?.status || null
  );

  const [showPlacedModal, setShowPlacedModal] = useState({
    show: false,
    response: {
      title: "Thanks for placing your order using KAM",
      subtitle: "We hope you had a great overall experience using this app",
    },
  });
  const HeaderMenu = sessionStorage.getItem("HeaderMenu")
  const headerMenuHeight = useMemo(() => HeaderMenu 
    ? sessionStorage.getItem("HeaderMenu") + "px"
    : "0px", [HeaderMenu]);


  let {
    append_kam_path_folder,
    Jewelkam_order_listing_row_size,
    user_role_id_for_karigar,
    oms_add_multiple_item_app,
  } = get_bulk_settings([
    "append_kam_path_folder",
    "Jewelkam_order_listing_row_size",
    "user_role_id_for_karigar",
    "oms_add_multiple_item_app",
  ]);

  // eslint-disable-next-line no-unused-vars
  oms_add_multiple_item_app = oms_add_multiple_item_app === "Yes";

  const user_data = get_userData_from_activeUser() || {};
  

  const userID = get_informationFromSessionID("userID");

  const is_karigar = user_role_id_for_karigar === user_data?.user_type;

  const kam_order_list_response = useQuery({
    queryKey: [
      {searchParams},
      "kam_order_list",
      userID,
      // state?.refetch,
      searchParams?.page,
      searchParams?.status,
      search_key
    ],
    queryFn: () =>
      fetch_data_from_api_from_query(
        `${append_kam_path_folder}/${ApiList.kam_order_list}`,
        "kam_order_list",
        {
          order_no: searchParams?.order_no || "",
          is_karigar: is_karigar ? "yes" : "no",
          client_master_id: user_data?.client_id || "",
          session_id: 0,
          sort: searchParams?.sort || 0,
          status: searchParams?.status || active_status || "",
          page_size: Jewelkam_order_listing_row_size,
          ...searchParams,
          page: !!searchParams?.order_no ? "0" : searchParams?.page || 0,
        }
      ).then((res) => {
        // set_total_order_count(Number(res?.total_order_count) || 0)
        const is_filter_applied = location.state?.filter_applied;
        
        if (!status_group || is_filter_applied || searchParams?.order_no || searchParams?.order_no === "") {
          set_status_group(res?.status_group);
          set_active_status(searchParams?.status || res?.status_group[0]?.id);
        }

        if (!active_status) {
          set_active_status(searchParams?.status || res?.status_group[0]?.id);
        }

        return res;
      }),
    refetchOnMount: "always",
    enabled: show_kam_module_custom_order.status,
  });

  useEffect(() => {
    // window.scrollTo(0, 0);
    dispatch(set_custom_order({ type: "reset" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams?.page, searchParams?.status]);

  useEffect(() => {
    if (!status_group) {
      if (kam_order_list_response.isSuccess && kam_order_list_response.data) {
        set_active_status(kam_order_list_response.data?.status_group[0]?.id);
      }
      return;
    }
    const selected_status = status_group.find(
      (group) => group.id === active_status
    );
    
    if (selected_status) {
      set_total_order_count(Number(selected_status?.order_count));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_status, status_group, kam_order_list_response.isSuccess]);

  if (!show_kam_module_custom_order.status) {
    return <Navigate to="/" />;
  }

  return (
    <>
      {showPlacedModal.show && (
        <OrderPlaceModal
          showPlacedModal={showPlacedModal}
          setShowPlacedModal={setShowPlacedModal}
          header="Order Placed Successfully"
          autoClose={true}
        />
      )}

      {showStepModal && (
        <CustomOrderModal
          setShowStepModal={setShowStepModal}
          showStepModal={showStepModal}
          setShowPlacedModal={setShowPlacedModal}
        />
      )}
      <section className="custom-order-section">
        {status_group && (
          <header style={{ top: headerMenuHeight }}>
          
            <section className="header-inner">
              <div className={`status-search-section`}>
                <CustomOrderStatus
                  status_group={status_group || []}
                  set_active_status={set_active_status}
                  active_status={active_status}
                />
                
                  <CustomOrderSearch
                    search_key={search_key}
                    set_search_key={set_search_key}
                  />
                
              </div>
              {total_order_count > 1 ? (
                <PaginationGlob
                  size={window.innerWidth > 992 ? "large" : "medium"}
                  totalProductCount={total_order_count}
                  page={page}
                  handle_page={handle_page}
                  page_size={Jewelkam_order_listing_row_size}
                />
              ) : null}
            </section>
          </header>
        )}

        {!kam_order_list_response.isLoading ? (
          <div className="custom-order-body">
              { show_kam_module_custom_order.label ? 
            <h2 className="custom-order-body-title title d-lg-none">
                <span >
                  {show_kam_module_custom_order.label}
                </span>
            </h2> : null
              }
            {kam_order_list_response?.data?.ack === "0" ? (
              <NoProduct
                errMsg={kam_order_list_response?.data?.error || "No data found"}
                hideHomeBtn={true}
              />
            ) : (
              <div className="custom-order-body-cards-section">
                {kam_order_list_response?.data?.order_list?.map((order) => (
                  <CustomOrderCard key={order?.id} order={order} />
                ))}
              </div>
            )}
          </div>
        ) : (
          <Loader isLoading={true} />
        )}
        {kam_order_list_response?.isSuccess ? (
          <NewOrderBtn
            setShowStepModal={setShowStepModal}
            is_karigar={is_karigar}
            total={kam_order_list_response.data?.total}
            ack={kam_order_list_response?.data?.ack}
          />
        ) : null}

        {/* <ScrollViewIntoElement dependancy={[searchParams.page, searchParams.status]} delay={300}/> */}
      </section>
    </>
  );
}

export default CustomOrder;
