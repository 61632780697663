import React, { useEffect } from 'react'
import UPSSingleCard from './UPSSingleCard';
import CardHeader from '../CardHeader/CardHeader';
import { get_property_settings } from '../../Helper';
import ReactSlider from '../../ReactSlider/ReactSlider';
import { set_equal_slick_slider_card_height } from '../../../utils';


function USPSection({uspData}) {

// const usp_heading = get_property_settings("USP_HEADING");
const usp_heading = get_property_settings("usp_heading_fsv");
const cdn = get_property_settings("cdn");

const responsive = [
  {
    breakpoint: 2000,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 1440,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
    },
  },

  {
    breakpoint: 1260,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
    },
  },

  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
];

useEffect(() => {
  if(uspData?.length > 4 && window.innerWidth > 576){
      set_equal_slick_slider_card_height('.ups-single-card')
  }
}, [uspData])

return (
    <section className="section ec-services-section section-space-p usp-section">
        <div className="container-fluid">
        <CardHeader title={usp_heading} clsName={'mb-2 mb-lg-0'}/>
            <div className="row mb-minus-30">
                {uspData?.length > 4 && window.innerWidth > 576 ? (
                <ReactSlider
                additionalSetting={{
                  infinite: false,
                  slidesToScroll: "1",
                  slidesToShow: "4"
                }}
                className="trending-slick"
                responsive={responsive}
                >
                    {
                        uspData?.map( usp => {
                          const img = `${cdn}uploads/usp_uploads/${usp?.usp_img}`
                          
                          return <UPSSingleCard title={usp?.title} desc={usp?.description} img={img} key={`usp_${usp?.position}`}/>
        
                        })
                    }
                </ReactSlider>
              ) : (
                  uspData?.map( usp => {
                  const img = `${cdn}uploads/usp_uploads/${usp?.usp_img}`
                  
                  return <UPSSingleCard title={usp?.title} desc={usp?.description} img={img} key={usp?.position}/>

                })
              )
              
              
              }
            </div>
        </div>
    </section>
  )
}

export default React.memo(USPSection);
