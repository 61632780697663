
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../../../Api/APICalls";
import { ApiList } from "../../../../Api/ApiList";
import HomeBanner from "../../../../Component/HomeComponents/HomeBanner/HomeBanner";
import "./CustomLoginPage.css";
import Loader from "../../../../Component/Loader";
import LoginContainer from "../../Components/LoginContainer";
import { useEffect, useRef, useState } from "react";

function CustomLoginPage({header = "Login", className=""}) {
  const [showOptContainer, setShowOptContainer] = useState(false);
  const loginCustomRef = useRef(null)
    const homescreen_query = useQuery({
        queryKey: ["homescreen"],
        queryFn: () =>
          fetch_data_from_api_from_query(ApiList.homeScreen, "homescreen", {
            device: "web",
          }),
        placeholderData: (previousData, previousQuery) => previousData,
        staleTime: Infinity,
      });

      useEffect(() => {
        if(!loginCustomRef.current) return
        loginCustomRef.current.scrollIntoView()
      }, [loginCustomRef.current])

      if(homescreen_query.isLoading){
        return <Loader isLoading={true}/>
      }

      

  return (
    <section className={`custom-login-page ${className}`} ref={loginCustomRef}>
        
          <HomeBanner brandingData={homescreen_query?.data?.branding} />
        
        <div className="login-container-wrapper">
            <header><h2>{showOptContainer ? "Verify OTP" : header}</h2></header>
            <LoginContainer showOptContainer={showOptContainer} setShowOptContainer={setShowOptContainer}/>
        </div>
    </section>
  )
}

export default CustomLoginPage
