import ReactSlider from "../../ReactSlider/ReactSlider";
import Banner from "./Banner";
import { get_property_settings } from "../../Helper";
import React from "react";
import { getCustomValue } from "../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../Custom/CompanyCustomization";
import CustomDots from "./Components/CustomPadding";

function HomeBanner({ brandingData }) {
  const cdn = get_property_settings("cdn");
  const show_floating_social_icons = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.show_floating_social_icons
  );
  const shift_upward_home_banner_gradient = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.shift_upward_home_banner_gradient)
  const pauseDuration = brandingData?.map((data) =>
    Number(data?.pause_duration)
  );

  return (
    <div className={`sticky-header-next-sec ec-main-slider section section-space-pb home-banner-slider ${shift_upward_home_banner_gradient ? "shift-gradient-upward" : ""}`}>
      <div className="ec-slider swiper-container main-slider-nav main-slider-dot">
        {/* -- Main slider -- */}
        <div className="swiper-wrappers">
          <ReactSlider
            additionalSetting={{
              dots: true || show_floating_social_icons,
              dotsClass: "home-banner-slick-dots",
              autoplay: false,
              cssEase: "linear",
              pauseOnHover: false,
              arrow: false,
              customPaging: function(i) {
                return (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a>

                    <CustomDots />
                  </a>
                  
                );
              },
              
              }
              }
            className={`banner-slick ${
              show_floating_social_icons ? "no-arrow" : ""
            }`}
            pauseDuration={pauseDuration}
          >
            {brandingData.map((branding) => {
              let isVideo = branding.img_for === "web_video";
              let img = "";
              let video = "";
              if (isVideo) {
                if (branding?.branding_img && branding?.branding_vid_mobile) {
                  video = [
                    `${cdn}uploads/branding_video/web/${branding?.branding_img}`,
                    `${cdn}uploads/branding_video/web/${branding?.branding_vid_mobile}`,
                  ];
                } else {
                  video = `${cdn}uploads/branding_video/web/${branding?.branding_img}`;
                }
              } else if (
                branding?.branding_img &&
                branding?.branding_img_mobile
              ) {
                img = [
                  `${cdn}uploads/branding_image/web/${branding?.branding_img}`,
                  `${cdn}uploads/branding_image/web/${branding?.branding_img_mobile}`,
                ];
              } else {
                img = `${cdn}uploads/branding_image/web/${branding?.branding_img}`;
              }

              return (
                <Banner img={img} key={branding?.position} video={video} />
              );
            })}
          </ReactSlider>
        </div>
        <div className="swiper-pagination swiper-pagination-white"></div>
        <div className="swiper-buttons">
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(HomeBanner);
