import "./shankesh-testimonial-card.css";
import { FaStar } from "react-icons/fa";


function ShankeshTestmonialCard({client_name, directors, company_logo, info, list_info = null, style = {}}) {
    
    return (
        <li className="ec-test-item shankesh-testimonial-card" style={style}>
            <i className="fi-rr-quote-right top"></i>
            <div className="ec-test-inner">
                {/* <div className="ec-test-img"><img alt="testimonial" title="testimonial"
                    src="assets/images/testimonial/1.jpg" /></div> */}
                <div className="ec-test-content">
                    {company_logo && <div className="img-container">
                        <img src={company_logo}/>
                    </div>}
                    <div className="ec-test-name client-name">{client_name}</div>
                    <div className='directors-name'>
                        {directors.join(", ")}
                    
                    </div>
                    <div className="ec-test-rating ratings">
                        <FaStar className="star-icon"/>
                        <FaStar className="star-icon"/>
                        <FaStar className="star-icon"/>
                        <FaStar className="star-icon"/>
                        <FaStar className="star-icon"/>
                    </div>
                    <div className="ec-test-desc desc">{Array.isArray(info) ? (
                        info.map((para, index) => <p key={index}>{para}</p>)
                    ) : ""}</div>
                    {
                        list_info ? (
                            <ul className="desc-list">
                                {list_info.map((list, index) => <li key={index}>{list}</li>)}
                            </ul>
                        ) : null
                    }
                    {/* <div className="ec-test-designation">General Manager</div> */}
                </div>
            </div>
            <i className="fi-rr-quote-right bottom"></i>
        </li>
    )
}

export default ShankeshTestmonialCard
