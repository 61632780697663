import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import "./MUITabs.css";

function MUITabs({children, tabs=[], defaultValue="", getSelectedTab = () => {}, className = ""}) {
    const [value, setValue] = useState(() => defaultValue.toLowerCase().replace(/\s/g, "-"));

    const handleChange = (_, newValue) => {
      setValue(newValue);
    };

    useEffect(() => {
        getSelectedTab(value)
    }, [value])

    if(!tabs?.length) return null

  return (
    <div className={`MUITabs-wrapper ${className}`}>
    <header className='MUITabs-header'>
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs"
        textColor='inherit'
        variant="fullWidth"
      >
        {
            tabs.map(tab => {
                const value = tab.toLowerCase().replace(/\s/g, "-");
                return <Tab value={value} label={tab} />
            })
        }
        
        
      </Tabs>
    </Box>
    </header>

    <section className='MUITabs-Container'>
        {children}
    </section>
    </div>
  )
}

export default MUITabs
