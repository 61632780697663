
import React, { useEffect,  useRef } from "react";
import LastUpdate from "../../Component/LiveRate/Components/LastUpdate";
import Loader from "../../Component/Loader";
import { get_bulk_settings } from "../../Component/Helper";
import "./LiveRates.css";
import NoProduct from "../../Component/NoProduct/NoProduct";
import useLiveRateReact from "../../Hooks/useLiveRateReact/useLiveRateReact";

function LiveRatesReact() {
  let {
    gold_rate_menu_title,
    live_rate_show_buy_rate,
    live_rate_show_low_rate,
    live_rate_show_sell_rate,
    live_rate_show_high_rate,
    live_rate_show_buy_title,
    live_rate_show_sell_title
  } = get_bulk_settings([
    "gold_rate_menu_title",
    "live_rate_show_buy_rate",
    "live_rate_show_low_rate",
    "live_rate_show_sell_rate",
    "live_rate_show_high_rate",
    "live_rate_show_buy_title",
    "live_rate_show_sell_title",
  ]);

  live_rate_show_buy_title = live_rate_show_buy_title === "Yes"
  live_rate_show_sell_title = live_rate_show_sell_title === "Yes"
  live_rate_show_buy_rate = live_rate_show_buy_rate === "Yes";
  live_rate_show_low_rate = live_rate_show_low_rate === "Yes";
  live_rate_show_sell_rate = live_rate_show_sell_rate === "Yes";
  live_rate_show_high_rate = live_rate_show_high_rate === "Yes";
  
  const prev_live_rate_data = useRef(null)
  const current_live_rate_data = useRef(null)

  // const live_rate = {"display_name":"GOLD MCX","ask_rate":69244,"bid_rate":69227,"high_rate":69440,"low_rate":68740,"short_code":"MCX995","desc":"GOLD MCX RATE","time_stamp":1723035658748,"show_low":1,"show_high":1}
  
  const {live_rate_data, TimeStamp} = useLiveRateReact() || {};



  if(live_rate_data){
    current_live_rate_data.current = live_rate_data

    if(!prev_live_rate_data.current){
      // prev_live_rate_data.current = current_live_rate_data.current
      // set_live_rate_data(Live_rate_query_array)
    }else{
      current_live_rate_data.current = current_live_rate_data.current.map((main_data, main_index) => {
          
          let prev_data = prev_live_rate_data.current[main_index]
          
          return main_data.map((sub_data, sub_index) => {
            let updated_data = {...sub_data}
            
            if (parseFloat(updated_data.bid_rate) > parseFloat(prev_data[sub_index]?.bid_rate)) {
              
              updated_data = {...updated_data, bidClass: "green-block"}
            }else if(parseFloat(updated_data.bid_rate) < parseFloat(prev_data[sub_index]?.bid_rate)){
              updated_data =  { ...updated_data, bidClass: "red-block" };
              
            }

            if (parseFloat(updated_data.ask_rate) > parseFloat(prev_data[sub_index]?.ask_rate)) {
              updated_data =  { ...updated_data, askClass: "green-block" };
              
            }else if(parseFloat(updated_data.ask_rate) < parseFloat(prev_data[sub_index]?.ask_rate)){
              updated_data =  { ...updated_data, askClass: "red-block" };
              
            }
            
            return updated_data;
          })
      })
      
      
    }
  }
 
  useEffect(() => {

         // 4. comapre prev data with current data and update colors
        if(!live_rate_data || !current_live_rate_data.current) return 
       
        
    prev_live_rate_data.current = current_live_rate_data.current

  }, [live_rate_data])
  
  
  


  // return null

  return (
    <section className="live-rate-app-page">
      <LastUpdate time_stamp={TimeStamp} />
      <section className="live-rate-section">
        <header>
          <h2>{gold_rate_menu_title || "LIVE RATES"}</h2>
        </header>
        <div className="live-rate-body">
          <div className="live-rate-container">
            {!prev_live_rate_data.current || !current_live_rate_data.current ? (
              <>
              {
                // process.env.NODE_ENV === "production" ? <NoProduct errMsg={"Coming Soon"} hideHomeBtn={true}/> : <Loader isLoading={true} />
                <Loader isLoading={true} />
              }
              </>
            ) : (
              <>
                <div className="header rows">
                  <div className="name"></div>
                  {live_rate_show_buy_title && <div className="buy">
                    <span>BUY</span>
                  </div>}
                  {live_rate_show_sell_title && <div className="sell">
                    <span>SELL</span>
                  </div>}
                </div>
                {current_live_rate_data.current.map((section, index) => {
                  return (
                    <div
                      className={`section-${index} section`}
                      key={`section-${index}`}
                    >
                      {!!section && section?.map((data) => {
                        
                        const bidClass = data?.bidClass || ""
                        const askClass = data?.askClass || ""
                        
                        return (
                          <div className="rows live-rate-row" key={data?.display_name}>
                            <div className="name">{data?.display_name}</div>
                            <div className="buy">
                              {live_rate_show_buy_rate && (
                                <span className={`block ${bidClass}`}>
                                  {data?.bid_rate}
                                </span>
                              )}
                              {live_rate_show_low_rate && (
                                <span className="small">
                                  L: {data?.low_rate}
                                </span>
                              )}
                            </div>
                            <div className="sell">
                              {live_rate_show_sell_rate && (
                                <span className={`block ${askClass}`}>
                                  {data?.ask_rate}
                                </span>
                              )}
                              {live_rate_show_high_rate && (
                                <span className="small">
                                  H: {data?.high_rate}
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </>
            )}
            {}
          </div>
        </div>
      </section>
    </section>
  );
}

export default LiveRatesReact;
