import { useFormContext } from "react-hook-form"
import { ToastError } from "../../../../../../Component/Helper";



function InputOption({data, field, mendatory_field, is_multiselect}) {
  const value = data[`${field}_value`]
  const { register, getValues, setValue} = useFormContext()
  const { onChange, onBlur, name, ref } = register(field); 
  
  function handleNonMultiClick (e) {
      const {value} = e.target;
      const selectedValue = getValues()
      const prevSelectedValue = selectedValue[field];
      
      if(prevSelectedValue === value || (Array.isArray(prevSelectedValue) && prevSelectedValue.includes(value))){
        setValue(field, null)
      }else{
        setValue(field, value)
      }
  }
  
  return (
    <div className='input-section'>
                {is_multiselect ? <input 
                type='checkbox' 
                id={`${name}-${value}`}
                value={data.id} 
                disabled={mendatory_field} 
                onChange={(e) => {
                  const {design_status} = getValues() || {}
                  
                  if(name === "design_status" && design_status && design_status.length === 1){
                    e.target.checked = true;
                  }else{

                    onChange(e)
                  }
                }} // assign onChange event 
                onBlur={onBlur} // assign onBlur event
                name={name} // assign name prop
                ref={ref} // assign ref prop
                /> : <input 
                type='checkbox' 
                id={`${name}-${value}`} 
                value={data.id} 
                disabled={mendatory_field} 
                onClick={handleNonMultiClick}
                onChange={onChange} // assign onChange event 
                onBlur={onBlur} // assign onBlur event
                name={name} // assign name prop
                ref={ref} // assign ref prop
                />}
                <label htmlFor={`${name}-${value}`} onClick={() => {
                  if(mendatory_field){
                    ToastError("This field is mandatory")
                  }
                }}>{value}</label>
            </div>
  )
}

export default InputOption