import { useEffect } from "react"
import { useLocation } from "react-router-dom"


function CheckoutOrderInfoModalConfig({parameterData, setValue}) {
  const location = useLocation()
  const finalize_fields_data = location.state?.finalize_fields_data;
  
 

  function update_modal_data(){
    //update form default value with parameterData
    
    parameterData.forEach(upadtedParams => {
      
      

      if(upadtedParams.short_code === "distributor" || upadtedParams.short_code === "retailer" || upadtedParams.short_code === "voucher_order_type"){
        if(upadtedParams?.value){

          setValue(upadtedParams.short_code, {label: upadtedParams?.value, value: upadtedParams?.value})
        }
      }else {
        setValue(upadtedParams.short_code, upadtedParams?.value)
      }
    })

  }
  

  useEffect(() => {
    if(!parameterData || !parameterData?.length || !finalize_fields_data) return
    setTimeout(() => {
      update_modal_data()
    }, 50)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalize_fields_data, parameterData])
  

  return null
}

export default CheckoutOrderInfoModalConfig
