import React, { useEffect, useMemo, useRef, useState } from "react";
import MenuDropdownList from "./MenuDropdownList";
import { Link, useLocation } from "react-router-dom";
import RecursiveCategoryModal from "../../RecursiveCategory/RecursiveCategoryModal";
import { getCustomValue } from "../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../Custom/CompanyCustomization";
import { FaVideo } from "react-icons/fa";
import SearchCartMenuOpt from "./components/Search_Cart_Menu_Opt";
import {
  get_bulk_settings,
  get_informationFromSessionID,
  get_property_settings,
  show_custom_order_menu,
  show_live_rate_menu,
} from "../../Helper";
import { append_all_product_menu_in_categories } from "../../../utils";

function HeaderMenu({ categories }) {
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [show_search_cart_opt, set_show_search_cart_opt] = useState(false);
  const HeaderMenuRef = useRef(null);
  
  const company_code = get_informationFromSessionID("companyCode")
  const append_company_code_in_url = process.env.NODE_ENV === "development" ? `?company_code=${company_code}` : '';

  const location = useLocation();
  const show_kam_module_custom_order = show_custom_order_menu();
  let {
    web_parent_category_from_for_navigation_bar,
    web_parent_category_from_for_navigation_bar_text,
    all_category_button,
    gold_rate_page_title,
    gold_rate_menu_title,
    
  } = get_bulk_settings([
    "web_parent_category_from_for_navigation_bar",
    "web_parent_category_from_for_navigation_bar_text",
    "all_category_button",
    "gold_rate_page_title",
    "gold_rate_menu_title",
    
  ]);

  
  const category_list = useMemo(() => append_all_product_menu_in_categories(categories), [categories])

  

  function handleScrollEvent() {
    if (!HeaderMenuRef?.current) return;
    if (window.scrollY < 40) return set_show_search_cart_opt(false);
    const HeaderMenuBound = HeaderMenuRef?.current?.getBoundingClientRect();

    if (HeaderMenuBound.y <= 0) {
      set_show_search_cart_opt(true);
    } else {
      set_show_search_cart_opt(false);
    }
  }

  useEffect(() => {
    sessionStorage.setItem("HeaderMenu", HeaderMenuRef?.current?.offsetHeight);
    sessionStorage.setItem(
      "HeaderMenuOffsetTop",
      HeaderMenuRef?.current?.getBoundingClientRect().y
    );
    window.addEventListener("scroll", handleScrollEvent);
    return () => window.removeEventListener("scroll", handleScrollEvent);
  }, [category_list]);

  if (!category_list)
    return (
      <p className="text-center opacity-50 mt-3 d-lg-block d-none">
        Loading....
      </p>
    );
    
  const custom_menu = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.custom_menu);

  const Our_Collection_Menu =
    web_parent_category_from_for_navigation_bar === "static" &&
    web_parent_category_from_for_navigation_bar_text
      ? web_parent_category_from_for_navigation_bar_text
      : "";
  all_category_button = all_category_button === "Yes" && !Our_Collection_Menu;
  
  return (
    <div
      id="ec-main-menu-desk"
      className={`d-none d-lg-block sticky-nav home-menu sticky-menu`}
      ref={HeaderMenuRef}
    >
      {showCategoryModal && (
        <RecursiveCategoryModal
          showCategoryModal={showCategoryModal}
          setShowCategoryModal={setShowCategoryModal}
        />
      )}

      <div
        className={`${
          Our_Collection_Menu ? "box-container-width" : "container-fluid"
        } position-relative`}
      >
        <div className="row">
          <div className="col-md-12 align-self-center">
            <div className="ec-main-menu position-relative">
              <ul>
                <li
                  className={`${
                    location.pathname === "/" || location.pathname === "/home"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link to="/home">Home</Link>
                </li>

                {custom_menu
                  ? custom_menu?.map((menu, menu_index) => {
                      const isVideoMenu = menu.name
                        .toLowerCase()
                        .includes("video");

                      if (menu.type === "mobile-menu") return null;
                      /*********  LIVE RATE ********************/
                      if (
                        menu.link === "/live-rates" 
                       ) {
                        if(show_live_rate_menu()){

                          return (
                            <li key={`live_${menu.name}`}>
                              <Link
                                to={menu.link}
                                className={`${
                                  location.pathname.includes("live-rates")
                                    ? "active-link"
                                    : ""
                                }`}
                              >
                                {gold_rate_menu_title || "LIVE RATES"}
                              </Link>
                            </li>
                          );
                        }else{
                          return null
                        }
                        
                        /*********  OUR COLLECTION ********************/
                      } else if (
                        menu.link === "/categories"
                      ) {
                        
                        return Our_Collection_Menu
                          ? Array.isArray(category_list) &&
                          category_list?.length > 0 && (
                                <MenuDropdownList
                                key={`menu_${menu.name}`}
                                  cat_name={Our_Collection_Menu}
                                  subcategory={category_list}
                                  //key={category.id}
                                  setShowCategoryModal={setShowCategoryModal}
                                  id={Our_Collection_Menu.replace(
                                    /\s/g,
                                    "_"
                                  ).toLowerCase()}
                                />
                              )
                          : Array.isArray(category_list) &&
                              category_list?.length > 0 &&
                              category_list?.map((category) => (
                                <MenuDropdownList
                                key={`menu_2_header_${menu.name + category.id}`}
                                  {...category}
                                  
                                  setShowCategoryModal={setShowCategoryModal}
                                  all_category_button={all_category_button}
                                />
                              ));
                        /*********  CUSTOM ORDER ********************/
                      } else if (menu.link === "/custom-order") {
                        let oms_navigate_direct_create_new_customer_order = get_property_settings("oms_navigate_direct_create_new_customer_order")
                        oms_navigate_direct_create_new_customer_order = oms_navigate_direct_create_new_customer_order === "Yes"
                        let link = "";
                        let label = "";
                        
                        if(oms_navigate_direct_create_new_customer_order){
                          link = `/${show_kam_module_custom_order.label
                            .replace(/\s/g, "-")
                            .toLowerCase()}/new-${show_kam_module_custom_order.label
                            .replace(/\s/g, "-")
                            .toLowerCase()}`;
                          label = `New ${show_kam_module_custom_order.label}`

                        }else{
                          link = `/${show_kam_module_custom_order.label
                                  .replace(/\s/g, "-")
                                  .toLowerCase()}`;
                          label = show_kam_module_custom_order.label
                        }

                        if (show_kam_module_custom_order.status) {
                          return (
                            <li
                            key={`custom_1_${menu.name}`}
                              className={`${
                                location.pathname ===
                                link
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <Link
                                to={link}
                              >
                                {label}
                              </Link>
                            </li>
                          );
                        } else {
                          return null;
                        }
                      } else {
                        return (
                          <li
                          key={`custom_2_${menu.name}`}
                            className={isVideoMenu ? "video_call_menu" : ""}
                          >
                            {menu.external_link ? (
                              <a
                                href={menu?.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {isVideoMenu ? (
                                  <FaVideo className="video_call_icon" />
                                ) : null}
                                {menu.name}
                              </a>
                            ) : (
                              <Link
                                to={`${menu?.link}${append_company_code_in_url}`}
                                key={`custom_3_${menu.name}`}
                                className={`${menu.clsName} ${
                                  location.pathname.includes(menu?.link)
                                    ? "active-link"
                                    : ""
                                }`}
                              >
                                {isVideoMenu ? (
                                  <FaVideo className="video_call_icon" />
                                ) : null}
                                {menu.name.toLowerCase() === "live rate"
                                  ? gold_rate_page_title
                                  : menu.name}
                              </Link>
                            )}
                          </li>
                        );
                      }
                    })
                  : null}
              </ul>

              {Our_Collection_Menu && show_search_cart_opt ? (
                <SearchCartMenuOpt />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderMenu;
