import { Divider, Menu, MenuItem } from "@mui/material";
import { FaUserPlus } from "react-icons/fa";
import { MdOutlineLogin, MdOutlineLogout } from "react-icons/md";
import {
  get_bulk_settings,
  get_informationFromSessionID,
  get_userData_from_activeUser,
  logOutUser,
} from "../../../../../Helper";
import { Link } from "react-router-dom";
import ModalDialog from "../../../../../ModalDialog";

import { useState, useRef } from "react";
import UserDetails from "./AfterLoginMenu/UserDetails";
import EditProfile from "./AfterLoginMenu/EditProfile";
import ChangeCurrency from "./AfterLoginMenu/ChangeCurrency";
import Shortlist from "./AfterLoginMenu/Shortlist";
import ChangePassword from "./AfterLoginMenu/ChangePassword";
import AllOrders from "./AfterLoginMenu/AllOrders";
import UserPreferencesMenu from "./AfterLoginMenu/UserPreferencesMenu";

function MUDropDownMenu({
  open,
  handleClose,
  anchorEl,
  is_mobile_navigation_menu,
}) {
  let {
    show_currency,
    enable_shortlist,
    show_named_wishlist_app,
    has_user_preferences
  } =
    get_bulk_settings([
      "show_currency",
      "enable_shortlist",
      "show_named_wishlist_app",
      "syod_pastorder_title",
      "has_user_preferences"
    ]) || {};
  show_currency = show_currency === "Yes";
  const show_shortlist =
    enable_shortlist === "Yes" && show_named_wishlist_app === "Yes";
  let show_past_order = get_userData_from_activeUser()?.show_past_order;
  show_past_order = show_past_order === '1';
 
  
  const userID = get_informationFromSessionID("userID");
  const [showModal, setShowModal] = useState(false);
  
  
  const menuRef = useRef(null);

  // useLayoutEffect(() => {
  //   if(!menuRef.current || !is_mobile_navigation_menu) return

  //   const MuiMenuPaper = menuRef.current?.children[2]
  
  //   MuiMenuPaper?.classList?.add("mobile-nav-menu")
  // })

  function BeforeLoginMenu() {
    return (
      <>
        {is_mobile_navigation_menu && (
          <link rel="stylesheet" type="text/css" href="./MUI_CSS.css" />
        )}
        <MenuItem onClick={handleClose} className="menu-list">
          <FaUserPlus className="icon" />
          <Link to="/registration">Register</Link>
        </MenuItem>
        <MenuItem onClick={handleClose} className="menu-list">
          <MdOutlineLogin className="icon" />
          <Link to="/login">Login</Link>
        </MenuItem>
      </>
    );
  }

  function AfterLogin() {
    return (
      <>
        <UserDetails handleClose={handleClose} />
        <Divider />

        {has_user_preferences === "1" ? (
          <>
            <UserPreferencesMenu handleClose={handleClose}/>
            <Divider />
          </>
        ) : null}

        {show_past_order ? (
          <>
            <AllOrders handleClose={handleClose}/>
            <Divider />
          </>
        ) : null}

        {show_shortlist ? (
          <>
            <Shortlist handleClose={handleClose} />
            <Divider />
          </>
        ) : null}

        <EditProfile handleClose={handleClose} />
        <Divider />

        {show_currency ? (
          <>
            <ChangeCurrency handleClose={handleClose} />
            <Divider />
          </>
        ) : null}

        <ChangePassword handleClose={handleClose} />
        <Divider />

        <MenuItem
          onClick={() => {
            handleClose();
            setShowModal(true);
          }}
          className="menu-list"
        >
          <MdOutlineLogout className="icon" />
          <span>Logout</span>
        </MenuItem>
      </>
    );
  }

  return (
    <>
      {showModal && (
        <ModalDialog
          show={showModal}
          setShow={setShowModal}
          action={logOutUser}
          toastMsg={"Your Successfully logout"}
        />
      )}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: is_mobile_navigation_menu ? "right" : "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: is_mobile_navigation_menu ? "right" : "center",
        }}
        className="mui-drop-down-menu"
        ref={menuRef}
      >
        {!userID ? <BeforeLoginMenu /> : <AfterLogin />}
      </Menu>
    </>
  );
}

export default MUDropDownMenu;
