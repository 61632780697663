import React, { useRef } from 'react'
import WhatsAppModal from '../../../../../Component/WhatsAppModal/WhatsAppModal'

export default function WhatsApp_Us_Btn() {
  
  const open_whatsapp_modal_ref = useRef(null)

  return (
      <>
      <WhatsAppModal ref={open_whatsapp_modal_ref}/>
      <button type='button' onClick={() => open_whatsapp_modal_ref.current.open_modal()}>
              Whats App Us
      </button>
      </>
    
  )
}
