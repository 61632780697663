import AboutUsSection from '../About_Us_Section';



function SHANKESH_Home_About_Us_Comp() {
  return (
    <AboutUsSection title="About Us">
        
        <div>
            <p>Shankesh Jewellers Pvt Ltd is a highly reputable jewelry company located in the vibrant jewel hub of Mumbai, Zaveri Bazaar. With a rich legacy spanning over 35 years, the company has established itself as a premier purveyor of fine jewelry. Our unwavering commitment to excellence has enabled us to become a trusted name in the industry.</p>

            <p><b>Directors:</b><br/>
            Shankesh Jewellers Pvt Ltd is led by two visionary directors, <b>Manoj Jain</b> and <b>Mahavir Jain</b>. Their expertise and passion for the art of jewelry have played a pivotal role in the company's success. Under their guidance, Shankesh Jewellers has consistently upheld the highest standards of craftsmanship, innovation, and customer satisfaction.</p>
        </div>
    </AboutUsSection>
  )
}

export default SHANKESH_Home_About_Us_Comp