import { useSelector } from "react-redux"
import { get_bulk_settings } from "../../../../Component/Helper"
import { get_fsv_products_calc, get_fsv_selected_product } from "../../../../redux/Reducer/reducer"
import { getCustomValue } from "../../../../Custom/CompanyList"
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../../Custom/CompanyCustomization"
import { useLocation } from "react-router-dom"


function FSVCustomVariantDetails() {
  const {sub_product_naming_format} = get_bulk_settings(["sub_product_naming_format"])
  const selectedProduct = useSelector(get_fsv_selected_product)
  const fsv_custom_varient_details = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.fsv_custom_varient_details)
  const product_calc = useSelector(get_fsv_products_calc);
  const selected_product_calc = product_calc[selectedProduct?.category_name];
  const location = useLocation()
  const isFSVPath = location.pathname.includes("/product-page")

  let calc_gross_weight= selected_product_calc?.calc_gross_weight
  let calc_net_weight= selected_product_calc?.calc_net_weight
  calc_gross_weight = parseFloat(calc_gross_weight) ? Number(calc_net_weight).toFixed(3) : null
  calc_net_weight = parseFloat(calc_net_weight) ? Number(calc_net_weight).toFixed(3) : null
  
  function get_item_code_key () {
    return sub_product_naming_format === "subcat_search_item_code" ? "search_item_code" : "mfg_code"
  }
  
  if(!fsv_custom_varient_details || (Array.isArray(fsv_custom_varient_details) && fsv_custom_varient_details?.length === 0)) return
  

  return (
    <section className={`fsv-custom-varient-details ${isFSVPath ? "mb" : ""}`}>
        <div className='container'>
          {
            fsv_custom_varient_details.map(details => {
              let key = "";
              let value = ""
              key = details.label
              if(details.under === "root"){
                value = selectedProduct[details.key]
                
              }else if(details.under === "short_code" && selectedProduct[details.under]){
                const label_index = selectedProduct[details.under]?.indexOf(details.key)
                
                if(typeof label_index === "number" && !!label_index.toString())
                value = selectedProduct.values[label_index]
               
            }else if(details.under === null){
              const code_key = get_item_code_key()
              value = key === "Design No" ? selectedProduct[code_key] : ""
              
            }
            
              value = (key === "Gross Wt" || key === "Wt") ? (calc_gross_weight || value) : key === "Net Wt" ? (calc_net_weight || value) : value

              return (
                <div className='row justify-content-start product-details-inner-group-sm' key={key}>
                <div className='col-lg-5 col-md-3 col-sm-5 align-self-center label d-flex product-details-label'>
                {key}{":"}
                </div>
                <div className='ec-pro-variation-content col-sm-7 varient-details-value'>
                    {value}
                </div>
            </div>
              )
            })
          }
            
        </div>
    </section>
  )
}

export default FSVCustomVariantDetails