import "./ButtonFlowGrid.css"

export default function ButtonFlowRowGrid({className = "", data, ButtonComp}) {
  
  return (
    <ul
      className={`status-button-group button-flow-row-grid ${className}`}
     >
      {
        data.map(status => {
          return <ButtonComp {...status} />
        })
      }
      
    </ul>
  )
}
