import { useEffect, useState } from "react";
import ReactSelect from "./ReactSelect";
import {
  createSelectArrayForReactSelect,
  get_informationFromSessionID,
  get_userData_from_activeUser,
} from "../../../../../Component/Helper";
import { fetch_data_from_api, fetch_data_from_api_from_query } from "../../../../../Api/APICalls";
import { ApiList } from "../../../../../Api/ApiList";
import { useDispatch } from "react-redux";
import { useLocation} from "react-router-dom";
import { setDistributor } from "../../../../../redux/Reducer/reducer";
import { useQuery } from "@tanstack/react-query";

export const convertOBJ_to_html_react_options = (retailer_list) => {
  
  return retailer_list.map((list) => {

    if(!list?.city) {
      list.city = ""
    } if(!list?.contact_person_name){
      list.contact_person_name = ""
    }
     if(!list?.contact_person_number){
      list.contact_person_number = "";
    }
    
    return {
      value: list?.id,
      label: (
        <>
          <div>
            <h3>{list?.name}</h3>

            {list?.city ? <div>City: {list?.city}</div> : null}
            {list?.contact_person_name ? (
              <div>Name: {list?.contact_person_name}</div>
            ) : null}
            {list?.contact_person_number ? (
              <div>Contact: {list?.contact_person_number}</div>
            ) : null}
          </div>
        </>
      ),
      // label: list?.name+"\n"+"("+list?.city+")"+"\n"+list?.contact_person_name+"\n"+list?.contact_person_number,
      

      // label: `${list?.name} ${list?.city ? `(${list?.city})` : ''} ${list?.contact_person_name} ${list?.contact_person_number}`,
      
      text: list?.name,
      // eslint-disable-next-line no-dupe-keys
      value: list?.id,
    };
  });
};

function DropdownSelect({
  paramsData,
  paramsDataIndex,
  Controller,
  control,
  setValue,
  watch,
  disabled,
  order_data,
  new_retailer_id = "",
  set_new_retailer_id = () => {}
}) {
  const dispatch = useDispatch();
  const distributor = watch("distributor")
  
  const [distributorOptions, setDistributorOptions] = useState([]);
  // const [retailerOptions, setRetailerOptions] = useState(() => []);
  

  //const distributor_list = useSelector(getDistributor);

  const location = useLocation()

let searchParams = Object.fromEntries(
  new URLSearchParams(location.search)
)

  const fetch_distributor_list = async () => {
    const user_id = get_informationFromSessionID("userID");
    if (!user_id) return;
    const client_type =
      (user_id && get_userData_from_activeUser().client_type) || "1";
    const distributor_list_response = await fetch_data_from_api(
      `${ApiList.Finalize}/${ApiList.get_distributor}`,
      "get_distributor",
      {
        client_type,
        session_id: "",
      }
    );
    const distributor_list = createSelectArrayForReactSelect(
      distributor_list_response?.final_result,
      "name",
      "id"
    );
    dispatch(setDistributor(distributor_list_response?.final_result || []));
    setDistributorOptions(distributor_list);
    
  };

  const user_id = get_informationFromSessionID("userID");
  const client_type =
      (user_id && get_userData_from_activeUser().client_type) || "1";

  const {data: retailerOptions} = useQuery({
    queryKey: ["get_retailer", distributor?.value, searchParams?.distributor_id],
    queryFn: () =>
      fetch_data_from_api_from_query(`${ApiList.Finalize}/${ApiList.get_retailer}`, "get_retailer", 
        {
          client_type,
          session_id: "",
          distributor_client_master_id: distributor?.value || "",
        }
    ).then(retailer_list_response => {
      if(retailer_list_response){
        if(disabled){
          if(order_data?.retailer){
            return[{label: order_data?.retailer, value: order_data?.retailer}]
          }
          return [];
        }else{
          const htmlOption = convertOBJ_to_html_react_options(
            retailer_list_response?.final_result || []
          );
          
          return htmlOption
        }
       
      }
  
    }),
    initialData: [],
    // enabled: !!distributor?.value,
  });
  
  

  useEffect(() => {
    if (paramsData?.short_code === "distributor") {
      fetch_distributor_list();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams?.distributor_id]);

  // useEffect(() => {
  //   if (paramsData?.short_code === "retailer" && distributor_list.length > 0) {
  //     const distributor_client_master_id = distributor_list[0]?.id;
  //     fetch_retailer_list(distributor_client_master_id);
  //   }
  // }, [distributor_list]);

  useEffect(() => {
    if(!new_retailer_id || !Array.isArray(retailerOptions)) return
    const new_retailer = retailerOptions.find(retailer => retailer?.value === new_retailer_id)
    if(new_retailer){
      setValue("retailer", new_retailer)
      set_new_retailer_id("");
    }

  }, [new_retailer_id, retailerOptions])

  return (
    <>
      <ReactSelect
              disabled={disabled}
              options={
                paramsData?.short_code === "distributor"
                  ? distributorOptions
                  : retailerOptions
              }
             
              
              paramsDataIndex={paramsDataIndex}
              paramsData={paramsData}
              Controller={Controller}
              control={control}
              setValue={setValue}
              searchParams={searchParams}
            />
        
      
    </>
  );
}

export default DropdownSelect;
