import React from "react";
import styles from "../../CSS/HomeCSS/CardSection.module.css";
import "./categoryCardLayout.css";
import { Link } from "react-router-dom";
import { createSearchURLParams, get_property_settings } from "../Helper";

import { useNavigate } from "react-router-dom";

function SingleCardNew({
  img,
  cat_id,
  cat_name,
  product_count,
  hasSubcategory,
  setShowCategoryModal,
  layoutStyle = "minibgimg_nllefttitle_nlleftcount",
  hasImage,
  textColor,
  bgColor,
  categoryData
}) {
  const show_hide_category_counts_is_show =
    get_property_settings("show_hide_category_counts") === "show";
  const parent_category_from_is_static =
    get_property_settings("parent_category_from") === "static";
  // const parent_category_from_is_static = true
  let fullbgimg_centertitle_with_image =
    layoutStyle === "fullbgimg_centertitle_centercount" && hasImage
      ? "with_image"
      : "";
  // let fullbgimg_centertitle_with_image = true

  let subcategory_popup_on_the_homepage_on_web = get_property_settings("subcategory_popup_on_the_homepage_on_web")
  subcategory_popup_on_the_homepage_on_web = subcategory_popup_on_the_homepage_on_web === "Yes"

  const navigate = useNavigate();
  //if layoutStyle: fullbgimg_notitle_rightbottomcount and there is no image (is default image) then show only text  
  const only_show_text_is_default_image = img.includes('default_image') && layoutStyle === "fullbgimg_notitle_rightbottomcount";
 

  let {show_subcat} = categoryData || {}
  show_subcat = show_subcat === "Yes"
  return (
    <Link
      className={`${styles.ec_card} ${layoutStyle} ${fullbgimg_centertitle_with_image}`}
      to={
        parent_category_from_is_static
          ? "/"
          : createSearchURLParams("matrix-page", {
              cat_id,
              mode: "filter",
              page: 0,
            })
      }
      onClick={(e) => {
        e.preventDefault();
        if (hasSubcategory && show_subcat) {
          navigate(createSearchURLParams(subcategory_popup_on_the_homepage_on_web ? "home" : "categories", { cat_id }));
          if(!subcategory_popup_on_the_homepage_on_web){
            setShowCategoryModal(true);
          }
        } else {
          navigate(
            createSearchURLParams("matrix-page", {
              cat_id,
              mode: "filter",
              page: 0,
            })
          );
          setShowCategoryModal(false);
        }
      }}
      style={{ boxShadow: "0px 1px 4px 2px rgba(0, 0, 0, 0.10)" }}
    >
      {layoutStyle === "fullbgimg_centertitle_centercount" &&
      fullbgimg_centertitle_with_image ? (
        <div
          className="ec-card-image category-home-card"
          style={{ backgroundImage: `url(${img})` }}
        >
          {!img.includes(".mp4") ? (
            <img src={img} alt="img" />
          ) : (
            <video
              muted
              playsInline
              src={img}
              controls={false}
              autoPlay
              loop
              preload="metadata"
              onLoadedData={(e) => {
                e.target.play();
              }}
              onLoad={(e) => {
                setTimeout(() => {
                  e.target.play();
                }, 1000);
              }}
              onClick={(e) => e.target.play()}
            />
          )}
        </div>
      ) : layoutStyle !== "fullbgimg_centertitle_centercount" ? (
        <div
          className="ec-card-image category-home-card"
          style={{ backgroundImage: !only_show_text_is_default_image ? `url(${img})` : 'none'}}
        >
          {!img.includes(".mp4") ? ( 
            only_show_text_is_default_image ? <span className="text-only">{cat_name}</span> : <img src={img} alt="img" className="cateogry-image"/>
          ) : (
            <video
            muted
            playsInline
              src={img}
              controls={false}
              autoPlay
              loop
              preload="metadata"
              onLoadedData={(e) => {
                e.target.play();
              }}
              onLoad={(e) => {
                setTimeout(() => {
                  e.target.play();
                }, 1000);
              }}
              onClick={(e) => e.target.play()}
            />
          )}
        </div>
      ) : null}
      <div className="ec-card-details" style={{ color: textColor, backgroundColor: bgColor }}>
        <h2 className="cat_name">{cat_name}</h2>
        {/* {show_hide_category_counts_is_show && <p>{is_plural_base_on_count(product_count) ? 'Designs' : 'Design'} {product_count} {is_plural_base_on_count(product_count) ? 'Pcs' : 'Pc'}</p>} */}
        {show_hide_category_counts_is_show && <p>{product_count} Designs</p>}
      </div>
    </Link>
  );
}

export default React.memo(SingleCardNew);
