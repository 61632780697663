import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setConstants } from "../../redux/Reducer/reducer";
import { NumericFormat } from "react-number-format";
import { ApiList } from "../../Api/ApiList";
import {
  ToastError,
  ToastSuccess,
  get_inCartWishList,
  get_informationFromSessionID,
  get_product_status_details,
  get_property_settings,
  set_productID_inCartWishList,
} from "../Helper";
import CartIcon from "../../assets/images/ic_matrix_cart@3x.png";
import Tag from "../Tag";
import { fetch_data_from_api } from "../../Api/APICalls";
import noImg from "../../assets/images/no_image_available.png";

const ProductNew = ({
  index,
  sort,
  item,
  saveStateToLocalStorage,
  display_card_for_product_base_section,
  brading_img_path
}) => {
  
  
  
  //General Settings
  const show_hide_design_master_new_arrival = get_property_settings("show_hide_design_master_new_arrival") === 'show';
  const new_arrival_badge_bg_color = get_property_settings("new_arrival_badge_bg_color")
  const new_arrival_badge_fg_color = get_property_settings("new_arrival_badge_fg_color")
  const showStepper = display_card_for_product_base_section ? "No" : get_property_settings("matrix_stepper_and_buttons")
  const showAddToCart = display_card_for_product_base_section ? "No" : get_property_settings("matrix_add_to_cart_button")
  const lessThanMoq = get_property_settings("allow_lessthan_moq")
  const enable_shortlist = get_property_settings("enable_shortlist") === "Yes"
  const matrix_shortlist_button_position = get_property_settings("matrix_shortlist_button_position")
  const matrix_cart_button_position = get_property_settings("matrix_cart_button_position")
  const showLabel = get_property_settings("show_center_label_matrix")
  const labelValue = get_property_settings("center_label_matrix_value")
  const CDN = get_property_settings("cdn")

  //Session Values
  const user_id = get_informationFromSessionID("userID");

  //Product Object
  const design_master_new_arrival = item?.design_master_new_arrival === '1';
  const centerLabel = showLabel ? item[labelValue] : "";
  const image=`${item.design_files ? `${CDN}${item.image_path}${item.design_files}` : noImg}`
  const label = item?.label
  const values = item?.values
  const minQty = item?.min_order_quantity
  const maxQty = item?.max_order_quantity
  const design_inventory = item?.design_inventory


  const [count, setCount] = useState(parseInt(minQty));
  const [itemIsInCart, setItemIsInCart] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("ADD TO CART");
  const product_status_details = get_product_status_details(item?.status_details)
  const location = useLocation();
  const dispatch = useDispatch();
  
  const searchParams = Object.fromEntries(
    new URLSearchParams(location.search)
  )
  

  const navigate = useNavigate()
  const cat_id = searchParams?.cat_id || 0;


  const Increment = () => {
    let add = parseInt(count) + 1;
    if (parseInt(maxQty) === 0) {
      setCount(add);
    } else if (add > parseInt(maxQty)) {
      ToastError(`Max ${maxQty} Qty Reached.`);
    } else {
      setCount(add);
    }

  };


  const Decrement = () => {
    const is_allow_lessthen_moq = lessThanMoq === "Yes";
   
    if(is_allow_lessthen_moq && parseInt(count) <= parseInt(minQty)) return;
    
    if(!itemIsInCart && parseInt(count) === 1) return

    if(parseInt(count) > 0){
      setCount(parseInt(count) - 1)
    }
    

  };
  
  const [inCartWishList, setInCartWishList] = useState(() => {

    return get_inCartWishList();
  });


  const set_ATC_button_label = () => {
    let property = "";
    let typeOfID = "";
    if(item.design_inventory === "design_master"){
        property = "in_cart_id_dm"
        typeOfID = "design_master_id"
    }else if(item.design_inventory === "inventory_master"){
        property = "in_cart_id_im"
        typeOfID = "inventory_master_id"
    }
    const inCartWishList = get_inCartWishList()
    
    if(!inCartWishList || Object.keys(inCartWishList).length === 0){
      setButtonLabel("ADD TO CART")
    }else{
      if(inCartWishList?.constructor === Object && inCartWishList[property].includes(item[typeOfID])){
        showStepper === "Yes" ? setButtonLabel("UPDATE") : setButtonLabel("REMOVE")
      }else{
        setButtonLabel("ADD TO CART")
      }
    }


  }

  

  const handle_cart_and_wishlist = async (typeOfCart, product_id, action) => {
      if (!user_id) {
        return navigate('/login')
      }

      // if((typeOfCart !== "cart" || typeOfCart !== "wishlist") || (action !== "add" || action !== "remove")){
      //   throw new Error("typeOfCart And Action must be required")
      // }

      const table = typeOfCart === "wishlist" ? "temp_wishlist" : "temp_cart";
      const defaultParams = {
          user_id,
          session_id: 0,
          table,
          design_inventory,
          product_id,
      }

      const params = action === "add" ? 
      {
        ...defaultParams,
        quantity: count,
        mode: "with_subproduct",
        type_of_user: "normal_user",
      } 
      :
      {
        ...defaultParams,
      }

      const API_Endpoint = action === 'add' ? `${ApiList.cart}/${ApiList.addToCartMatrix}` : `${ApiList.cart}/${ApiList.Delete_From_Cart_Wishlist_Matrix}`;
      const Proxy_Endpoint = action === 'add' ? 'add-to-cart' : 'remove-from-cart';
      const response = await fetch_data_from_api(API_Endpoint, Proxy_Endpoint, params)

      const is_Response_is_an_object = response?.constructor === Object;

      //if there is an error
      if(is_Response_is_an_object && response.ack === "0"){
        return ToastError(response?.msg)
      }else{
          if(is_Response_is_an_object){
            ToastSuccess(response?.msg);
            dispatch(setConstants({ count: response?.cart_total }));

            const typeOfID =  item.design_inventory === "design_master" ? "design_master_id" :
                              item.design_inventory === "inventory_master" ? "inventory_master_id" :
                              "";

            let updated_cart_wish_list = {}
            if (response?.action === 1 || response?.action === 2) {
                updated_cart_wish_list = set_productID_inCartWishList(typeOfCart, item.design_inventory, item[typeOfID], "add")
                
            }
            
            else if(!response?.action || response?.action === 3){
              updated_cart_wish_list = set_productID_inCartWishList(typeOfCart, item.design_inventory, item[typeOfID], "remove")
              if(typeOfCart === "cart"){
                  setItemIsInCart(false)
                  setCount(1)
                  set_ATC_button_label()
              }
            }

            setInCartWishList(updated_cart_wish_list)
            
          }

      }

  }

  //Return ethier "design_master_id" || "inventory_master_id"
  const Get_Product_ID_Property_Name = () => {
    return item?.design_inventory === "design_master" ? "design_master_id" :
    item?.design_inventory === "inventory_master" ? "inventory_master_id" : "";
  }


  
  const checkQty = () => {
    if (count === 0 || count === "undefined") {
      setCount(parseInt(minQty));
    }
  };
  useEffect(() => {
    set_ATC_button_label()
    const productID = Get_Product_ID_Property_Name()
    const inCartWishList = get_inCartWishList()
    if(!inCartWishList || Object.keys(inCartWishList).length === 0) {
      setItemIsInCart(false)
    }else{
      const inCartWishList_property_name = productID === "inventory_master_id" ? "in_cart_id_im" : "in_cart_id_dm"
      if(inCartWishList[inCartWishList_property_name].includes(item[productID])){
          setItemIsInCart(true)
      }else{
          setItemIsInCart(false)
      }
    }
    
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  
  return (

    <div
      className="ec-product-sbtn mt-auto mb-auto d-flex aling-items-center w-100 flex-column h-100 single-matrix-product"
      key={index}
    >
      
      <img src={brading_img_path} className="branding-img-ondummy" alt="brand-img"/>
      <span className="ec-pro-actions   px-3 w-100 d-flex justify-content-center ">
        <div
          className={`cart-top-container d-flex w-100 align-items-center actions-ico 
          ${matrix_cart_button_position === "topright"
              ? matrix_shortlist_button_position === "topright"
                ? "justify-content-between"
                : "justify-content-between"
              : matrix_shortlist_button_position === "topleft"
                ? "justify-content-start"
                : "flex-row-reverse justify-content-between "
            } `}
        >
          {item.design_inventory === "design_master" ? (
            <>
              {
                show_hide_design_master_new_arrival && design_master_new_arrival ? 
                <div className="card-top-label" style={{backgroundColor: new_arrival_badge_bg_color, color: new_arrival_badge_fg_color}}>
                  New Arrivals
                </div> : null
                
              }

              {enable_shortlist && (
                <>
                  {inCartWishList?.in_wishlist_id_dm?.length > 0 &&
                    inCartWishList?.in_wishlist_id_dm.includes(
                      item.design_master_id
                    ) ? (
                    
                    <div>
                      
                      <div
                        className=" whishlist-selected whishlist-circle bg-transparent"
                        onClick={(e) => {
                          const productID = Get_Product_ID_Property_Name()
                          handle_cart_and_wishlist("wishlist", item[productID], "remove")
                        }}
                      >
                        <i className="fa-solid fa-heart fa-lg   px-2 py-3"></i>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {/* <img
                        className="mx-1 whishlist matrix-icons "
                        src={ColoredShortListIcon}
                        onClick={(e) => {
                          handleWhishlist(e, item, "add");
                        }}
                      /> */}

                      <div
                        className="  whishlist-circle bg-transparent"
                        onClick={(e) => {
                          const productID = Get_Product_ID_Property_Name()
                          handle_cart_and_wishlist("wishlist", item[productID], "add")
                        }}
                      >
                        <i className="fa-solid fa-heart fa-lg px-2 py-3 "></i>
                      </div>
                    </div>
                  )}
                </>
              )}

              {inCartWishList?.in_cart_id_dm?.length > 0 &&
                inCartWishList?.in_cart_id_dm?.includes(
                  item.design_master_id
                ) && (
                  <div className="cartIcon">
                    <img className="mx-1 matrix-icons" src={CartIcon} alt="" />
                    
                  </div>
                )}
            </>
          ) : (
            ""
          )}
          {item.design_inventory === "inventory_master" ? (
            <>

              {enable_shortlist && (
                <>
                  {inCartWishList?.in_wishlist_id_im?.length > 0 &&
                    inCartWishList?.in_wishlist_id_im.includes(
                      item.inventory_master_id
                    ) ? (
                    <div
                      className=" whishlist-selected whishlist-circle bg-transparent"
                      onClick={(e) => {
                        const productID = Get_Product_ID_Property_Name()
                        handle_cart_and_wishlist("wishlist", item[productID], "remove")
                      }}
                    >
                      <i className="fa-solid fa-heart fa-lg px-2 py-3"></i>
                    </div>
                  ) : (
                    <div
                      className="  whishlist-circle bg-transparent"
                      onClick={(e) => {
                        const productID = Get_Product_ID_Property_Name()
                        handle_cart_and_wishlist("wishlist", item[productID], "add")
                      }}
                    >
                      <i className="fa-solid fa-heart fa-lg px-2 py-3 "></i>
                    </div>
                  )}
                </>
              )}
              {
                inCartWishList?.in_cart_id_im?.length > 0 &&
                inCartWishList?.in_cart_id_im?.includes(
                  item.inventory_master_id
                ) && (
                  <div>
                    <img src={CartIcon} alt="" />
                   
                  </div>
                )
                
              }
              
            </>
          ) : (
            
            ""
          )}
        </div>
        
      </span>
      <div className="ec-product-image" style={{ backgroundImage: `url(${image})` }}>
        {product_status_details && <Tag {...product_status_details} />}
        {/* <a href="#" className="ec-image"> */}
        <Link
          to={
            location.pathname.includes('home') ? `/product-page/${index}?mode=homescreen&product_id=${item.design_master_id}&sort=${sort}&cat_id=${cat_id}` : `/product-page/${index}?sort=${sort}&cat_id=${cat_id}`
          }
          state={location}
          onClick={() => {
            saveStateToLocalStorage();
            
          }}
        >
          
          { }
          <img
            className="pic-1"
            src={image}
            alt="pic"
          />
          
        </Link>

        
      </div>
      <div className="ec-product-body mt-auto">
        
        {centerLabel ? <b className="product-name"><span>{centerLabel}</span></b> : null}

        {label &&
          label.length >= 0 &&
          label.map((item, i) => {
            return (
              <div key={i}>
                <b>
                  {" "}
                  <span>{item}</span>
                </b>{" "}
                :<span> {values[i]}</span>

              </div>
            );
          })}
        <div className="stepper">
          
          {showStepper !== "No" && (
            <div className="qty-plus-minus">
              <div className="dec ec_qtybtn" onClick={Decrement}>
                -
              </div>
              
              <NumericFormat
                className="qty-input"
                onValueChange={(values) => {
                  
                  const { floatValue } = values;
                  if (maxQty === 0) {
                    setCount(floatValue);
                  } else if (
                    !isNaN(floatValue) &&
                    floatValue >= minQty &&
                    floatValue <= maxQty
                  ) {
                    setCount(floatValue);
                  } else {
                    if (floatValue > maxQty) {
                      setCount(maxQty);
                      ToastError(`Max ${maxQty} Qty Reached.`);
                    }
                  }
                }}
                value={count}
                onBlur={checkQty}
                isAllowed={(inputObj) => {
                  
                  const { value } = inputObj;
                  if (value === "") {
                    return true;
                  }
                  const numericValue = parseFloat(value);
                  if (numericValue === 0 && !itemIsInCart) {
                    return false;
                  }
                  return maxQty === 0
                    ? true
                    : numericValue > maxQty
                      ? false
                      : true;
                }}
                allowNegative={false}
                prefix=""
                placeholder="qty"
              />
              
              <div className="inc ec_qtybtn" onClick={Increment}>
                +
              </div>
            </div>
          )}
        </div>

        {showAddToCart !== "No" && (
          <div className="ec-product-button-group">
            {/* <a className="ec-product-like-icon" href="#"><i className="fi-rr-heart"></i></a> */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className={
                itemIsInCart
                  ? "ec-add-to-cart mt-2 update-button"
                  : "ec-add-to-cart mt-2 add-to-cart-button"
              }
              id="add-to-cart"
              onClick={async () => {
                const productID = Get_Product_ID_Property_Name()
                setItemIsInCart(true)
                const action = buttonLabel === "UPDATE" || buttonLabel === "ADD TO CART" ? "add" : "remove"
                await handle_cart_and_wishlist("cart", item[productID], action)
                set_ATC_button_label()
              }}
            >
              {buttonLabel}
            </a>
            {/* <a className="ec-product-compare-icon" href="#"><i className="fi fi-rr-arrows-repeat"></i></a> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductNew;
