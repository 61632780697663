import { Controller, useFormContext, useWatch } from "react-hook-form";
import Select from "react-select";
import { createSelectArrayForReactSelect, get_property_settings } from "../../../../Component/Helper";
import { useLayoutEffect } from "react";


function DistributorDropDown({field, data}) {
  const {field_name, short_code, required_field, control_value, default_select} = field || {}
  const { control, formState: {errors}} = useFormContext() 
  const primary_BG_color = get_property_settings("btn_primary_color");

 
  return (
    <div className="form-input-group">
        <label htmlFor={short_code}>
          <span>{field_name}</span>
          {required_field === "1" && <span className="required">*</span>}
        </label>
        <Controller
        control={control}
        name={short_code}
        rules={{
          required:{
            value: required_field === "1",
            message: `${field_name} is required`
          }
        }}
        // defaultValue={default_selected}
        render={({ field }) => (
          <Select
            isSearchable={true}
            className="react-select-container r-container"
            classNamePrefix="react-select r-select"
            id={short_code}
            options={data}
            {...field}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25:
                  (primary_BG_color &&
                    primary_BG_color
                      .replace("rgb", "rgba")
                      .replace(")", ",0.2)")) ||
                  "inherit",
                primary: primary_BG_color,
              },
            })}
          />
        )}
        />
        {errors[short_code] && <p className="error-input">{errors[short_code]?.message}</p>}
      </div>
  )
}

export default DistributorDropDown
