import React from "react";
import ProductDetails from "./components/ProductDetails";

import TotalCost from "./components/TotalCost";
import MaterialDetails from "./components/MaterialDetails";
import LabourDetails from "./components/LabourDetails";
import OtherChargesDetails from "./components/OtherChargesDetails";
import QtyRemark from "../FSVInputs/components/Qty_Remark/Qty_Remark";
import {
  get_bulk_settings,
  
  get_informationFromSessionID,
  
  get_userData_from_activeUser,
} from "../../../../Component/Helper";
import { useSelector } from "react-redux";
import {
  get_fsv_products_calc,
  get_fsv_selected_product,
} from "../../../../redux/Reducer/reducer";



function FSVDetails({ master }) {
  const loginData = get_userData_from_activeUser();
  const user_id = get_informationFromSessionID("userID")

  const show_wastage = !user_id ? "0" : loginData?.show_wastage;
  const show_material = !user_id ? "0" : loginData?.show_material;
  const show_total_amt = !user_id ? "0" : loginData?.show_total_amt;
  
  const show_other_charges_details = loginData?.show_other_charges_details;
  let { show_quantity_stepper_in_fsv_dm, show_qty_remarks_inventory, qty_remarks_position_in_fsv } =
    get_bulk_settings([
      "show_quantity_stepper_in_fsv_dm",
      "show_qty_remarks_inventory",
      "qty_remarks_position_in_fsv"
    ]);
  show_quantity_stepper_in_fsv_dm = show_quantity_stepper_in_fsv_dm === "Yes";
  show_qty_remarks_inventory = show_qty_remarks_inventory === "Yes";
 

  const selectedProduct = useSelector(get_fsv_selected_product);
  const product_calc = useSelector(get_fsv_products_calc);

  const selected_product_calc = product_calc[selectedProduct?.category_name];

//   qty_remarks_position_in_fsv === "below_subproduct" && 
// (master === "design_master" && show_quantity_stepper_in_fsv_dm) ||
// (master === "inventory_master" && show_qty_remarks_inventory)

  
  
 
  return (
    <>
    
      <ProductDetails
        selectedProduct={selectedProduct}
        selected_product_calc={selected_product_calc}
      />

      {show_material === "1" ? (
        <MaterialDetails data={selectedProduct} />
      ) : null}

      {show_wastage === "1" ? (
        <LabourDetails
          data={selectedProduct}
          calc_making_charge={selected_product_calc?.calc_making_charge}
          calc_wasteage={selected_product_calc?.calc_wasteage}
        />
      ) : null}

      {show_other_charges_details === "1" ? (
        <OtherChargesDetails
          data={selectedProduct}
          calc_GST_amt={selected_product_calc?.calc_GST_amt}
        />
      ) : null}

      {selectedProduct?.label && selectedProduct?.label.length > 0
        ? selectedProduct?.label.map((item, i) => {
            return (
              <React.Fragment key={i}>
                {selectedProduct.short_code[i] === "total_cost" && show_total_amt === "1" && (
                  <TotalCost
                    item={item}
                    calc_total_after_tax_amt={
                      selected_product_calc?.calc_total_after_tax_amt
                    }
                    data={selectedProduct}
                    index={i}
                  />
                )}
              </React.Fragment>
            );
          })
        : null}

      {qty_remarks_position_in_fsv === "below_subproduct" && 
      ((master === "design_master" && show_quantity_stepper_in_fsv_dm) ||
      (master === "inventory_master" && show_qty_remarks_inventory)) ? (
        <QtyRemark clsName={"d-block"} />
      ) : null}

      {selectedProduct?.product_foot_note && <h3 style={{textAlign: "left", lineHeight: "3rem"}}>{selectedProduct?.product_foot_note}</h3>}
    </>
  );
}

export default FSVDetails;
