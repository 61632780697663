import React, { useEffect, useState } from "react";
import Loader from "../../Component/Loader";
import { ApiList } from "../../Api/ApiList";
import {
  get_informationFromSessionID,
  get_property_settings,
  ToastSuccess,
} from "../../Component/Helper";
import { useDispatch, useSelector } from "react-redux";
import {
  get_inCartWishList,
  set_inCartWishList,
  setCart,
  setConstants,
} from "../../redux/Reducer/reducer";
import { useLocation } from "react-router-dom";
import CartProductSection from "./Components/CartProductSection";
import { fetch_data_from_api } from "../../Api/APICalls";
import CartGroupBySection from "./Components/CartGroupBySection";
import CartFooter from "./Components/CartFooter";
import useLoginStatus from "../../Hooks/useLoginStatus";

const CartNew = () => {
  const location = useLocation();
  const type_of_cart = location.pathname.includes("cart")
    ? "temp_cart"
    : "temp_wishlist";

  const [cartData, setCartData] = useState([]);
  // const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [groupByData, setGroupByData] = useState([]);
  const [group_in_cart, setGroup_in_cart] = useState(null);
  const [group_in_wishlist, setGroup_in_wishlist] = useState(null);
  const [show, setShow] = useState(false);
  const [windowResolution, setWindowResolution] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const cart_group_by_bar_show_hide = get_property_settings(
    "cart_group_by_bar_show_hide"
  );

  const cart_group_based_on = get_property_settings("cart_group_based_on")

  const inCartWishList = useSelector(get_inCartWishList);

  const userID = get_informationFromSessionID("userID");
  const dispatch = useDispatch();

  const constants = useSelector((state) => state?.reducer?.constants?.count);

  //set min-height 100% to avoid vertical scroll to 0 and 1 product
  // if (navigator.userAgent.match(/Android/i)
  //        || navigator.userAgent.match(/webOS/i)
  //        || navigator.userAgent.match(/iPhone/i)
  //        || navigator.userAgent.match(/iPad/i)
  //        || navigator.userAgent.match(/iPod/i)
  //        || navigator.userAgent.match(/BlackBerry/i)
  //        || navigator.userAgent.match(/Windows Phone/i))
  // {

  // }else{

  //   document.body.setAttribute("style", `zoom: ${(1 / window.devicePixelRatio).toFixed(2)}; min-height: 100%`)
  //   document.body.style.setProperty("--device-pixel-ratio-height", `${window.devicePixelRatio * 100}vh`)
  //   document.body.dataset.deviceRatio = window.devicePixelRatio;
  // }
  useLoginStatus()
  async function fetch_groupBy_and_cartData(stopLoader) {
    !stopLoader && setLoading(true);

    const groupBy_and_cartData_response = await fetch_data_from_api(
      `${ApiList.cart}/${ApiList.fetchCartListGrouped}`,
      "cart-list",
      {
        session_id: 0,
        user_id: userID,
        table: type_of_cart,
        type:
          type_of_cart === "temp_wishlist" ? group_in_wishlist || cart_group_based_on : group_in_cart || cart_group_based_on,
      }
    );

    if (groupBy_and_cartData_response?.constructor === Object) {
      setGroupByData(groupBy_and_cartData_response?.group_by);

      //Set default status base on API
      if(type_of_cart === "temp_wishlist" && !group_in_wishlist){
        const selected_group_by = groupBy_and_cartData_response?.group_by?.find(groupBy => groupBy.is_selected === "1")?.short_code;
        setGroup_in_wishlist(selected_group_by || "all")
      }else if(!group_in_cart){
        
        const selected_group_by = groupBy_and_cartData_response?.group_by?.find(groupBy => groupBy.is_selected === "1")?.short_code;
        setGroup_in_cart(selected_group_by || "all")
      }

      if (groupBy_and_cartData_response?.cart_data) {
        setCartData(groupBy_and_cartData_response?.cart_data);
        const cartCalcData = calculate_total_gwt_nwt(
          groupBy_and_cartData_response?.cart_data
        );
        dispatch(setCart(cartCalcData));
      } else {
        setCartData([]);
      }
    }

    !stopLoader && setLoading(false);
  }

  function calculate_total_gwt_nwt(cart_data) {
    const required_property_of_cart_data_for_calculation = [
      "total_gross_wt",
      "total_net_wt",
      
    ];
    const cartStoreData = {
      total_gross_wt: 0,
      total_net_wt: 0,
      
    };

    required_property_of_cart_data_for_calculation.forEach((property) => {
      cart_data.forEach((data) => {

        cartStoreData[property] += parseFloat(data[property]);

        // if (property === "cart_count") {
        //   cartStoreData.total_quantity += parseFloat(data[property]);
        //   // cartStoreData.total_quantity += 8;
        // } else {
        //   cartStoreData[property] += parseFloat(data[property]);
        // }
      });
    });
    
    return cartStoreData;
  }

  const emptyCart = async () => {
    setLoading(true);
    const emptyCart_response = await fetch_data_from_api(
      `${ApiList.cart}/${ApiList.clearCart}`,
      "clear-cart",
      {
        session_id: 0,
        user_id: userID,
        table: type_of_cart,
        type:
          type_of_cart === "temp_wishlist" ? group_in_wishlist : group_in_cart,
      }
    );

    if (emptyCart_response?.constructor === Object) {
      dispatch(
        setConstants({
          count: {
            ...constants,
            count: type_of_cart === "temp_cart" ? 0 : constants.count,
            shortlist_count:
              type_of_cart === "temp_wishlist" ? 0 : constants.shortlist_count,
          },
        })
      );

      dispatch(
        set_inCartWishList({
          ...inCartWishList,
          in_cart_id_dm: type_of_cart === "temp_cart" ? [] : inCartWishList.in_cart_id_dm,
          in_wishlist_id_dm: type_of_cart === "temp_wishlist" ? [] : inCartWishList.in_wishlist_id_dm,
          in_cart_id_im: type_of_cart === "temp_cart" ? [] : inCartWishList.in_cart_id_im,
          in_wishlist_id_im: type_of_cart === "temp_wishlist" ? [] : inCartWishList.in_wishlist_id_im,
         })
      );

      //re-fetch data again
      await fetch_groupBy_and_cartData();
      setLoading(false);
      setShow(false);

      ToastSuccess(emptyCart_response?.msg);
    }
  };

  //push data in dm_id, im_id
  function separate_ids_with_master(dm_ids_arr, im_ids_arr, cartData) {
    cartData.forEach((data) => {
      data.product_details.forEach((product) => {
        if (product?.which_master === "design_master") {
          dm_ids_arr.push(product?.design_master_id);
        } else if (product?.which_master === "inventory_master") {
          im_ids_arr.push(product?.inventory_master_id);
        }
      });
    });
  }

  const moveAll = async () => {
    setLoading(true);

    const transfer_all_product_response = await fetch_data_from_api(
      `${ApiList.cart}/${ApiList.transferAllProducts}`,
      "transfer-all-products",
      {
        table_from: type_of_cart,
        table_to:
          type_of_cart === "temp_wishlist" ? "temp_cart" : "temp_wishlist",
        type:
          type_of_cart === "temp_wishlist" ? group_in_wishlist : group_in_cart,
        user_id: userID,
      }
    );

    if (transfer_all_product_response.constructor === Object) {
      dispatch(
        setConstants({
          count: transfer_all_product_response?.cart_total,
        })
      );

      if (transfer_all_product_response?.data?.ack === "1") {
        const transferTo =
          type_of_cart === "temp_wishlist" ? "cart" : "wishlist";
        let dm_ids = []; //design master id's
        let im_ids = []; //inventory master id's
        //for update dm_ids and im_ids
        separate_ids_with_master(dm_ids, im_ids, cartData);

        //Update Local stoage
        
        
        if (transferTo === "cart") {
          dispatch(set_inCartWishList({
            ...inCartWishList,
            in_cart_id_dm: [...inCartWishList.in_cart_id_dm, ...dm_ids],
            in_cart_id_im: [...inCartWishList.in_cart_id_im, ...im_ids],
            in_wishlist_id_dm: [],
            in_wishlist_id_im: [],
          }));
        } else if (transferTo === "wishlist") {
          dispatch(set_inCartWishList({
            ...inCartWishList,
            in_wishlist_id_dm: [
              ...inCartWishList.in_wishlist_id_dm,
              ...dm_ids,
            ],
            in_wishlist_id_im: [
              ...inCartWishList.in_wishlist_id_im,
              ...im_ids,
            ],
            in_cart_id_dm: [],
            in_cart_id_im: [],
          }));
        }
      }

      await fetch_groupBy_and_cartData();
      ToastSuccess(transfer_all_product_response?.msg);
    }
  };

  useEffect(() => {
    fetch_groupBy_and_cartData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group_in_cart, group_in_wishlist, type_of_cart]);

  const updateWindowResolution = () => {
    // Update view and thumb position based on window width

    setWindowResolution({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowResolution);
    return () => {
      window.removeEventListener("resize", updateWindowResolution);
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [type_of_cart]);
  
  return (
    <section className="position-relative cart-page-container">
      {/* {Array.isArray(groupByData) && groupByData.length > 1 ? ( */}
      {Array.isArray(groupByData) ? (
        <div
          className={`${
            type_of_cart === "temp_wishlist" ? "cart-page" : "shortlist-page"
          } cart-container`}
        >
          {cart_group_by_bar_show_hide === "show" ? (
            <CartGroupBySection
              groupByData={groupByData}
              windowResolution={windowResolution}
              type_of_cart={type_of_cart}
              setGroup_in_wishlist={setGroup_in_wishlist}
              setGroup_in_cart={setGroup_in_cart}
            />
          ) : null}
        </div>
      ) : null}

      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <CartProductSection
          cartData={cartData}
          group_in_cart={group_in_cart}
          group_in_wishlist={group_in_wishlist}
          fetchData={fetch_groupBy_and_cartData}
          show={show}
          setShow={setShow}
          emptyCart={emptyCart}
          type_of_cart={type_of_cart}
        />
      )}

      {cartData && cartData.length > 0 && (
        <CartFooter
          moveAll={moveAll}
          type_of_cart={type_of_cart}
          cartData={cartData}
        />
      )}
    </section>
  );
};

export default CartNew;
