import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import MUIModal from '../../../../../Component/MUIModal/MUIModal'

function Join_Video_Call_Modal(_, ref) {

    const [open_MUI_modal, set_open_MUI_modal] = useState(false);
    const handle_close_MUI_modal = useCallback(
      () => set_open_MUI_modal(false),
      []
    );
  
    const handle_open_MUI_modal = () => set_open_MUI_modal(true);
  
    useImperativeHandle(ref, () => ({
      open_modal: () => handle_open_MUI_modal(),
    }));

  return (
    <MUIModal
          className="join-video-call-modal"
          open_MUI_modal={open_MUI_modal}
          handle_close_MUI_modal={handle_close_MUI_modal}
          style={{ p: 0, width: "min(320px, 90%)" }}
    >
        sdsdadas
    </MUIModal>
  )
}

export default forwardRef(Join_Video_Call_Modal)
