import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import MUIModal from '../../../../../Component/MUIModal/MUIModal';
import "./FSVDuplicateProductModal.css";
import { IoMdClose } from "react-icons/io";

function FSVDuplicateProductModal({handle_allow_duplicate_product_in_cart}, ref) {
      const [open_MUI_modal, set_open_MUI_modal] = useState(false);
      const handle_close_MUI_modal = useCallback(
        () => {
          set_open_MUI_modal(false)
          
        },
        []
      );
    
      const handle_open_MUI_modal = () => set_open_MUI_modal(true);
    
      useImperativeHandle(ref, () => ({
        open_modal: () => handle_open_MUI_modal(),
      }));

      const handle_allow_duplicate_product = () => {
        handle_allow_duplicate_product_in_cart(true)
        handle_close_MUI_modal()
      }

  return (
    <MUIModal
            className="duplicate-product-warning-modal"
            open_MUI_modal={open_MUI_modal}
            handle_close_MUI_modal={handle_close_MUI_modal}
            style={{ p: 0,  width: "min(375px, 90%)" }}
          >
            <div className='duplicate-product-warning-modal-container'>
              <header>
                  <h3>Alert</h3>
                  {/* <IoMdClose className='close-icon'/> */}
              </header>

              <section className='product-warning-modal-body'>
                  <p>This Product is already added in Cart. Are you sure you want to add this product again ?</p>
                  <footer>
                    <button type='button' onClick={handle_allow_duplicate_product}>Continue</button>
                    <button type="button" className='outline-btn' onClick={handle_close_MUI_modal}>Cancel</button>
                  </footer>
              </section>
            </div>
    </MUIModal>
  )
}

export default forwardRef(FSVDuplicateProductModal)
