import { get_informationFromSessionID } from "../../../Helper"
import { shankesh_testimonial_filterd_data } from "./SHANKESH/shankesh_testimonial_data"
import ShankeshTestmonialCard from "./SHANKESH/ShankeshTestmonialCard"

export const get_Custom_Testimonial_Data = () => {
    const company_code = get_informationFromSessionID("companyCode")
    const decrypted_company_code = atob(company_code)
    
    
        switch (decrypted_company_code) {
          case "SHANKESH" : return {data: shankesh_testimonial_filterd_data, COMPONENT: ShankeshTestmonialCard};
          default: return null
        }
    
}