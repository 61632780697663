import React from 'react'
import { getCustomValue } from '../../../Custom/CompanyList'
import { CUSTOMIZIED_COMPANY_PROPERTY } from '../../../Custom/CompanyCustomization'
import { get_bulk_settings } from '../../../Component/Helper'

export default function VideoCallingBanner() {
    const { has_video_call_banner } = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.video_calling) || {}
    const {cdn, vc_banner_ipad} = get_bulk_settings(["cdn", "vc_banner_ipad"])
    const image_url = `${cdn}uploads/other_banner_image/ipad/${vc_banner_ipad}`
  return (
    <div className='video-calling-banner'>
      {
        !has_video_call_banner ? (
        <div className="video-calling-image-wrapper">
            <img src={image_url} alt="video-calling-banner"/>
        </div>
        ) : null
      }
    </div>
  )
}
