import AboutUsSection from '../About_Us_Section';



function DREAMSBANGLES_Home_About_Us_Comp() {
  return (
    <AboutUsSection title="About Us">
        <div className="igje-about-comp-info">
         <p>Our identity lies in the essence that dreams represent, which is more than just a word or name. Dreams embody the aura that our products emanate.</p> 

<p>Dreams Bangles is known for its ethical standards, commitment to excellence, consistent product quality, and transparent business practices. With years of expertise as a manufacturer, we constantly innovate our products and styles to match our expertise with evolving technology. </p>

<p>Our aim is to work in synergy with our clients and embrace their demands for better designs every day.</p>
<p>With the introduction of this website, we want to bring an enormous product collection to your fingertips for wholesale purchase. Browse through our products exclusively on our website</p>
        </div>
    </AboutUsSection>
  )
}

export default DREAMSBANGLES_Home_About_Us_Comp