

import dayjs from "dayjs";
import {
  get_informationFromSessionID,
  get_property_settings,
  get_userData_from_activeUser,
} from "../../Component/Helper";
import aws_get_image from "../../aws/aws_get_image";

export function custom_order_save_images(data) {
  data.image_name = [];
  return data;
}

export function getKamImageUploadPath() {
  let jewelkam_bucket_name = get_property_settings("jewelkam_bucket_name");
  const unified_jewelkam_bucket =
    get_property_settings("unified_jewelkam_bucket") === "1";
  let decrypted_companyCode = atob(get_informationFromSessionID("companyCode"));
  decrypted_companyCode = decrypted_companyCode.toLowerCase();

  if (unified_jewelkam_bucket) {
    jewelkam_bucket_name += `/${decrypted_companyCode}`;
  }

  return jewelkam_bucket_name;
}

export function get_plain_values_from_custom_order (obj, index, images, design_by_images) {
    let modifiedObj = {
      device_type: "web",

    };
       
        for (let key in obj) {
            if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                // Rule 1: If the object has `label` and `value` properties, set it to only the `value` property
                if ('value' in obj[key] && 'label' in obj[key]) {
                    modifiedObj[key] = obj[key].value;
                } else {
                    modifiedObj[key] = obj[key]; // Preserve the object if it doesn't match the specific structure
                }
            } else if (Array.isArray(obj[key]) && key === "image_name") {


              //By Design No. Image array
              

              if(Array.isArray(design_by_images) && design_by_images.length > 0){
                const design_no = obj?.design_no
                
                if(design_no){
                  modifiedObj[key] = design_by_images.filter(images => images?.design_no === design_no).map(img => `web_${img.image_name.replace(/\s/g, "-")}@@`).join('');
                  modifiedObj["cat_id"] = "";
                  modifiedObj["oms_app_add_by_design_no"] = design_no;
                }
               
                
              }
                
                
                if(Array.isArray(images) && images.length > 0){
                  if(images[index]){
                    if(modifiedObj[key]){

                      modifiedObj[key] += images[index].map(image => `web_${image.file.name.replace(/\s/g, "-")}@@`).join('');
                    }else{
                      modifiedObj[key] = images[index].map(image => `web_${image.file.name.replace(/\s/g, "-")}@@`).join('');

                    }
                  }
                }

               
               
            } else if(key.includes("_custom_input") && obj[key]) { //
                modifiedObj[key.replace("_custom_input", "")] = obj[key]
            } else if(key === "client_due_date" && obj[key]){
              modifiedObj[key] = dayjs(obj[key]).format('DD-MM-YYYY')
            } else if(Array.isArray(obj[key])){
              modifiedObj[key] = obj[key].join(",")
            }
            
            else {
                // Rule 3: Preserve string values as they are
                modifiedObj[key] = obj[key] ? obj[key] : "";
            }
        }

        return modifiedObj;
}


function modifyArrayObjects(array, images, design_by_images) {
  
    return array?.map((obj, index) => {
        return get_plain_values_from_custom_order(obj, index, images, design_by_images)
    });
}

export function getKamOrderParams(formData, images, design_by_images) {
//   Size: "2.5";
//   bng_mm: "1";
//   bng_mm_custom_input: "10";
//   bng_purity: "22 KT";
//   cat_id: "1";
//   client_due_date: "2024-06-01";
//   from_wt: "2";
//   image_name: [];
//   narration: "test 1";
//   quantity: "1";
//   to_wt: "3";
//   unit_of_measurement: "pc";




let items = [...formData.items]

   items = modifyArrayObjects(items, images, design_by_images)

   

  const user_data = get_userData_from_activeUser();
  const default_params = {
    client_master_id: user_data?.client_id || "",
    device_type: "web",
    client_type: user_data?.client_type || "",
  };

//   formData.items = encodeURI(
//     JSON.stringify(items)
//   )
//     .replace(/\(/g, "%28")
//     .replace(/\)/g, "%29")
//     .replace(/%5B/g, "[")
//     .replace(/%5D/g, "]");

formData.items = items

  return {Add_Multiple_Items: encodeURI(JSON.stringify({...default_params, ...formData}))}
}

export async function GetBlobFormImageURL(credentials, design_by_images) {
  const getImageURL = design_by_images.map(img => ({image_name: img.image_name, image_path: img.image_path}))
  return getImageURL.map(async (img) => {
      // const response = await fetch(img?.image_path, {method: "GET", mode: "no-cors"});
      // const blob = await response.blob();
      // const file = new File([blob], img?.image_name, { type: blob.type });
      const file = await aws_get_image(credentials, img?.image_path, img?.image_name)
      
      return {file}
  })
}

export function get_plain_values_from_form_data (formData) {
  const data = {}
  for(const key in formData){
    data[key] = formData[key]?.value || formData[key];
  }

  return data
}


export function oms_wight_calculation (product_category_data, size_chart, watch) {
  const ogWeight = product_category_data.find(category_data => category_data.short_code.toLowerCase() === "single_wt")
  const ogPieces = product_category_data.find(category_data => category_data.short_code.toLowerCase() === "quantity")
  const ogSize = product_category_data.find(category_data => category_data.short_code.toLowerCase() === "size")
  
 
  const single_wt = watch(ogWeight?.short_code) || ogWeight?.default_select;
  const pcs = watch(ogPieces?.short_code) || ogPieces?.default_select;
  let size = watch(ogSize?.short_code) || ogSize?.default_select;
  size = size?.value || size
  const selectedSizeDiameter = size_chart[size]
  
  const ogWeightNumber = parseFloat(ogWeight?.default_select)
  const ogPiecesNumber = parseFloat(ogPieces?.default_select)
  const ogSizeNumber = parseFloat(ogSize?.default_select)
  
  const newWeight = ((ogWeightNumber / ogPiecesNumber) / parseFloat(size_chart[ogSizeNumber])) * Number(pcs) * parseFloat(selectedSizeDiameter)
  
  return newWeight.toFixed(3)

}