import React, { useState } from 'react'
import MUITabs from '../../../../Component/MUITabs/MUITabs'
import Video_Call_Welcome_Screen from './video-call-welcome-screen'

export default function VideoCallingTabs() {
  const [selected_tab, set_selected_tab] = useState("upcoming")
  const handle_selected_tab = (value) => {
    set_selected_tab(value)
  }

  return (
    <div className='video-calling-tabs'>
      <MUITabs tabs={["Upcoming", "Past"]} defaultValue={selected_tab} getSelectedTab={handle_selected_tab} className='video-calling-tab-wrapper'>
        <Video_Call_Welcome_Screen />
      </MUITabs>
    </div>
  )
}
