import { useQuery, useQueryClient } from "@tanstack/react-query";
import "./AddRetailerForm.css";
import {
  fetch_data_from_api,
  fetch_data_from_api_from_query,
} from "../../Api/APICalls";
import { ApiList } from "../../Api/ApiList";
import Loader from "../Loader";
import { FormProvider, useForm } from "react-hook-form";
import TextField from "../InputFields/TextField/TextField";
import PhoneNumber from "../InputFields/PhoneNumber/PhoneNumber";
import CountryStateCity from "../InputFields/CountryStateCity/CountryStateCity";
import OTPModalComp from "../OTPModal/OTPModalComp";
import { useRef, useState } from "react";
import {
  createSearchURLParams,
  get_plain_value_from_inputs,
  get_property_settings,
  get_userData_from_activeUser,
  ToastError,
  ToastSuccess,
} from "../Helper";
import { useLocation, useNavigate } from "react-router-dom";
import CountryWiseGSTInput from "../InputFields/TextField/CountryWiseGSTInput";



function AddRetailerForm({ distributor_client_master_id, close_retailer_form_modal = () => {} }) {
  const add_retailer_response = useQuery({
    queryKey: ["retailer_form"],
    queryFn: () =>
      fetch_data_from_api_from_query(
        `${ApiList?.Finalize}/${ApiList?.add_retailer_get_parameters}`,
        "add_retailer_get_parameters"
      ),
  });

  const methods = useForm();
  const OTP_modal_ref = useRef(null);
  const [otpRes, setOtpRes] = useState("");
  const [otpResData, setOtpResData] = useState(null);
  const [modify_form_data, set_modify_form_data] = useState({});
  const location = useLocation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  function params_for_add_retailer() {
    if (!otpResData) return {};
    const client_type = get_userData_from_activeUser().client_type || "1";
    const client_id = get_userData_from_activeUser().client_id || "";

    const add_retailer_params = {
      mode: otpResData.mode,
      distributor_id: distributor_client_master_id || client_id,
    };

    if (client_type === "4") {
      if (otpResData?.mode === "new_retailer") {
        add_retailer_params.retailer_id = "";
      } else if (otpResData?.mode === "associate_retailer") {
        add_retailer_params.retailer_id = client_id;
      }
    } else {
      add_retailer_params.distributor_id = client_id;
      add_retailer_params.retailer_id = otpResData.client_id;
    }

    return { ...modify_form_data, ...add_retailer_params };
  }

  /* SUBMIT ADD RETAILER FORM */
  const onSubmit = async (data = null) => {
    const is_vaild = await methods.trigger()
    if(!is_vaild) return;
    const formData = data ? data : methods.getValues();

    /* MODIFY FORM DATA */
    const modifyFormData = {};
    for (let key in formData) {
      if (key === "mobile_no") {
        const { country_code, phoneNumber } = formData[key] || {};
        modifyFormData.country_code = `+${country_code}`;
        modifyFormData.mobile_no = phoneNumber.replace(country_code, "");
      } else {
        modifyFormData[key] = get_plain_value_from_inputs(formData[key]);
      }
    }
    modifyFormData["distributor_client_master_id"] =
      distributor_client_master_id;
    set_modify_form_data(modifyFormData);
    
    
    const add_retailer_check_sendOTP_response = await fetch_data_from_api(
      `${ApiList.Finalize}/${ApiList.add_retailer_check_sendOTP}`,
      "add_retailer_check_sendOTP",
      modifyFormData
    );
    
    if (add_retailer_check_sendOTP_response?.ack === "1") {
      OTP_modal_ref.current?.open_modal();
      setOtpResData(add_retailer_check_sendOTP_response);
      setOtpRes(add_retailer_check_sendOTP_response?.mobile_key);
    } else if (add_retailer_check_sendOTP_response?.ack === "0") {
      ToastError(
        add_retailer_check_sendOTP_response?.error || "Somthing went wrong"
      );
    }
  };

  async function add_retailer() {
    if (!otpResData) return {};
    const add_retailer_params = params_for_add_retailer();

    const add_retailer_response = await fetch_data_from_api(
      `${ApiList.Finalize}/${ApiList.add_retailer}`,
      "add_retailer",
      add_retailer_params
    );
    
    if (add_retailer_response?.ack === "1") {
      OTP_modal_ref.current?.close_modal();

      //Reset data
      methods.reset();
      setOtpRes("");
      set_modify_form_data({});

      const kam_module_title = get_property_settings("kam_module_title").replace(/\s/g, "-").toLowerCase();
      const oms_add_multiple_item_app = get_property_settings("oms_add_multiple_item_app") === "Yes"
      await queryClient.invalidateQueries({ queryKey: ['get_retailer'] })
      
      if(location.pathname.includes('checkout')){
          navigate(createSearchURLParams('checkout', {retailer_id: add_retailer_response?.final_result.id, distributor_id: add_retailer_params?.distributor_id}))
      }else if(location.pathname.includes(kam_module_title) && oms_add_multiple_item_app){
        navigate(createSearchURLParams(`${kam_module_title}/new-${kam_module_title}`, {retailer_id: add_retailer_response?.final_result.id, distributor_id: add_retailer_params?.distributor_id}))
      }
      ToastSuccess(add_retailer_response?.msg);
      close_retailer_form_modal(add_retailer_response?.final_result.id || "")
    } else {
      ToastError(add_retailer_response?.msg || "Somthing went wrong");
    }
  }

  const selected_country = methods.watch("country")

  

  return (
    <div className="add-retailer-form-container">
      <OTPModalComp
        ref={OTP_modal_ref}
        callbackFn={add_retailer}
        resendOTPFn={onSubmit}
        otpRes={otpRes}
        mobile_no={`+${methods.getValues()?.mobile_no?.phoneNumber}`}
      />
      {add_retailer_response.isLoading ? (
        <Loader isLoading={true} />
      ) : (
        <FormProvider {...methods}>
          <form
            className="add-retailer-form ui form-ui"
            // onSubmit={methods.handleSubmit(onSubmit)}
          >
            <section className="form-fields">
              {add_retailer_response.data?.map((formField) => {
                const { control_type, short_code } = formField || {};
                if(control_type === "Textfield" && short_code === "gst_number" && selected_country?.cin_required === "No") {
                  return null;
                }else if (control_type === "Textfield" ) {
                  return <TextField fields={formField} key={formField?.short_code}/>;
                } else if (control_type === "Telephone") {
                  return <PhoneNumber fields={formField}  key={formField?.short_code}/>;
                } else if (
                  control_type === "Country" ||
                  control_type === "State" ||
                  control_type === "City"
                ) {
                  if (control_type === "State" || control_type === "City")
                    return null;
                  const state_field = add_retailer_response.data?.find(
                    (data) => data?.control_type === "State"
                  );
                  const city_field = add_retailer_response.data?.find(
                    (data) => data?.control_type === "City"
                  );
                  return (
                    <CountryStateCity
                      key={short_code}
                      fields={{ ...formField, country_id: "101" }}
                      state_field={state_field}
                      city_field={city_field}
                      
                    />
                  );
                } 
                

                return null;
              })}

              {
                selected_country && selected_country?.cin_label ? <CountryWiseGSTInput fields={{...selected_country}} /> : null
              }
            </section>

            <footer>
              <button type="button" className="add-client-btn" onClick={() => onSubmit()}>
                Add Client
              </button>
            </footer>
          </form>
        </FormProvider>
      )}
    </div>
  );
}

export default AddRetailerForm;
