import React, { useRef } from 'react'
import { Shankesh_Awards_Data } from '../Shankesh_About_Us_Data';
import FullScreenImageSliderNew from '../../../../Component/FullScreenImageSlider/FullScreenImageSliderNew';
import {motion} from "framer-motion";

export default function Shakesh_Awards() {
    const openModalRef = useRef(null);
  
      const handleOpenFullScreenImageSlider = () => {
          openModalRef.current.openModal();
        };
  return (
    <>
      <FullScreenImageSliderNew
              imagePath={null}
              imagesArr={Shankesh_Awards_Data.map((award) => award.img)}
              ref={openModalRef}
            />
            <section className="award-image-container">
              {Shankesh_Awards_Data.map((data, index) => (
                <motion.div
                  key={index}
                  className="image-wrapper"
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  whileHover={{ scale: 1.08 }}
                  whileTap={{ scale: 0.8 }}
                  transition={{
                    duration: 1,
                    delay: 0.1 * index,
                    scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
                  }}
                  onClick={() => {
                    sessionStorage.setItem(
                      "active_order_details_slide",
                      index + 1
                    );
                    handleOpenFullScreenImageSlider();
                  }}
                >
                  <img src={data.img} />
                </motion.div>
              ))}
            </section>
    </>
  )
}
