import React from "react";
import { ApiList } from "../../../Api/ApiList";
import { get_bulk_settings, get_informationFromSessionID } from "../../Helper";
import { useLocation } from "react-router-dom";
import FooterCategories from "./FooterCategories";
import FooterLinkCard from "./FooterLinkCard";
import FooterPayment from "./FooterPayment";
import { useSelector } from "react-redux";
import { fetch_data_from_api_from_query } from "../../../Api/APICalls";
import FooterLocation from "./FooterLocation";
import FooterCopy from "./FooterCopy";
import { get_is_myc_web } from "../../../redux/Reducer/reducer";
import { useQuery } from "@tanstack/react-query";
import FooterComapnyLogo from "./components/FooterComapnyLogo";
import { getCustomValue } from "../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../Custom/CompanyCustomization";
import DownloadAppSection from "./components/DownloadAppSection/DownloadAppSection";
import { getDownloadAppImage } from "./components/DownloadAppSection/DownloadApp";
import FooterCustomLoaction from "./FooterCustomLoaction";

function FooterSection() {
  const is_myc_web = useSelector(get_is_myc_web);
  const userID = get_informationFromSessionID("userID");
  const cartCount = useSelector((state) => state?.reducer?.constants?.count);
  const DownloadAppImage = getDownloadAppImage();
  const show_footer_custom_location_link = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.show_footer_custom_location_link
  );
  let {
    cdn,
    company_logo,
    web_show_product_directory_footer,
    web_show_payment_methods_strip_footer,
    show_hide_content_footer,
  } =
    get_bulk_settings([
      "cdn",
      "company_logo",
      "web_show_product_directory_footer",
      "web_show_payment_methods_strip_footer",
      "show_hide_content_footer",
    ]) || {};

  web_show_product_directory_footer =
    web_show_product_directory_footer === "Yes";
  web_show_payment_methods_strip_footer =
    web_show_payment_methods_strip_footer === "Yes";
  show_hide_content_footer = show_hide_content_footer === "show";

  const logoImg = `${cdn}uploads/company_logo/${company_logo}`;
  //const company_code = get_informationFromSessionID("companyCode")
  //const [links, setLinks] = useState([])
  const location = useLocation();
  const show_horizontal_align_location_in_footer_section = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.show_horizontal_align_location_in_footer_section
  );

  const footer_height_to_adjest_download_app_image = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.footer_height_to_adjest_download_app_image
  );


  const staticpages_query = useQuery({
    queryKey: ["staticpages"],
    queryFn: () =>
      fetch_data_from_api_from_query(
        ApiList.fetchStaticpages,
        "FetchStaticpages"
      ),
    initialData: [],
    enabled: !is_myc_web,
  });

  const cartFooterHeight = sessionStorage.getItem("cartFooterHeight")
    ? `${sessionStorage.getItem("cartFooterHeight")}px`
    : "125px";

    return (
    <footer
      className={`ec-footer footer-main w-100 bottom-0`}
      // style={{
      //   marginBottom: `${
      //     ((location.pathname === "/cart" && Number(cartCount?.count) > 0) ||
      //       (location.pathname === "/short-list" &&
      //         Number(cartCount?.shortlist_count) > 0)) &&
      //     userID
      //       ? cartFooterHeight
      //       : "0"
      //   }`,
      // }}
    >
      <div className="footer-container">
        {is_myc_web || !web_show_product_directory_footer ? null : (
          <FooterCategories />
        )}
        {is_myc_web ||
        location.pathname === "/contact-us" ||
        !show_hide_content_footer ? null : (
          <div
            className="footer-top section-space-footer-p"
            style={{
              minHeight: footer_height_to_adjest_download_app_image || "auto",
              
            }}
          >
            <div className="container-fluid d-flex flex-column">
              <div
                className={`row ${
                  staticpages_query?.isSuccess &&
                  staticpages_query?.data?.length === 0
                    ? "justify-content-center"
                    : staticpages_query?.data?.length < 2
                    ? "justify-content-center"
                    : ""
                }`}
              >
                {DownloadAppImage &&
                !show_horizontal_align_location_in_footer_section &&
                staticpages_query?.isSuccess &&
                staticpages_query?.data?.length === 0 ? (
                  <div className="downloadApp-without-static-pages col-lg-4">
                    <DownloadAppSection clsName={"without-static-pages"} />
                  </div>
                ) : null}
                <div
                  className={`col-sm-12 col-lg-3 ec-footer-contact position-relative ${
                    staticpages_query?.data?.length < 2
                      ? "d-flex flex-column align-items-center"
                      : ""
                  }`}
                >
                  <FooterComapnyLogo
                    logoImg={logoImg}
                    biggerLogo={
                      show_horizontal_align_location_in_footer_section
                    }
                  />
                  {DownloadAppImage &&
                  show_horizontal_align_location_in_footer_section ? (
                    <DownloadAppSection
                      clsName={
                        staticpages_query?.data?.length === 0
                          ? "without-static-pages"
                          : ""
                      }
                    />
                  ) : null}
                  {/* -- Address--  */}
                  {!show_horizontal_align_location_in_footer_section ? (
                    <>
                      {show_footer_custom_location_link ? (
                        <FooterCustomLoaction />
                      ) : (
                        <FooterLocation />
                      )}
                    </>
                  ) : null}
                </div>

                {show_horizontal_align_location_in_footer_section &&
                staticpages_query?.data?.length === 0 ? (
                  <div className="col-sm-12 col-lg-9 ec-footer-account horizontal-align-footer-location without-static-pages">
                    <FooterLocation />
                  </div>
                ) : null}

                {/* -- Policies --  */}

                {staticpages_query?.isSuccess && (
                  <FooterLinkCard links={staticpages_query?.data} />
                )}
              </div>
              {show_horizontal_align_location_in_footer_section &&
                staticpages_query?.isSuccess &&
                staticpages_query?.data?.length > 0 && (
                  <div className="col-sm-12 col-lg-9 ec-footer-contact horizontal-align-footer-location">
                    {/* -- Address--  */}
                    <FooterLocation />
                  </div>
                )}
            </div>
          </div>
        )}
        {is_myc_web || !web_show_payment_methods_strip_footer ? null : (
          <FooterPayment />
        )}
        <FooterCopy />
      </div>
    </footer>
  );
}

export default React.memo(FooterSection);
