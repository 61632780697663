import { useQuery, useQueryClient } from "@tanstack/react-query";
import { get_informationFromSessionID, get_userData_from_activeUser, logOutUser, set_activeUser, set_informationToSessionID, set_userData_to_activeUser } from "../Component/Helper";
import { get_is_myc_web, set_inCartWishList, set_is_allow_access_myc_to_user, setConstants, setIsLoggedIn } from "../redux/Reducer/reducer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetch_data_from_api_from_query } from "../Api/APICalls";
import { ApiList } from "../Api/ApiList";


function useLoginStatus() {
    const userID = get_informationFromSessionID("userID") || "";
    const user_details = (userID && get_userData_from_activeUser()) || {}
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const is_myc_web = useSelector(get_is_myc_web);
    const login = useQuery({
        queryKey: ["login", userID],
        queryFn: () =>
        fetch_data_from_api_from_query(
          ApiList?.login,
          "login",
          {
            login_from: is_myc_web ? "myc" : "web",
            password: encodeURIComponent(atob(user_details?.password || "")),
            mobile_no: user_details?.mobile_no,
            long: 0,
            lat: 0,
            device_token: "",
            device_model: "",
            device_ip: "",
            device_id: "",
          }
        ).then(res => {
          
          if(res.ack === "1"){
            set_userData_to_activeUser(res);
            set_activeUser();
           
            dispatch(set_inCartWishList({
              in_cart_id_dm: res.in_cart_id_dm,
              in_cart_id_im: res.in_cart_id_im,
              in_wishlist_id_dm: res.in_wishlist_id_dm,
              in_wishlist_id_im: res.in_wishlist_id_im,
            }))
          }else{
      
        
      
            if(res.ack === "3" || res.ack === "0"){
      
                
                queryClient.invalidateQueries({ queryKey: ['category'] })
                queryClient.invalidateQueries({ queryKey: ['homescreen'] })
                logOutUser()
                // if(res.ack === "3"){
      
                //   set_informationToSessionID({isUserLoggedOut: true});
                // }
                dispatch(
                  setConstants({})
                );
                dispatch(setIsLoggedIn({status: false, userID: ""}))
                dispatch(set_inCartWishList(null))
                dispatch(set_is_allow_access_myc_to_user(false))
                if(is_myc_web){
                  const rs = get_informationFromSessionID("rs")
                  navigate(`${rs ? `/?rs=${rs}` : '/'}`)
                }else{
                  if(res.ack === "0"){

                      navigate('/login');
                  }else{
                    navigate('/');
                  }
                }
               
            
            }
            
          }
          return res
        }),
        // enabled: !!(userID && !inCartWishList),
        enabled: !!userID,
      });

      return login || {};
}

export default useLoginStatus
