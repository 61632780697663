import { useState } from "react";
import SelectDropDownInput from "./Inputs/SelectDropDownInput";
import RetailerDropdownWithButton from "./Inputs/RetailerDropdownWithButton";
import TextInput from "./Inputs/TextInput";
import { useQuery } from "@tanstack/react-query";
import { ApiList } from "../../../Api/ApiList";
import { createSelectArrayForReactSelect, get_userData_from_activeUser } from "../../../Component/Helper";
import { fetch_data_from_api_from_query } from "../../../Api/APICalls";
import { convertOBJ_to_html_react_options } from "../../Checkout/Components/DropDown/Components/DropdownSelect";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import DistributorDropDown from "./Inputs/DistributorDropDown";

function MultiCustomOrderForm({ default_fields }) {

    const userdata = get_userData_from_activeUser()
    const [distributor_list, set_distributor_list] = useState([])
    const [retailer_list, set_retailer_list] = useState([])
    const { setValue } = useFormContext() 

    const location = useLocation()
    const searchParams = Object.fromEntries(new URLSearchParams(location.search));
 
    const distributor_list_response = useQuery({
        queryKey: ["get_distributor"],
        queryFn: () =>
          fetch_data_from_api_from_query(`${ApiList.Finalize}/${ApiList.get_distributor}`, "get_distributor", {
            client_type: userdata.client_type,
          })
          .then(res => {
            
            if(res?.final_result){
                const selectOption = createSelectArrayForReactSelect(res?.final_result, "name", "id")
                setValue("distributor", selectOption[0])
                set_distributor_list(selectOption)
            }
            return res
          })
        ,
        
      });

      // eslint-disable-next-line no-unused-vars
      const retailer_list_response = useQuery({
        queryKey: ["get_retailer", searchParams?.retailer_id],
        queryFn: () =>
          fetch_data_from_api_from_query(`${ApiList.Finalize}/${ApiList.get_retailer}`, "get_retailer", {
            client_type: userdata.client_type,
            distributor_client_master_id: distributor_list_response.data?.final_result[0]?.id
          }).then(res => {
            
            if(res?.final_result){
                
                // set_retailer_list(res?.final_result)
                const htmlData = convertOBJ_to_html_react_options(res?.final_result || [])
                set_retailer_list(htmlData)
            }
            return res
          }),
        enabled: !!distributor_list_response.data?.final_result,
      });

     
   
  return (
    <div className="muti-custom-order-form">
        {
            // eslint-disable-next-line array-callback-return
            default_fields?.map(field => {

                
                if(field?.control_type === "Dropdown" && field?.short_code === "retailer"){
                    return <RetailerDropdownWithButton field={field} key={field?.short_code} data={retailer_list || []}/>
                }else if(field?.control_type === "Dropdown" && field?.short_code === "distributor"){
                    return <DistributorDropDown field={field} key={field?.short_code} data={distributor_list || []}/>
                }else if(field?.control_type === "Dropdown"){
                    return <SelectDropDownInput field={field} key={field?.short_code}/>
                }else if(field?.control_type === "Textfield"){
                    return <TextInput field={field} key={field?.short_code}/>
                }
            })
        }
      
      
    
    </div>
  );
}

export default MultiCustomOrderForm;
