import {motion} from "framer-motion"
import { shankesh_clientele_data } from "../../../../Component/HomeComponents/Testmonial/custom/SHANKESH/shankesh_testimonial_data"

export default function Clientele_Image_Grid() {

// const images_array = [...new Set(shankesh_testimonial_data.map(testimonial => testimonial.company_logo).filter(img => !!img))]
  return (
    <div className='clientele_image_grid'>
        {shankesh_clientele_data.map((img, index) => <motion.div key={index} className='image-container'
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 0.90 }}
        whileHover={{ scale: 1 }}
        // whileTap={{ scale: 0.8 }}
        transition={{
            duration: 1,
            delay: 0.1 * index,
            scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
        }}
        >
            <img src={img} />
        </motion.div>)}
    </div>
  )
}
