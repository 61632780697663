const image1 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/awards/award-1.jpeg"  
const image2 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/awards/award-2.jpeg"  
const image3 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/awards/award-3.jpeg"  
const image4 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/shankesh/uploads/static_pages/awards/award-4.jpeg"  

export const Shankesh_Awards_Data = [
    {
        img: image1,
        title: 'Solitaire Awards 2011 (Winner)'
    },

    {
        img: image2,
        title: 'Solitaire Awards 2009 (Nominated), Solitaire Awards 2011 (Winner), Solitaire Awards 2012 (Winner)'
    },

    // {
    //     img: image3,
    //     title: 'Solitaire Awards 2012 (Nominated)'
    // },

    // {
    //     img: image4,
    //     title: 'Solitaire Awards 2011'
    // },
]