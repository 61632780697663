import React, { useEffect, useState } from "react";

import SingleCardNew from "../SingleCardNew";
import { get_bulk_settings } from "../../Helper";
import NoImg from "../../../assets/images/no_image_available.png"
import Loader from "../../Loader";
import SetSameHeight from "../../SetSameHeight/SetSameHeight";


function ButtonImageGroup({ subCategories, setShowCategoryModal, layoutStyle }) {
  
  
  // const defaultImg = companyCode === "VEdD" ? default_cat_img_tgc : companyCode === "S0FMQVNIQU9OTElORQ" ? default_cat_img_kalashonline : NoImg;
  let {cdn, default_image, category_thumbnail_autopick_from_dm} = get_bulk_settings(["cdn", "default_image", "category_thumbnail_autopick_from_dm"]) || {}
  const defaultImg = default_image ? `${cdn}uploads/default_image/${default_image}` : NoImg;
  
  category_thumbnail_autopick_from_dm = category_thumbnail_autopick_from_dm === "Yes"

  

  if(!subCategories){
    return <Loader isLoading={true}/>
  }

  
  
  return (
    <section className={`button-img-group-container category-card ${subCategories?.length < 4 ? 'three-grid-column-layout' : ''}`}>
      {subCategories?.map((category) => {
        
        const img = category_thumbnail_autopick_from_dm && category?.category_img ? category?.category_img : category?.category_img
          ? `${cdn}uploads/branding_image/category/${category.category_img}`
          : defaultImg;
          
        return (
          <SingleCardNew
            img={img}
            key={category.id}
            cat_id={category.id}
            cat_name={category.cat_name}
            product_count={category.product_count}
            hasSubcategory={
              Array.isArray(category.subcategory) &&
              category.subcategory?.length > 0
            }
            setShowCategoryModal={setShowCategoryModal}
            layoutStyle={`${layoutStyle} modal-button-image`}
            hasImage={!!category?.category_img}
            textColor={category?.branding_text_fg_color}
            bgColor={category?.branding_text_bg_color}
            categoryData={category}
            
          />
        );
      })}
      <SetSameHeight traget_class=".button-img-group-container .cateogry-image" dependency={[subCategories]}/>
    </section>
  );
}

export default React.memo(ButtonImageGroup);
