import React from 'react'
import WhatsappIconNew from '../../../Component/WhatsappIcon/WhatsappIconNew'
import { CustomStaticRedirection } from './CustomStaticRedirection'
import { get_informationFromSessionID } from '../../../Component/Helper'
import { useLocation, useParams } from 'react-router-dom'
import NoProduct from '../../../Component/NoProduct/NoProduct'
import { getCustomValue } from '../../../Custom/CompanyList'
import { CUSTOMIZIED_COMPANY_PROPERTY } from '../../../Custom/CompanyCustomization'

function CustomStaticPage() {
  let {staticID} = useParams()
  const company_code = get_informationFromSessionID("companyCode")
  const decrypted_company_code = atob(company_code)
  const location = useLocation()
  const custom_menu = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.custom_menu)
  const get_custom_link = custom_menu ? custom_menu.find(menu => location.pathname.includes(menu?.link))?.link : false

  staticID = staticID ? staticID : !!get_custom_link ? get_custom_link.replace('/static-page/', '') : null
  const Page = staticID ? CustomStaticRedirection[decrypted_company_code][staticID] : null
  
 
  if(!Page){
    return <NoProduct errMsg={"Page Not Found"} hideHomeBtn={true}/>
  }
  
  return (
    <div>

       <WhatsappIconNew />
       <Page/>
        
    </div>
  )
}

export default CustomStaticPage