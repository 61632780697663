import React, { useEffect } from 'react'
import { ScrollToTopCustom } from '../../../../utils'
import { IoIosCall } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import "./SHANKESH_Contact.css";

export default function SHANKESH_Contact() {

     useEffect(() => {
            ScrollToTopCustom()
     }, [])

  return (
    <section className='shankesh-contact-us-page'>
        <h2>Contact Us</h2>
        <article>
            

                <h4>SHANKESH JEWELLERS PVT LTD</h4>

            <section className='location-section'>
                <div className='section'>
                    <span className='label-title'>
                        <FaLocationDot className='icon'/>
                        <span>Address</span>
                    </span>
                    <span className='label-value'>
                        <a href="https://maps.app.goo.gl/q5tbPxTUtoiLfYuR8" target='_blank'>101, Mumbadevi Chambers, 3rd Floor, Room No-12, S.M. Street, Zaveri Bazar, Mumbai-400003</a>
                    </span>
                </div>

                <div className='section'>
                    <span className='label-title'>
                        <IoIosCall className='icon'/>
                        <span>Contact No.</span>
                    </span>
                    <span className='label-value'>
                        <a href="tel:022-23470009">022-23470009</a>{" / "}<a href="tel:022-23470008">022-23470008</a>
                    </span>
                </div>

                

            </section>
        </article>
    </section>
  )
}
