import React, {  useState } from "react";
import {
  ToastError,
  delete_informationFromSessionID,
  get_informationFromSessionID,
  get_property_settings,
  set_activeUser,
  set_informationToSessionID,
  set_userData_to_activeUser,
} from "../../../Component/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller } from "react-hook-form";
import { MOBILE_REGEX } from "../../../utils";
import Telephone from "../../../Component/Telephone";
import { fetch_data_from_api } from "../../../Api/APICalls";
import { ApiList } from "../../../Api/ApiList";
import { useDispatch, useSelector } from "react-redux";
import {
  get_is_myc_web,
  get_myc_allow_new_registration,
  get_myc_is_secured,
  set_inCartWishList,
  set_open_preferences_modal,
  setIsLoggedIn,
} from "../../../redux/Reducer/reducer";
import { useNavigate } from "react-router-dom";
import OTPLoginContainer from "./OTPLoginContainer";
import { useQueryClient } from "@tanstack/react-query";

function LoginContainer({ showOptContainer, setShowOptContainer }) {
  const [showPassword, setShowPassword] = useState(false);

  const [OTPRes, SetOTPRes] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [isLoginWithOpt, setIsLoginWithOpt] = useState(false)
  let login_based_on = get_property_settings("login_based_on");
  // login_based_on = "password";
  
   
  const is_myc_web = useSelector(get_is_myc_web);
  const is_myc_is_secured = useSelector(get_myc_is_secured);
  const is_myc_allow_new_registration = useSelector(get_myc_allow_new_registration);
  const queryClient = useQueryClient()
    
  let mycat_v2_login_based_on = get_property_settings(
    "mycat_v2_login_based_on"
  );
  // mycat_v2_login_based_on = "password"


  let has_user_preferences = get_property_settings(
    "has_user_preferences"
  );

  has_user_preferences = has_user_preferences === "1"


 

  const {
    register,
    handleSubmit,
    control,
    trigger,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm();

  const LoginWithOTP = async () => {
    trigger("telephone");
    clearErrors("password");
    let mobile_no = getValues("telephone");
    if (!mobile_no) return;
    const country_code = get_informationFromSessionID("country_code");
    mobile_no = mobile_no.replace(country_code, "");
    if (
      errors?.telephone?.message ||
      mobile_no.length < 10 ||
      mobile_no.length > 10
    )
      return;

    const get_otp_response = await fetch_data_from_api(
      `${ApiList.login}/${ApiList.generateOtp}`,
      "fetchotp",
      {
        mobile_no,
        device_token: "",
        session_id: 0,
        // sb_jewelpos_mode: "No",
      }
    );
    
    if (get_otp_response?.ack === "3") {
      // if (is_myc_is_secured && !is_myc_allow_new_registration) {
      //   ToastError(get_otp_response?.error || "Something went wrong.");
      // } else {
      //   navigate("/registration", { state: { mobile_no, country_code } });
      // }
      if(navigator.userAgent.indexOf('iPhone') > -1 || navigator.userAgent.indexOf('Android') > -1 ) {
            sessionStorage.setItem("state", JSON.stringify({ mobile_no, country_code }))
      }
      navigate("/registration", { state: { mobile_no, country_code } });
    } else if (get_otp_response?.login_otp) {
      SetOTPRes(get_otp_response?.login_otp);
      setShowOptContainer(true);
    } else {
      // ToastError("OTP verification failed")
    }
  };

  const redirect_after_successful_login = (login_details_response) => {
    delete_informationFromSessionID("country_code");
    set_informationToSessionID({ userID: login_details_response.user_id });
    set_activeUser();
    set_userData_to_activeUser(login_details_response);
    dispatch(
      setIsLoggedIn({ status: true, userID: login_details_response.user_id })
    );

    let inCartWishList = {
      in_cart_id_dm: login_details_response.in_cart_id_dm,
      in_cart_id_im: login_details_response.in_cart_id_im,
      in_wishlist_id_dm: login_details_response.in_wishlist_id_dm,
      in_wishlist_id_im: login_details_response.in_wishlist_id_im,
    };

    dispatch(set_inCartWishList(inCartWishList))

    if(has_user_preferences){
      dispatch(set_open_preferences_modal(true))
      
    }
    queryClient.invalidateQueries({ queryKey: ['homescreen'] })
    navigate("/");

    // setTimeout(()=>{
    //     ToastSuccess("You are logged in successfully")
    // }, 500)
  };

  const LoginWithPassword = async (data) => {
    const country_code = get_informationFromSessionID("country_code") || "91";
    const login_details_response = await fetch_data_from_api(
      ApiList?.login,
      "login",
      {
        login_from: is_myc_web ? "myc" : "web",
        password: encodeURIComponent(data.password),
        mobile_no: data.telephone.replace(country_code, ""),
        long: 0,
        lat: 0,
        device_token: "",
        device_model: "",
        device_ip: "",
        device_id: "",
      }
    );

    if (login_details_response?.ack === "1") {
      redirect_after_successful_login(login_details_response);
    } else if (login_details_response?.ack === "3") {
      if (is_myc_is_secured && !is_myc_allow_new_registration) {
        ToastError(login_details_response?.error || "Something went wrong.");
      } else {
        if(navigator.userAgent.indexOf('iPhone') > -1 || navigator.userAgent.indexOf('Android') > -1 ) {
          sessionStorage.setItem("state", JSON.stringify({ mobile_no: data.telephone.replace(country_code, ""), country_code, password: data.password }))

        }
        navigate("/registration", {
          state: {
            mobile_no: data.telephone.replace(country_code, ""),
            country_code,
            password: data.password,
          },
        });
      }
    }
    else if(login_details_response?.error){
      
      ToastError(login_details_response?.error || "Something went wrong.");
    }
  };

  return (
    <div className="ec-login-container login-container">
      <div className="ec-login-form">
        {!showOptContainer ? (
          <form onSubmit={handleSubmit(LoginWithPassword)} noValidate>
            <span className="ec-login-wrap">
              <label className="label">Mobile No.</label>
              <span className="required-field">*</span>
              <Controller
                control={control}
                name="telephone"
                rules={{
                  required: {
                    value: true,
                    message: `Mobile no. is required`,
                  },
                  validate: (fieldValue) => {
                    const countryCode =
                      get_informationFromSessionID("country_code");
                    const onlyNumber = fieldValue.replace(countryCode, "");
                    return onlyNumber.length === 10 || "Invalid mobile no.";
                  },
                  pattern: {
                    value: MOBILE_REGEX,
                    message: "Invalid mobile no.",
                  },
                }}
                render={({ field }) => {
                  return (
                    <Telephone clsName="fieldValue" tabIndex="0" {...field} />
                  );
                }}
              />
              <div className="required-field errorText">
                {errors?.telephone?.message}
              </div>
            </span>

            <span className="ec-login-wrap">
              {((!is_myc_web && login_based_on !== "otp") ||
                (is_myc_web && mycat_v2_login_based_on !== "otp")) && (
                <>
                  <label className="label">Password</label>
                  <span className="required-field">*</span>
                  <div className=" w-100 d-flex align-item-center password-input-wrapper">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      required=""
                      tabIndex="1"
                      className="fieldValue"
                      {...register("password", {
                        required: {
                          value: !get_informationFromSessionID("optLogin"),
                          message: "Password is required",
                        },
                        minLength: {
                          value: 6,
                          message: "Password should be min 6 characters",
                        },
                      })}
                    />

                    <span
                      className="password-toggle-icon"
                      onClick={() => {
                        setShowPassword((prevState) => !prevState);
                      }}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </span>
                  </div>
                  <div className="required-field errorText">
                    {errors?.password?.message}
                  </div>
                </>
              )}
            </span>

            <span className="login-button">
              {((!is_myc_web && login_based_on !== "otp") ||
                (is_myc_web && mycat_v2_login_based_on !== "otp")) && (
                <button
                  className="w-100 mt-0 btn btn-primary primary_button btn-primary"
                  tabIndex="2"
                  type=""
                  onClick={() => {}}
                >
                  LOGIN
                </button>
              )}

              <button
                className={`btn btn-outline-dark text text-nowrap w-100 ${
                  (!is_myc_web && login_based_on !== "otp") ||
                  (is_myc_web && mycat_v2_login_based_on !== "otp")
                    ? "secondary_button border btn-secondary"
                    : "btn-primary primary_button btn-primary"
                }`}
                //   tabIndex={show ? "0" : "4"}
                tabIndex="3"
                onClick={(e) => {
                  e.preventDefault();
                  LoginWithOTP();
                }}
              >
                LOGIN WITH OTP
              </button>
            </span>

            <div className="my-3 Login-links d-flex justify-content-between flex-wrap">
            {((!is_myc_web && login_based_on !== "otp") ||
                (is_myc_web && mycat_v2_login_based_on !== "otp")) && (
              <button
                type="submit"
                className="anchor-tag fw-light borderless-btn"
                tabIndex="4"
                onClick={() => {
                  navigate("/forgot-password");
                }}
              >
                Forgot Password?
              </button>
            )}
              
              {is_myc_is_secured && !is_myc_allow_new_registration ? null : (
                <button
                  className="anchor-tag fw-light borderless-btn"
                  tabIndex="5"
                  onClick={() => {
                    navigate("/registration");
                  }}
                >
                  New User? Register
                </button>
              )}
            </div>
          </form>
        ) : (
          <OTPLoginContainer
            setShowOptContainer={setShowOptContainer}
            OTPRes={OTPRes}
            mobile_no={getValues("telephone")}
            redirect_after_successful_login={redirect_after_successful_login}
          />
        )}
      </div>
    </div>
  );
}

export default LoginContainer;
